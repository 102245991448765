import React, { useEffect } from "react";
import styled from "styled-components";

import { PopupEntranceAnimation } from "../pandora/animations";
import PopupIcon from "../resources/images/warning-icon.svg";


const Container = styled.div`
    position: fixed;
    z-index: 1;

    right: 10px;
    bottom: 50px;

    background-color: black;
    color: white;

    border-radius: 5px;
    width: 20%;
    height: fit-content;
    padding: 20px;

    overflow: hidden; 

    animation: ${PopupEntranceAnimation} 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)
    1;
    transition: 0.3s ease;
`

const Icon = styled("div")<{color: string}>`
    position: absolute;
    left: 15px;
    top: 15px;
    height: 40px;
    width: 40px;

    mask: url(${PopupIcon}) no-repeat 50% 50%;
    mask-size: cover;
    background-color: ${props => props.color};
`

const Title = styled("h1")<{size: number, weight?: string, css?: string}>`
    position: relative;
    font-size: ${props => props.size}px;
    color: white;
    font-weight: ${props => props.weight ?? "bold"};
    text-align: left;
    margin: 0px;
    width: auto;
    white-space: normal;
    word-break: break-all;
    height: fit-content;

    ${props => props.css ?? ""}
`




export interface GenericPopupProps {
    title: string
    message: string
    color: string
    setPopup: (value: GenericPopupProps | null) => void
}


/**
 * Usage:
    {popup && <GenericPopup 
                title=          {popup.title}
                message=        {popup.message}
                color=          {popup.color}
                setPopup=       {setPopup}
            />
    }
 * @param error Error instance with description.
 * @param setError State setter for error on parent Component. Used to remove warning popup. 
 */
const GenericPopup = (props: GenericPopupProps): React.ReactElement => {

    const { 
        title,
        message,
        color,
        setPopup
    } = props;

    useEffect(() => {
        const timer = setTimeout(() => {
            setPopup(null);
        }, 7000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <Container>
            <Icon color={color}/>
            <div style={{paddingLeft: "50px"}}>
                <Title size={14} css={"margin-bottom: 5px;"}>{title}</Title>
                <Title size={11} weight={"normal"}>{message}</Title>
            </div>
        </Container>
    )
}

export default GenericPopup;