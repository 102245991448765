import React from 'react';
import styled from 'styled-components';

import MarkerIcon from "../../../resources/images/marker-icon.svg";


const MarkerDiv = styled("div")<{background: string}>`
    background-size: cover;
    width: 40px;
    height: 40px;
    cursor: pointer;
    mask: url(${MarkerIcon}) no-repeat 50% 50%;
    mask-size: cover;
    border-width: 0;

    background-color: ${props => props.background};

    transition: 0.2s ease;

    &: hover {
        background-color: white;
        transform: scale(1.5);
    }
`

interface MarkerProps {
    id: number
    color: string
}

const Marker = (props: MarkerProps): React.ReactElement => {
    return (
        <MarkerDiv 
            id={`marker-${props.id}`} 
            background={props.color}
        />
    )
}

export default Marker;

