import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { IGeoData, IProject } from "../../../api/GeoDocModels";
import { convertDMS } from "../../../pandora/pandora";

import { HorizontalFlex, VerticalFlex } from "../../../pandora/styled";
import Color from "../../../resources/colors";


const PopupContainer = styled.div`
    color: white;
    position: relative;
    font-family: Montserrat, sans-serif;
    display: flex;
    justify-content: flex-start;
    background: ${Color.darkGrey};
    width: 100%;
    height: 100%;
    background: transparent;
    border-radius: 5px;
    overflow: hidden;
`

const HeaderBar = styled("div")<{background: string}>`
    background: ${props => props.background};
    height: 15px;
    width: 100%;
    position: absolute;
    top: 0px;
    margin: 0;
`

const Title = styled("h1")<{size: number, color?: string, css?: string}>`
    font-size: ${props => props.size}pt;
    color: ${props => props.color ?? "white"};
    font-weight: bold;
    font-family: Montserrat, sans-serif;
    margin: 0;
    height: fit-content;
    text-align: left;

    ${props => props.css ?? ""}
`

const ActionButton = styled("div")<{background: string, hoverBackground: string, icon: string}>`

    margin-top: 5px;
    margin-bottom: 5px;

    background-color: ${props => props.background};
    border-radius: 5px;

    color: white;
    font-size: 9pt;
    text-align: center;
    font-weight: bold;
    line-height: 30px;
    
    height: 30px;
    width: auto;

    background-image: url(${props => props.icon});
    background-repeat: no-repeat;
    background-size: 10px 10px;
    background-position: 20px 50%;

    cursor: pointer;
    user-select: none;

    transition: 0.1s linear;

    &:hover {
        padding: 5px;
        margin-top: 0px;
        margin-bottom: 0px;
        background-color: ${props => props.hoverBackground};
    }
`

const TextLabel = styled("h3")<{css?: string}>`
    margin-top: 5px;
    color: white;
    font-size: 8pt;
    text-align: left;
    font-weight: normal;

    ${props => props.css ?? ""}
`






interface GeoDataPopupProps {
    data: IGeoData
    project: IProject
    color: string
    openGeoDataView: (geoData: IGeoData, project?: IProject) => void
}

export const GeoDataPopup = (props: GeoDataPopupProps): React.ReactElement => {

    // State
    const [geoData, setGeoData] = useState<IGeoData>(props.data);
    const [project, setProject] = useState<IProject>(props.project);
    const coordinates: [string, string] = convertDMS(...geoData.geometry[0].point.coordinates);

    // Effects
    useEffect(() => setGeoData(props.data), [props.data]);

    // Actions
    const openGeoDataView = () => props.openGeoDataView(geoData, project);

    return (
        <PopupContainer id={`geodata-${geoData.id}`}>
            <HeaderBar background={props.color}></HeaderBar>
            <VerticalFlex css={"padding: 10px; padding-left: 15px; padding-right: 15px;"}>
                <HorizontalFlex css={"justify-content: space-between; height: 40px; padding-top: 10px; line-height: 40px; margin-bottom: 10px;"}>
                    <Title size={15} color={Color.extraLightGrey}>{"#" + geoData.id}</Title>
                    <Title size={8} color={Color.extraLightGrey}>{coordinates[0] + " " + coordinates[1]}</Title>
                </HorizontalFlex>
                <Title size={18}>{geoData.name}</Title>
                <Title size={12} css={"margin-top: 30px;"}>Beskrivning</Title>
                <TextLabel css={"font-size: 9pt; margin-bottom: 60px;"}>{geoData.description}</TextLabel>
                <ActionButton 
                    background=         {Color.lightGrey}
                    hoverBackground=    {Color.extraLightGrey}
                    icon=               {""}
                    onClick=            {openGeoDataView}
                >Öppna</ActionButton>
            </VerticalFlex>
        </PopupContainer>
    );
}