import { keyframes } from "styled-components";

import Color from "../resources/colors";


export const ViewEntranceAnimation = keyframes`
    from {
        transform: scale(0.95) 
        translate(0px, -10px);
    }  
    to {
        transform: scale(1) 
        translate(0px, 0px);
    }
`

export const PopupEntranceAnimation = keyframes`
    from { 
        right: -22%; 
    }
    to { 
        right: 10px; 
    }
`


export const ScaleAnimation = keyframes`
    from { 
        transform: scale(0.7); 
    }
    to { 
        transform: scale(1); 
    }    
`

export const ConfirmationPopupAnimation = keyframes`
    from { 
        transform: scale(0.85); 
    }
    to { 
        transform: scale(1); 
    }
`

export const InfoPopupAnimation = keyframes`
    from { 
        transform: translate(-20px, 0px);
        opacity: 0;
    }
    to { 
        transform: translate(0px, 0px);
        opacity: 1;
    }
`

export const FadeAnimation = keyframes`
    from {
        opacity: 0;
    } 
    to {
        opacity: 1;
    }
`

export const ItemContainerAnimation = keyframes`
    from {
        transform: translate(0px, -10px);
        opacity: 0;
    }
    to {
        transform: translate(0px, 0px);
        opacity: 1;
    }
`

export const JiggleAnimation = keyframes`
    0% { transform: rotate(-5deg); } 
    50% { transform: rotate(5deg) scale(1.05); }
    100% { transform: rotate(-5deg) scale(1); }
` 

export const DropContainerAnimation = keyframes`
    0% { 
        transform: scale(.5); 
        background-color: ${Color.lightGrey};
    } 
    50% { 
        transform: scale(1.0); 
        background-color: ${Color.green}; 
    }  
    100% { 
        transform: scale(.5); 
        background-color: ${Color.lightGrey}; 
    } 
`