import React from "react";
import styled from "styled-components";

import { ItemContainerAnimation } from "../../../pandora/animations";
import { IProject } from "../../../api/GeoDocModels";
import Color from "../../../resources/colors";
import DeleteIcon from "../../../resources/images/delete-icon.png";



const ColorBar = styled("div")<{color: string}>`
    position: absolute;
    left: 0;
    top: 0;
    background: ${props => props.color};
    height: 100%;
    width: 5px;

    border-radius: 5px 0px 0px 5px;
`

const Container = styled("div")`
    position: relative;
    height: fit-content;
    margin-top: 10px;
    padding: 15px;
    padding-left: 25px;

    overflow: visible;
    border-radius: 5px;
    background-color: ${Color.lightGrey};

    user-select: none;
    cursor: pointer;
    transition: 0.3s ease;
    animation: ${ItemContainerAnimation} 0.3s 1;

    &: hover {
        background-color: ${Color.extraLightGrey};
    }
`

const Title = styled("h1")<{size: number, weight?: string, css?: string}>`
    color: white;
    height: 20px;
    font-size: ${props => props.size}px;
    font-weight: ${props => props.weight ?? "bold"};
    line-height: ${props => props.size}px;
    text-align: left;
    margin: 0;
    margin-top: 5px;

    ${props => props.css ?? ""}
`   

const ActionContainer = styled.div`
    position: absolute;
    right: 10px;
    top: -10px;
    bottom: 0;
    width: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const ActionButton = styled("div")<{icon: string, background: string, hoverBackground: string}>`
    height: 45px;
    width: 45px;
    margin-top: 15px;

    border-radius: 5px;
    background-color: ${props => props.background};

    background-image: url(${props => props.icon});
    background-repeat: no-repeat;
    background-size: 20px 20px; 
    background-position: 50% 50%;

    cursor: pointer;
    transition: 0.3s ease;

    &:hover {
        background-color: ${props => props.hoverBackground};  
    }
`


interface ProjectListItemProps {
    project: IProject
    deleteProject: (project: IProject) => void;
    openProject: (project: IProject) => void;
}

const ProjectListItem = (props: ProjectListItemProps): React.ReactElement => {
    
    // State
    const { project } = props;

    // Actions
    const openProject = (e) => {
        e.stopPropagation();
        props.openProject(props.project);
    }

    const deleteProject = (e) => {
        e.stopPropagation();
        props.deleteProject(props.project);
    }

    return (
        <Container 
            onClick=    {openProject}
        >
            <ColorBar color={project.color} />
            <Title size={17}>{project.name}</Title>
            <Title size={12} weight={"normal"}>{project.description}</Title>
            <ActionContainer>
                <ActionButton 
                    icon=               {DeleteIcon} 
                    background=         {Color.red} 
                    hoverBackground=    {Color.lightRed}
                    onClick=            {deleteProject}
                />
            </ActionContainer>
        </Container>
    )
}


export default ProjectListItem;