import React from "react";
import styled from "styled-components";

import Auth from "../../datasource/auth";

import Color from "../../resources/colors";
import UserIcon from "../../resources/images/user-icon.png";
import UploadIcon from "../../resources/images/upload-icon.png";
import MapIcon from "../../resources/images/layers.png";



const ButtonsContainer = styled("div")<{selected: boolean}>`
    position: ${props => props.selected ? "relative" : "absolute"};    
    display: flex;
    flex-direction:  ${props => props.selected ? "column" : "row"};
    justify-content: space-evenly;
    align-items: center;
    
    bottom: ${props => props.selected ? 0 : 10}px;
    right: 0;
    left: 0;
    
    padding-bottom: ${props => props.selected ? 10 : 0}px;
    margin-top: ${props => props.selected ? 5 : 0}px;
    
    height: auto;
    width: auto;

    transition: 0.3s ease;
`
const Icon = styled("div")<{image: string}>`
    width: 35px;
    height: 35px;
    margin: 5px;
    border-radius: 5px;

    background-image: url(${props => props.image});
    background-repeat: no-repeat;
    background-size: 70% 70%;
    background-position: center;

    cursor: pointer;
    transition: 0.3s ease;

    &:hover {
        background-color: ${Color.lightGrey};
    }

    &: active {
        background-color: ${Color.extraLightGrey};
    }
` 


interface NavButtonsProps {
    projectWasSelected: boolean
    toggleMapSelection: () => void
    toggleUserView: () => void
    toggleUploadView: () => void
}

export const NavButtons = (props: NavButtonsProps): React.ReactElement => {

    const toggleUserView = (e: React.MouseEvent) => {
        e.stopPropagation();
        props.toggleUserView();
    }

    const toggleMapSelection = (e: React.MouseEvent) => {
        e.stopPropagation();
        props.toggleMapSelection();
    }

    const toggleUploadView = (e: React.MouseEvent) => {
        e.stopPropagation();
        props.toggleUploadView();
    }

    return (
        <ButtonsContainer selected={props.projectWasSelected}>  
            {Auth.isEmployee() && <Icon onClick={toggleUploadView} image={UploadIcon}/>}
            <Icon onClick={toggleUserView} image={UserIcon}/>
            <Icon onClick={toggleMapSelection} image={MapIcon}/>
        </ButtonsContainer>     
    );
}