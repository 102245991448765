import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { OrmState } from "redux-orm"; 

import RequestFactory from "../../../api/RequestFactory";
import { Schema } from "../../../datasource/orm";
import { ClientAction } from "../../../datasource/actions";
import Selector from "../../../datasource/selector";
import { IOrganisation, IProject } from "../../../api/GeoDocModels";

import Color from "../../../resources/colors";
import AddIcon from "../../../resources/images/add-icon.png";
import CloseIcon from "../../../resources/images/close-icon.png";
import { Flex } from "../../../pandora/styled";

import OrganisationSelect from "./OrganisationSelect";
import GenericPopup, { GenericPopupProps } from "../../../components/GenericPopup";


const Title = styled("h1")<{size: number, weight?: string}>`
    font-size: ${props => props.size}px;
    color: white;
    font-weight: ${props => props.weight ?? "bold"};
    text-align: left;
    margin: 0px;
    white-space: nowrap;
    width: auto;
    margin-bottom: 5px;
`

const ActionButton = styled("div")<{icon: string, background: string, hoverBackground: string, disabled: boolean}>`
    position: relative;
    width: fit-content;
    height: 40px;
    padding-left: 40px;
    padding-right: 20px;
    margin-right: 10px;
    color: white;
    font-size: 9pt;
    font-weight: bold;
    line-height: 40px;

    border-radius: 5px;
    background-color: ${props => props.background};

    background-image: url(${props => props.icon});
    background-repeat: no-repeat;
    background-size: 12px 12px; 
    background-position: 12px 50%;

    cursor: pointer;
    transition: 0.2s ease;

    &:hover {
        background-color: ${props => props.hoverBackground};        
    }

    ${props => props.disabled ? "opacity: 0.5; cursor: not-allowed;" : ""}
`

const Input = styled("input")<{css?: string}>`
    background: ${Color.lightGrey};
    border-radius: 5px;
    outline: none;
    width: auto;
    min-height: 40px;
    height: 40px;
    margin-bottom: 20px;

    text-indent: 10px;
    font-size: 8pt;
    font-family: Montserrat, sans-serif;
    color: white;
    border-width: 0px;

    transition: 0.3s ease;

    ::placeholder {
        color: white;
        font-family: Montserrat, sans-serif;
        font-weight: bold;
        opacity: 50%;
    }

    &: focus {
        background: ${Color.extraLightGrey}
    }
    
    &: hover {
        background: ${Color.extraLightGrey}
    }

    ${props => props.css ?? ""} 
`




interface AddProjectViewProps {
    toggle: () => void;
}

const AddProjectView = (props: AddProjectViewProps): React.ReactElement => {

    const dispatch = useDispatch()
    const organisations: IOrganisation[] = useSelector((state: OrmState<Schema>) => Selector.getOrganisations(state))
    
    // State
    const [createActionMessage, setCreateActionMessage] = useState<string>("Skapa");

    const [createEnabled, setCreateEnabled] = useState<boolean>(false);
    const [name, setName] = useState<string>(""); 
    const [description, setDescription] = useState<string>("");
    const [organisationID, setOrganisationID] = useState<string | null>(null);

    const [popup, setPopup] = useState<GenericPopupProps | null>(null);


    // Update state
    const updateName = (e) => {
        const value = e.target.value;
        if (value.length < 50) { setName(value) }
    }

    const updateDescription = (e) => {
        const value = e.target.value;
        if (value.length < 500) { setDescription(value) }
    }

    useEffect(() => {
        const enabled = (name !== "" && description !== "" && organisationID !== null)
        setCreateEnabled(enabled)
    }, [name, description, organisationID])


    // Actions

    /**
     * Request project creation 
     */
    const addProject = async () => { 
        if (createEnabled) {    
            setCreateActionMessage("Skapar...");
            try {

                // Request project creation via API
                const project: IProject = await RequestFactory.createProject(name, description, organisationID)
                
                // Dispatch project creation to Redux store
                dispatch(ClientAction.createProject(project))
                
                // Show success popup
                setPopup({
                    title: "Projekt skapat!",
                    message: `Projekt ${project.name} har skapats.`,
                    color: Color.green,
                    setPopup: setPopup
                }) 
                
                // Reset state
                setCreateActionMessage("Skapa");
                setOrganisationID(null);
                setName("");
                setDescription("");
                props.toggle()
                
            } catch (e) {
                setPopup({
                    title: "Ett fel uppstod.",
                    message: `${e.message}`,
                    color: Color.red,
                    setPopup: setPopup
                }) 
            }
        }
    }


    return (
        <Flex direction={"column"} css={"justify-content: space-between; position: relative; user-select: none; overflow-y: auto;"}>
            {popup && <GenericPopup 
                            title=          {popup.title}
                            message=        {popup.message}
                            color=          {popup.color}
                            setPopup=       {setPopup}
                        />
            }
            <Title size={30}>Skapa projekt</Title>
            <Flex direction={"column"} css={"margin-top: 20px; overflow-y: auto;"}>
                <Input 
                    type="text" 
                    placeholder="Namn" 
                    id="name" 
                    value={name} 
                    onChange={(e) => updateName(e)}
                />
                <Input 
                    type="text" 
                    placeholder="Beskrivning" 
                    id="description" 
                    value={description} 
                    onChange={(e) => updateDescription(e)}
                />
                <OrganisationSelect 
                    orgs=               {organisations} 
                    setOrganisationID=  {setOrganisationID}
                />
                <Flex direction={"row"} css={"height: 50px; justify-content: flex-start; margin-top: 40px;"}>
                    <ActionButton
                        id=                 "create-button"
                        icon=               {AddIcon}
                        background=         {Color.green}
                        hoverBackground=    {Color.lightGreen}
                        onClick=            {addProject}
                        disabled=           {!createEnabled}
                    >{createActionMessage}</ActionButton>
                    <ActionButton
                        icon=               {CloseIcon}
                        background=         {Color.red}
                        hoverBackground=    {Color.lightRed}
                        onClick=            {props.toggle}
                        disabled=           {false}
                    >Avbryt</ActionButton>
                </Flex>
            </Flex>
        </Flex>
    )
}

export default AddProjectView;



