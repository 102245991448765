import React, {useEffect, useState} from "react";
import styled from "styled-components";

import Auth from "../../datasource/auth";

import { CompletionState, CompletionStateColorMap, getCompletionNameState } from "../../pandora/pandora";
import Color from "../../resources/colors";

import UncompletedIcon from "../../resources/images/close-icon.png";
import PendingIcon from "../../resources/images/hourglass.png";
import CompletedIcon from "../../resources/images/check-mark-icon.png";
import AllIcon from "../../resources/images/marker-icon.svg";


const FilterBarContainer = styled.div`
    position: relative;
    height: 180px;
    width: 50px; 
    min-width: 160px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 15px;

    transition: 0.3s ease;
`

const FilterOptionDiv = styled("div")<{icon: string, background: string, colorSet: string}>`
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    background-color: ${props => props.background};
    background-image: url(${props => props.icon});
    background-repeat: no-repeat;
    background-position: 12px 50%;
    background-size: 15px 15px;

    font-size: 10px;
    color: transparent;
    font-weight: bold;
    line-height: 40px;
    text-indent: 20px;
    user-select: none;
    cursor: pointer;

    transition: 0.3s ease;

    &:hover {
        background-color: ${props => props.colorSet[0]};
        color: white;
        width: 150px;
    }
    &:active {
        background-color: ${props => props.colorSet[1]};
    }
`



const FilterIconMap = { 
    [CompletionState.Uncompleted] : UncompletedIcon,
    [CompletionState.Pending] : PendingIcon,
    [CompletionState.Completed] : CompletedIcon,
    all: AllIcon
}

interface FilterBarProps {
    selectedFilter: CompletionState | null;
    setFilter: (filter: CompletionState) => void
}

export const FilterBar = (props: FilterBarProps): React.ReactElement => {

    // The additional "all" key for the completion state filter
    // options does not exist on the CompletionState enum. 
    // Rather, the selected filter type value that corresponds to the key "all" 
    // is "null".
    const ALL_KEY: string = "all";
    
    // State
    const [selectedFilterKey, setSelectedFilterKey] = useState<string | null>(props.selectedFilter === null ? ALL_KEY : props.selectedFilter); 
    const [hoveredFilter, setHoveredFilter] = useState<string | null>(null);

    const options: string[] = ["all", ...Object.keys(CompletionState)];

    // Effects

    // If the selected filter is null, 
    // then the corresponding filter key is set
    // to the "ALL" option. 
    useEffect(() => { 
        const key = props.selectedFilter === null ? ALL_KEY : props.selectedFilter
        setSelectedFilterKey(key)
    }, [props.selectedFilter])

    // Actions
    const setHover = (e, f: string) => setHoveredFilter(f);

    const handleClick = (e, f: string) => {
        e.stopPropagation();
        setSelectedFilterKey(f);
        props.setFilter(getFilterForKey(f));
    }

    /**
     * Return the associated Completion State
     * filter instance for the given key. 
     * 
     * The "all" key here acts as the key for the 
     * CompletionState of "null".
     * @param key 
     */
    const getFilterForKey = (key: string): CompletionState | null => {
        if (key === ALL_KEY) {
            return null;
        } else {
            return CompletionState[key];
        }
    }    
    
    return (
        <FilterBarContainer>
            {options.map((f) => 
                    <FilterOptionDiv 
                        key=                {f}
                        icon=               {FilterIconMap[f]}
                        background=         {selectedFilterKey === f ? CompletionStateColorMap[f][0] : Color.darkGrey} 
                        colorSet=           {CompletionStateColorMap[f]}
                        onClick=            {(e) => handleClick(e, f)}
                        onMouseOver=        {(e) => setHover(e, f)}
                        onMouseOut=         {(e) => setHover(e, null)}
                    >{hoveredFilter === f || selectedFilterKey === f ? getCompletionNameState(getFilterForKey(f), Auth.isEmployee()) : "" }</FilterOptionDiv>
                )
            }
        </FilterBarContainer>
    );
}