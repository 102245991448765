import React, { useState } from "react";
import styled from "styled-components";
import './DatePicker.css';
import DatePicker, { DayRange, Day } from 'react-modern-calendar-datepicker';

import { IProject } from "../../api/GeoDocModels";

import Color from "../../resources/colors";
import { Flex } from "../../pandora/styled";



const Container = styled(Flex)`
    height: 20px;
    width: fit-content;
    padding: 10px;

    margin-bottom: 10px; 
    border-radius: 5px;
    background-color: ${Color.darkGrey};
`

const ExportButton = styled.div`
    position: relative;
    height: 20px;
    width: fit-content;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;

    top: -5px;

    border-radius: 5px;
    background-color: ${Color.green};

    font-size: 11px;
    font-weight: bold;
    color: white;
    line-height: 20px;
    text-align: left;
    
    cursor: pointer;
    transition: 0.3s ease;

    &:hover {
        background-color: ${Color.lightGreen};        
    }
`

const DatePickerContainer = styled(Flex)<{css?: string}>`
    position: relative;
    height: 20px;
    width: 200px;
    padding: 5px;
    top: -5px;

    justify-content: center;

    border-radius: 5px;
    background-color: ${Color.lightGrey};
    cursor: pointer;
    transition: 0.3s ease;

    ${props => props.css ?? ""}

    &: hover {
        background: ${Color.extraLightGrey};
    }
`


interface ExportTimeReportProps {
    project: IProject;
    exportTimeReport: (fromDate: Date | null, toDate: Date | null) => void;
}

const ExportTimeReport = (props: ExportTimeReportProps): React.ReactElement => {

    const { project } = props;

    // State
    const timeReportDates: Date[] = [];
    project.geoData.forEach(g => g.timeReports.forEach(t => timeReportDates.push(t.time)))
    const [dateRange, setDateRange] = useState<DayRange>({from: null, to: null});

    // Convert GeoData timestamps to React Calendar library 
    // date format (year, month, day) to 
    const convert = (): Day => { 
        const minDate: Date = timeReportDates.reduce((pre, cur) => pre > cur ? cur : pre);
        return {year: minDate.getFullYear(), month: minDate.getMonth(), day: minDate.getDay()}
    }

    // Convert React Calendar library 
    // date format to JS Date objects.
    // Call export time report function in 
    // parent component. 
    const exportTimeReport = () => {
        const getDate = (d: Day | null): Date | null => {
            if (d === null) return null;
            const args: [number, number, number] = Object.keys(d).map(k => d[k]).reverse() as [number, number, number]
            args[1] -= 1
            return new Date(...args)
        }
        props.exportTimeReport(getDate(dateRange.from), getDate(dateRange.to))
    }

    return (
        <div>
            {timeReportDates.length !== 0 &&
                <Container 
                    direction=  "row" 
                    css=        "justify-content: flex-end;"
                >
                    <DatePickerContainer 
                        direction=  "row" 
                        css=        {"margin-right: 10px;"}
                    >
                        <DatePicker
                            value=              {dateRange}
                            onChange=           {setDateRange}
                            inputPlaceholder=   "Välj datumintervall"
                            minimumDate=        {convert()}
                        />
                    </DatePickerContainer>
                    <ExportButton 
                        onClick={exportTimeReport}
                    >Exportera</ExportButton>
                </Container>
            }
        </div>
    )
}

export default ExportTimeReport;











