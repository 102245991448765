import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from "react-redux";

import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

import store from "./datasource/store";
import Auth from "./datasource/auth";
import App from './App';
import Redirect from "./datasource/redirect";
import { bootstrap } from "./datasource/bootstrap";


/**
 * If user not authenticated, 
 * redirects to ASuite login.
 */
const authenticated = Auth.init()
if (!authenticated) Redirect.asuite(true)

/**
 * BOOTSTRAP STATE
 * Dispatch bootstrap of ORM state to store. 
 * Bootstrapping retrieves data from Sleipnir (server) normalizes
 * and instantiates a bootstrapped ORM state. 
 * 
 * Dispatch event BOOSTRAP_ACTION_KEY hydrates Redux store state with the
 * bootstrapHydrator and updates state to retrieved, formatted data.
 */

// @ts-ignore
store.dispatch(bootstrap());


ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App /> 
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()