import { IGeoData } from "../api/GeoDocModels"; 
import { CompletionState } from "../pandora/pandora";
import Auth from "./auth"; 





export default class CompletionFilter { 

    /**
     * Filter GeoData with generic {@link CompletionState}.
     * @param filter CompletionState Filter to apply. 
     * @param geoData GeoData to filter.
     */
    public static get = (filter: CompletionState | null, geoData: IGeoData[]): IGeoData[] => {
        switch(filter) {
            case CompletionState.Uncompleted:
                return CompletionFilter.getUncompleted(geoData);
            case CompletionState.Pending:
                return CompletionFilter.getPending(geoData);
            case CompletionState.Completed:
                return CompletionFilter.getCompleted(geoData);
            case null: 
                return geoData;
            default: 
                throw new Error(`Unknown DataFilter passed to DataFilter.determine: ${filter}`)
        }
    }

    /**
     * Determine completion state depending on current user.
     * @param geoData GeoData on which to determine Filter state.
     */
    public static determine = (geoData: IGeoData): CompletionState => {
        if (geoData.internalCompletion === null && geoData.externalCompletion === null) {
            return Auth.isEmployee() ? CompletionState.Pending : CompletionState.Uncompleted
        } else if (geoData.internalCompletion === null && geoData.externalCompletion !== null) {
            return Auth.isEmployee() ? CompletionState.Uncompleted : CompletionState.Pending            
        } else if (geoData.internalCompletion !== null && geoData.externalCompletion !== null) {
            return CompletionState.Completed
        } else {
            throw new Error(`GeoData #${geoData.id} is completed internal but not external.`)
        }
    }

    /**
     * Filter logic to determine which data to 
     * show to user depending on them being external 
     * or internal users.
     * @param filter 
     * @param geoData 
     */
    private static check = (filter: CompletionState, geoData: IGeoData): boolean => {        
        const keys: string[] = Object.keys(CompletionState)
        for (let i=0; i < keys.length; i++) {
            if (CompletionState[keys[i]] === filter) 
                return CompletionFilter.determine(geoData) === filter
        }
        throw new Error(`Could not determine Filter state for GeoData #${geoData.id}`)
    }

    /**
     * Check if completion is possible on GeoData 
     * for user and completion state. 
     * @param geoData 
     */
    public static completionEnabled = (geoData: IGeoData): boolean => {
        const state: CompletionState = CompletionFilter.determine(geoData)
        switch (state) {
            case CompletionState.Uncompleted: return true;
            case CompletionState.Pending: return false;
            case CompletionState.Completed: return false;
            default:
                throw new Error(`Unknown Filter state passed to DataFilter.completionEnabled`)
        }
    }

    /**
     * Check if uncompletion is possible on GeoData 
     * for user and completion state. 
     * @param geoData 
     */
    public static uncompletionEnabled = (geoData: IGeoData): boolean => {
        const state: CompletionState = CompletionFilter.determine(geoData)
        switch (state) {
            case CompletionState.Uncompleted: return Auth.isEmployee() ? true : false;
            case CompletionState.Pending: return Auth.isEmployee() ? false : true;
            case CompletionState.Completed: return false;
            default:
                throw new Error(`Unknown Filter state passed to DataFilter.uncompletionEnabled`)
        }
    }

    /**
     * Get uncompleted geodata.
     * @param geoData GeoData to filter.
     */
    public static getUncompleted = (geoData: IGeoData[]): IGeoData[] => geoData.filter((geoData: IGeoData) => CompletionFilter.check(CompletionState.Uncompleted, geoData))

    /**
     * Get pending geodata.
     * @param geoData GeoData to filter.
     */
    public static getPending = (geoData: IGeoData[]): IGeoData[] => geoData.filter((geoData: IGeoData) => CompletionFilter.check(CompletionState.Pending, geoData))
    
    /**
     * Get completed geodata.
     * @param geoData GeoData to filter.
     */
    public static getCompleted = (geoData: IGeoData[]): IGeoData[] => geoData.filter((geoData: IGeoData) => CompletionFilter.check(CompletionState.Completed, geoData))
}