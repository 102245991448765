import React, { useState, useRef, useEffect }from "react";
import ReactDOM from "react-dom";
import { Map as MPMap, Marker as MPMarker } from "mapbox-gl";

import CompletionCompletionState from "../../datasource/completion";
import { CompletionState } from "../../pandora/pandora";
import { IProject, IGeoData } from "../../api/GeoDocModels";

import { MapBoxAPIToken } from "../../pandora/tokens";

import Marker from "./Marker/Marker";
import { GeoDataPopup } from "./Popup/GeoDataPopup";
import "./Popup/GeoDataPopup.css";


const mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');
mapboxgl.accessToken = MapBoxAPIToken; 



interface MapProps {
    projects: IProject[]
    hiddenProjects: IProject[]
    mapBoxKey: string
    flyToPoint: [number, number]
    filter: CompletionState
    openGeoDataView: (geoData: IGeoData, project?: IProject) => void
    setFlyToPoint: (value: [number, number] | null) => void;
}

type MappedGeoData = { number : IGeoData[] } // Map project props ID to GeoData[]

const Map = (props: MapProps): React.ReactElement => {

    // Get filtered GeoData for currently 
    // applied CompletionFilter. 
    const getGeoData = (filter: CompletionState): MappedGeoData | Record<string, unknown> => {
        const data: MappedGeoData | Record<string, unknown> = {};
        for (const i in projects) {
            data[i] = CompletionCompletionState.get(filter, projects[i].geoData);
        }
        return data;
    }

    // State
    const mapContainer = useRef<HTMLDivElement | null >(null);

    const [markers, setMarkers] = useState<MPMarker[]>([]);
    const [map, setMap] = useState<MPMap>(null);
    const [mapType, setMapType] = useState<string>(props.mapBoxKey);

    const [completionFilter, setCompletionFilter] = useState<CompletionState>(props.filter);

    const [projects, setProjects] = useState<IProject[]>(props.projects);
    const [hiddenProjects, setHiddenProjects] = useState<IProject[]>(props.hiddenProjects);
    const [geoData, setGeoData] = useState<MappedGeoData | Record<string, unknown>>(getGeoData(props.filter));

    // Effects

    /**
     * Handle initial map load from Mapbox. 
     */
    useEffect(() => {

        const initialZoom = {
            center: [14.16, 56.76],
            zoom: 7
        }  

        const initializeMap = ({ setMap, mapType, mapContainer }) => {

            // Create map instance. 
            const map = new mapboxgl.Map({
                container: mapContainer.current,
                style: `mapbox://styles/${mapType}`,
                center: [13, 55],
                zoom: 1,
                pitch: 30,
                maxZoom: 20,
                minZoom: 5,
                reuseMaps: true
            });
            
            // Perform initial data load on map instance. 
            map.on('load', () => {
                if (map.loaded()) {
                    setMap(map);
                    setMapProjectLayers(map, projects);
                    setMapData(map, geoData);
                    setTimeout(() => { map.flyTo(initialZoom) }, 1000);
                }
            });
        };
        
        // Initialise MapBox map instance if map 
        // has been loaded from MapBox API. 
        if (!map) 
            initializeMap({ setMap, mapType, mapContainer });
    }, [map])

    /**
     * Update map layers on projects 
     * and hidden projects update. 
     */
    useEffect(() => {
        if (map) setMapProjectLayers(map, projects);
    }, [hiddenProjects, projects])

    /**
     * Retrive new GeoData filtered 
     * with CompletionState on completion 
     * state change. 
     */
    useEffect(() => {
        const newGeoData = getGeoData(props.filter)
        setGeoData(newGeoData);
        if (map) setMapData(map, newGeoData);
    }, [completionFilter, projects, hiddenProjects])

    /**
     * Update hidden / projects state on 
     * props update. 
     */
    useEffect(() => {
        setHiddenProjects(props.hiddenProjects);
        setProjects(props.projects);
    }, [props.projects, props.hiddenProjects])

    // Update Map style type on props change
    useEffect(() => { setMapType(props.mapBoxKey)}, [props.mapBoxKey])

    // Update completion state filter state on filter props change. 
    useEffect(() => { setCompletionFilter(props.filter)}, [props.filter])
    
    // Apply new style to map. 
    useEffect(() => { 
        if (map) 
            map.setStyle(`mapbox://styles/${mapType}`) 
    }, [mapType])
    
    // Request map fly to point action 
    // on fly to point props update. 
    useEffect(() => { 
        if (map && props.flyToPoint !== null) 
            map.flyTo({center: props.flyToPoint, zoom: 15});
            props.setFlyToPoint(null);
    }, [props.flyToPoint])


    // Actions

    /**
     * Set map GeoData pins.
     * @param data Mapped GeoData to projects to display on map.  
     */
    const setMapData = (map: MPMap, data: MappedGeoData | Record<string, unknown>) => {

        // Remove all current markers on map 
        // to clean map for new pin rendering.
        if (markers.length !== 0) markers.forEach((m: MPMarker) => m.remove());

        // Iterate data in projects to display on map. 
        const newMarkers: MPMarker[] = [];
        for (const projectID of Object.keys(data)) {

            // Skip hidden projects. 
            if (hiddenProjects.includes(projects[projectID])) continue;
            
            // Extract project and geodata instances from arguments. 
            const _project: IProject = projects[projectID]
            const color: string = _project.color;
            const _geoData: IGeoData[] = data[projectID]

            _geoData.forEach((d: IGeoData) => {
                const { id } = d;
                const popupData = {data: d,
                                   project: _project,
                                   color: color,
                                   openGeoDataView: props.openGeoDataView}

                // Create and render marker 
                const markerNode = document.createElement("div");
                ReactDOM.render(<Marker id={id} color={color}/>, markerNode);

                // Create and render popup
                const popup = React.createElement(GeoDataPopup, popupData);
                const popupCanvas = document.createElement('div');
                ReactDOM.render(popup, popupCanvas);
                
                const m = new mapboxgl.Marker(markerNode)
                                            .setLngLat(d.geometry[0].point.coordinates)
                                            .setPopup(new mapboxgl.Popup({
                                                closeButton: false,
                                                offset: 25
                                            })
                                            .setDOMContent(popupCanvas))
                                            .addTo(map);
                newMarkers.push(m);
            });
        }
        setMarkers(newMarkers);
    }

    /**
     * Set map project layers. 
     * @param map MapBox map instance. 
     * @param _projects Projects to render. 
     */
    const setMapProjectLayers = (map: MPMap, _projects: IProject[]) => {

        // Iterate passed projects to render.
        _projects.forEach((project: IProject) => {

            // Exit if project has no passed area 
            // polygon from API. 
            if (project.area === null) return; 

            const { id } = project;
            const idString: string = id.toString();

            // Remove project layers if project is hidden 
            if (hiddenProjects.includes(project)) {
                if (map.getLayer(idString)) map.removeLayer(idString);    
                if (map.getSource(idString)) map.removeSource(idString);
            } else {
                if (map.getSource(idString)) return;
                if (map.getLayer(idString)) return;

                map.addSource(idString, {
                    'type': 'geojson',
                    'data': {
                        'type': 'Feature',
                        //@ts-ignore
                        'geometry': project.area
                    }
                })

                map.addLayer({
                    'id': idString,
                    'type': 'fill',
                    'source': idString,
                    'layout': {},
                    'paint': {
                        'fill-color': project.color,
                        'fill-opacity': 0.5,                    
                    }
                })
            }
        });
    }
    
    return (
        <div 
        ref={el => (mapContainer.current = el)} 
        style={
            {
                position: "absolute",
                top: 0,
                right: 0,
                left: 0,
                bottom: 0
            }
        }/>
    )
}

export default Map;
