import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";

import Auth from "../../../datasource/auth";

import CompletionFilter from "../../../datasource/completion";
import { IProject, IGeoData } from "../../../api/GeoDocModels";

import Color from "../../../resources/colors";
import { HorizontalFlex, VerticalFlex } from "../../../pandora/styled";
import GeoDataContainer from "../../GeoDataContainer";

import { CompletionState, getCompletionNameState, getCompletionInfo } from "../../../pandora/pandora";
import UncompletedIcon from "../../../resources/images/close-icon.png";
import PendingIcon from "../../../resources/images/hourglass.png";
import CompletedIcon from "../../../resources/images/check-mark-icon.png";
import NoDataComponent from "../../NoDataComponent";
import InfoComponent from "../../InfoComponent";


const EntranceAnimation = keyframes`
    from {
        transform: translate(-180px, 0px);
    }  
    to {
        transform: translate(0px, 0px);
    }
`

const Container = styled(VerticalFlex)`
    position: fixed;
    margin-left: 80px;
    width: 200px;
    height: 100%;
    background: ${Color.lightGrey};
    padding: 40px;

    padding-right: 20px;
    padding-top: 20px;

    animation: ${EntranceAnimation} 0.2s ease 1;

    overflow: visible;
    transition: 0.3s ease;
`

const DataList = styled(VerticalFlex)`
    overflow: auto;
    margin-top: 20px;
    padding-bottom: 40px;
    
    ::-webkit-scrollbar {
        width: 0px;
    }   
`

const Header = styled(HorizontalFlex)`
    align-items: center;
    height: 70px;
    justify-content: flex-start;
    position: relative;
`

const Marker = styled("div")<{background: string}>`
    position: absolute;
    z-index: 10;
    top: 0px;
    left: 0px;
    right: 0px;
    margin: 0;
    width: 100%;
    height: 10px;
    background: ${props => props.background};
`

const TitleDiv = styled(VerticalFlex)`
    align-items: left;
    height: 100%;
    justify-content: center;
    width: fit-content;
`

const TopTitle = styled.h3`
    color: white;
    font-size: 11px;
    font-weight: normal;
    text-align: left;
    line-height: 12px;
    margin: 0;
    width: fit-content;
`

const Title = styled("h1")<{size: number}>`
    text-align: left;
    color: white;
    font-size: ${props => props.size}pt;
    margin: 0;
    width: fit-content;
    word-wrap: normal;
`

const DataTitle = styled(Title)<{isTop: boolean, icon: string}>`
    position: relative;

    height: 30px;
    font-size: 11px;
    line-height: 30px;
    text-indent: 30px;

    width: 100%;
    margin-bottom: 20px;
    margin-top: 10px;
    ${props => !props.isTop ? "margin-top: 40px;" : ""}

    background-color: ${Color.darkGrey};
    border-radius: 5px;

    background-image: url(${props => props.icon});
    background-repeat: no-repeat;
    background-size: 12px 12px;
    background-position: 10px 50%;

    overflow: visible;
`



interface ProjectDataListProps {
    project: IProject
    openGeoData: (geoData?: IGeoData) => void
}


const ProjectDataList = (props: ProjectDataListProps): React.ReactElement => {

    const { 
        project,
        openGeoData,
    } = props;

    // State
    const [uncompletedData, setUncompletedData] = useState<IGeoData[]>(CompletionFilter.getUncompleted(project.geoData));
    const [pendingData, setPendingData] = useState<IGeoData[]>(CompletionFilter.getPending(project.geoData));
    const [completedData, setCompletedData] = useState<IGeoData[]>(CompletionFilter.getCompleted(project.geoData));

    // Update data in the list. 
    // Retrieve data based on the GeoData 
    // completion state. 
    // The filter getter calls could be refactored 
    // to instead use the CompletionState enum for less verbose 
    // and repetitive filter calls. 
    const setData = (geoData: IGeoData[]) => {
        setPendingData(CompletionFilter.getPending(geoData));
        setUncompletedData(CompletionFilter.getUncompleted(geoData));
        setCompletedData(CompletionFilter.getCompleted(geoData));
    }

    useEffect(() => setData(project.geoData), [project]);


    return (
        <Container>
            <Marker background={project.color} />
            <Header>
                <div style={{
                        height: 35, 
                        width: 35, 
                        backgroundColor: project.color,
                        borderRadius: 5,
                        marginRight: 10
                    }} 
                />
                <TitleDiv>
                    <TopTitle>PROJEKT</TopTitle>
                    <Title size={15}>{project.name}</Title>
                </TitleDiv>
            </Header>
            <DataList>
                <DataTitle 
                    size={12} 
                    isTop={true} 
                    icon={UncompletedIcon}
                >
                    {getCompletionNameState(CompletionState.Uncompleted, Auth.isEmployee())}
                    <InfoComponent 
                        message=    {getCompletionInfo(CompletionState.Uncompleted, Auth.isEmployee())} 
                        css=        {"right: 5px; top: 5px;"}
                    />
                </DataTitle>
                {uncompletedData.length === 0 && 
                   <NoDataComponent color={Color.darkGrey}/>
                }
                {uncompletedData.length !== 0 && 
                    uncompletedData.map(d => 
                        <GeoDataContainer 
                            key={d.id}
                            geoData={d}
                            openData={openGeoData}
                        />
                    )
                }
                <DataTitle 
                    size={12} 
                    isTop={false} 
                    icon={PendingIcon}
                >
                    {getCompletionNameState(CompletionState.Pending, Auth.isEmployee())}
                    <InfoComponent 
                        message=    {getCompletionInfo(CompletionState.Pending, Auth.isEmployee())} 
                        css=        {"right: 5px; top: 5px;"}
                    />
                </DataTitle>
                {pendingData.length === 0 && 
                    <NoDataComponent color={Color.darkGrey}/>
                }
                {pendingData.length !== 0 && 
                    pendingData.map(d => 
                        <GeoDataContainer 
                            key={d.id}
                            geoData={d}
                            openData={openGeoData}
                        />
                    )
                }
                <DataTitle 
                    size={12} 
                    isTop={false} 
                    icon={CompletedIcon}
                >
                    {getCompletionNameState(CompletionState.Completed, Auth.isEmployee())}
                    <InfoComponent 
                        message=    {getCompletionInfo(CompletionState.Completed, Auth.isEmployee())} 
                        css=        {"right: 5px; top: 5px;"}
                    />
                </DataTitle>
                {completedData.length === 0 && 
                    <NoDataComponent color={Color.darkGrey}/>
                }
                {completedData.length !== 0 && 
                    completedData.map(d => 
                        <GeoDataContainer 
                            key={d.id}
                            geoData={d}
                            openData={openGeoData}
                        />
                    )
                }
            </DataList>
        </Container>
    )
} 

export default ProjectDataList;