import { AxiosResponse } from "axios";
import { normalize, schema } from "normalizr";
import { OrmState } from "redux-orm";

import orm, { Schema } from "./orm";
import { RootState } from "./bootstrap"

import Sleipnir from "../api/Sleipnir";
import APIRequest from "../api/APIRequest";

import { IAuthenticatedUser, IOrganisation, Role } from "../api/GeoDocModels";
import Auth from "./auth";



interface NormalizerResult {
    result: unknown;
    entities: schema.Entity;
} 

enum SchemaEntity {
    Organisation = "organisations",
    User = "users",
    TimeReport = "timeReports",
    Comment = "comments",
    Completion = "completions",
    GeoData = "geoData",
    Project = "projects"
}



/**
 * Performs retrieval of data from
 * Sleipnir via API.
 * 
 * @returns: Sleipnir response as unnormalized JSON object. 
 */
const getData = async (): Promise<AxiosResponse> => await Sleipnir.request(APIRequest.getProjects, null, true);


/**
 * Normalize data from Sleipnir with Normalizr.
 * 
 * Many APIs, public or not, return JSON data that has deeply nested objects. 
 * Using data in this kind of structure is often very difficult for JavaScript applications, 
 * especially those using Flux or Redux.
 * 
 * Normalizr is a small, but powerful utility for taking JSON with a schema definition 
 * and returning nested entities with their IDs, gathered in dictionaries.
 * 
 * @param data: JSON object data from Sleipnir.
 * @returns: Normalized JSON data.
 */

export function normalizeData(data: Record<string, any>): NormalizerResult {  

    const { Entity } = schema;

    // Setup entity schemas
    const organisation = new Entity(SchemaEntity.Organisation)
    const user = new Entity(SchemaEntity.User, {
        organisation: organisation
    })
    const comment = new Entity(SchemaEntity.Comment, {
        user: user
    })
    const timeReport = new Entity(SchemaEntity.TimeReport, {
        user: user,
        comment: comment
    })
    const completion = new Entity(SchemaEntity.Completion, {
        user: user
    })
    const geoData = new Entity(SchemaEntity.GeoData, {
        addedBy: user,
        internalCompletion: completion,
        externalCompletion: completion,
        comments: [comment],
        timeReports: [timeReport]
    })
    const project = new Entity(SchemaEntity.Project, {
        geoData: [geoData],
        organisation: organisation
    })

    // Normalize that shit. 
    return normalize(data, [project]) // [Entity] signifies data is and will be formatted in array
}


/**
 * Load ORM state from normalized data.
 * Iterate normalized data in entities to create 
 * model instances in ORM state. 
 * 
 * Attempt to add additional data for given model name. 
 * 
 * @param data Normalized data.
 * @param additional Extra data as JSON for model name to be added to ORM store.
 */
export function loadORMState(data: NormalizerResult, additional?: {[key: string]: Record<string, unknown>[]}): OrmState<Schema> {

    // Begin a mutating session with that state.
    // `state` will be mutated.
    const state = orm.getEmptyState();
    const session = orm.mutableSession(state);

    // Model classes are available as properties of the Session instance.
    const { 
        Project, 
        GeoData,
        Comment,
        Completion,
        TimeReport,
        User,
        Organisation
    } = session;

    // Declare entity instatiation order
    // Order of entity names will determine order 
    // of instatiation. Models with no dependencies 
    // i.e. lower in the model dependency hierarchy 
    // should be instantiated first. 
    const entityModelMap = {
        [SchemaEntity.Organisation] : Organisation,
        [SchemaEntity.User]: User,
        [SchemaEntity.TimeReport]: TimeReport,
        [SchemaEntity.Comment]: Comment, 
        [SchemaEntity.Completion]: Completion,
        [SchemaEntity.GeoData]: GeoData,
        [SchemaEntity.Project]: Project
    };
    
    // Instantiate model instances
    Object.keys(SchemaEntity).forEach((entityKey: string) => {
        const entityData = data.entities[SchemaEntity[entityKey]]
        if (entityData === undefined) return;
        for (const [_, props] of Object.entries(entityData)) {
            entityModelMap[SchemaEntity[entityKey]].create(props);
        }
    })

    // Add additional bootstrap data to ORM state
    if (additional !== undefined) {
        for (const [modelName, entries] of Object.entries(additional)) {
            entries.forEach(entry => {
                try {
                    //@ts-ignore
                    if (!entityModelMap[modelName].idExists(entry.id)) entityModelMap[modelName].create(entry);
                } catch {
                    console.log(`Error: Could not instantiate data ${entry} of model ${modelName} `)
                }
            })
        }
    }
    return state
}

/**
 * Fetch organisations
 */
async function getOrganisations(): Promise<IOrganisation[]> {
    const orgs = await Sleipnir.request<IOrganisation>(APIRequest.getOrganisations) as IOrganisation[];
    orgs.forEach(org => {
        delete org["users"]
    })
    return orgs
}


/**
 * Fetch logged in user.
 */
async function getUser(): Promise<IAuthenticatedUser> {
    const res: IAuthenticatedUser = await Sleipnir.authenticate()
    const user: IAuthenticatedUser = {
        ...res,
        role: res.role !== null ? Role[res.role] : null,
    }
    return user;
}


/**
 * Requests JSON formatted data from Sleipnir.
 * Data is normalized and then used for ORM model instantiation.
 * Returns a setup ORM session state.
 * Session state is dispatched as BOOTSTRAP_ACTION_KEY action 
 * which triggers hydration of ORM state using bootstrapHydrator reducer.
 * 
 * @returns: ReduxORM State loaded from Sleipnir.
 */

async function fetchState(): Promise<RootState> {

    // Retrieve logged in user and 
    const user: IAuthenticatedUser = await getUser();
    Auth.setUser(user);

    // Retrieve all organisations
    let organisations: IOrganisation[];
    if (user.isEmployee) 
        organisations = await getOrganisations()

    // Retrieve GeoDoc data from Sleipnir
    const data: AxiosResponse = await getData()

    // Setup additional data. 
    // Authenticated user might not be retrieved 
    // from the database in getProjects() request.
    const additionalState = {
        [SchemaEntity.User]: [{
            ...user
        }]
    }
    if (user.isEmployee) 
        additionalState[SchemaEntity.Organisation] = organisations as unknown as Record<string, unknown>[]

    // Format and load data
    const normalizedData: NormalizerResult = normalizeData(data.data);
    const ormState: OrmState<Schema> = loadORMState(normalizedData, additionalState);
    const state: RootState = {
        orm: ormState,
        user: user
    }

    return state
}

export default fetchState;