import React, { useEffect, useState }from "react";
import styled from "styled-components";
import moment from "moment";


import Auth from "../../datasource/auth";
import CompletionFilter from "../../datasource/completion";
import Color from "../../resources/colors";
import { IGeoData, ITimeReport, TimeReportType } from "../../api/GeoDocModels";

import DeleteIcon from "../../resources/images/delete-icon.png";
import AdditionalIcon from "../../resources/images/hourglass-done-icon.png";
import InitialIcon from "../../resources/images/hourglass-close-icon.png";

import ListItemUserMarker from "../ListItemUserMarker";
import { CompletionState } from "../../pandora/pandora";
import { ItemContainerAnimation, FadeAnimation } from "../../pandora/animations";
import { HorizontalFlex, VerticalFlex } from "../../pandora/styled";
import NoDataComponent from "../NoDataComponent";


const ListContainer = styled(VerticalFlex)<{isProjectView: boolean}>`
    align-items: center;    

    overflow: auto; 
    overflow-x: hidden; 

    height: ${props => props.isProjectView ? "80%" : "100%"};
`

const DeleteButton = styled("div")<{contentExceeds: boolean}>`
    display: none;
    position: absolute;

    top: ${props => props.contentExceeds ? 40 : 10}px; 
    right: 20px;

    height: 40px;
    width: 40px;

    border-radius: 5px;
    background-color: ${Color.red};

    background-image: url(${DeleteIcon});
    background-repeat: no-repeat;
    background-size: 20px 20px; 
    background-position: 50% 50%;

    cursor: pointer;
    transition: 0.3s ease;

    &:hover {
        background-color: ${Color.lightRed};  
    }

    animation: ${FadeAnimation} 0.3s 1;
`

const TimeReportItemDiv = styled(HorizontalFlex)<{
        isStatic: boolean, 
        internalUser: boolean, 
        icon: string, 
        isDeletable: boolean,
        contentExceeds: boolean
    }>`

    position: relative;
    min-height: ${props => props.contentExceeds ? 100 : 40}px;
    width: 90%;
    max-width: 90%;
    margin-bottom: 10px;
    padding: 10px;

    justify-content: space-between;
    align-items: center;

    line-height: 40px;
    word-break: break-all;

    border-radius: 5px;
    background: ${Color.darkGrey};
    overflow: hidden;
    cursor: ${props => !props.isStatic ? "pointer" : "auto"};

    background-image: url(${props => props.icon});
    background-position: 95% 50%;
    background-size: 15px 15px;
    background-repeat: no-repeat;
    background-color: ${Color.darkGrey};

    &: first-child {
        margin-top: 10px;
    }

    ${props => props.internalUser ? `
        border-left: solid 4px ${Color.green};
    ` : ""}

    transition: 0.2s ease;
    animation: ${ItemContainerAnimation} 0.3s 1;

    ${props => !props.isStatic ? `
            &:hover {
                background-color: ${Color.extraLightGrey};
            }
        `
        : 
        `
            ${props.isDeletable ? `
                &:hover {
                    ${DeleteButton} {
                        display: block;
                    }
                }
            ` : ""}
        `
    }

    ${props => props.css ?? ""}
`  

const Title = styled("h1")<{css?: string}>`
    position: relative;
    color: white;
    display: block;
    font-size: 8pt;
    line-height: 12pt;
    align-text: left;
    margin-left: 20px;
    max-width: 60%;

    font-style: normal;
    font-weight: bold;

    ${props => props.css ?? ""}
`

const TimeTitle = styled(Title)`
    position: absolute;
    left: 80%;
    font-size: 15px;
    text-align: center;
    width: 50px;
    margin-left: 0;
`



const TimeReportStateIconMap = {
    [TimeReportType.Initial]: InitialIcon,
    [TimeReportType.Additional]: AdditionalIcon
}

const TimeReportStateMessageMap = {
    [TimeReportType.Initial]: "ÄTA Upptäckt",
    [TimeReportType.Additional]: "ÄTA Åtgärd"
}


export type TimeReportItemPair = [IGeoData, ITimeReport];

interface TimeReportItemProps {
    item: TimeReportItemPair
    deleteTimeReport?: (timeReport: ITimeReport) => void
    openGeoData?: (geoData: IGeoData) => void
}

const TimeReportItem = (props: TimeReportItemProps): React.ReactElement => {

    const { 
        item,
        deleteTimeReport,
        openGeoData
    } = props;

    // State
    const geoData: IGeoData = item[0];
    const timeReport: ITimeReport = item[1];
    const isStatic: boolean = (openGeoData === undefined);
    const fromUser: boolean = Auth.currentUser.id === timeReport.user.id;

    const isCompleted = CompletionFilter.determine(geoData) === CompletionState.Completed;
    const isDeletable: boolean = fromUser && isStatic && !isCompleted;

    const contentExceeds = timeReport.comment !== null && timeReport.comment.content.length > 70

    // Actions
    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (openGeoData !== undefined) openGeoData(geoData);
    }

    return (
        <TimeReportItemDiv 
            internalUser=   {timeReport.user.isEmployee} 
            icon=           {TimeReportStateIconMap[timeReport.type]} 
            isStatic=       {isStatic} 
            isDeletable=    {isDeletable}
            contentExceeds= {contentExceeds}
            onClick=        {handleClick}
            css=            {"justify-content: flex-start;"} 
        >
            <ListItemUserMarker item={timeReport}/>
            <VerticalFlex css={"align-items: flex-start;"}>
                {isDeletable && 
                    <DeleteButton 
                        contentExceeds= {contentExceeds}
                        onClick=        {() => deleteTimeReport(timeReport)}
                    />
                }
                <Title>{TimeReportStateMessageMap[timeReport.type]}</Title>
                {timeReport.comment !== null && 
                    <Title css={"font-weight: normal; font-style: italic; margin-top: 0px; text-align: left;"}>{timeReport.comment.content}</Title>
                }
            </VerticalFlex>
            <TimeTitle>{timeReport.hours + "h"}</TimeTitle>
        </TimeReportItemDiv>
    )
}


interface TimeReportListProps {
    items: TimeReportItemPair[]
    isProjectView: boolean;
    deleteTimeReport?: (timeReport: ITimeReport) => void
    openGeoData?: (geoData: IGeoData) => void
}

const TimeReportList = (props: TimeReportListProps) => {

    const { 
        items,
        isProjectView,
        deleteTimeReport,
        openGeoData
    } = props;

    // State
    const [content, setContent] = useState<TimeReportItemPair[]>([]);

    // Effects
    useEffect(() => setData(items), [items]);

    // Actions 

    /**
     * Sort passed activity item pairs in reverse
     * chronological order to present latest updates first. 
     * @param items 
     */
    const setData = (items: TimeReportItemPair[]) => {
        const _orderedContent: TimeReportItemPair[] = items.sort((a: TimeReportItemPair, b: TimeReportItemPair) => {
            return b[1].time.getTime() - a[1].time.getTime();
        })
        setContent(_orderedContent);
    }

    return (
        <ListContainer isProjectView={isProjectView}>
            {content.length !== 0 &&
                content.map((i: TimeReportItemPair, id) => 
                    <TimeReportItem 
                        key=                {id}
                        item=               {i}
                        openGeoData=        {openGeoData}
                        deleteTimeReport=   {deleteTimeReport}
                    />
                )
            }
            {content.length === 0 &&
                <NoDataComponent 
                    message=    {"Inga tidrapporter"}
                    color=      {Color.darkGrey}
                    textColor=  {Color.darkGrey}
                    css=        {"padding: 20px;"}
                />
            }
        </ListContainer>
    )
}

export default TimeReportList;