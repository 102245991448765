import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux"; 

import Sleipnir from "../../../api/Sleipnir";
import APIRequest from "../../../api/APIRequest";
import { IProject } from "../../../api/GeoDocModels";
import { ClientAction } from "../../../datasource/actions";
import { VerticalFlex } from "../../../pandora/styled";

import Color from "../../../resources/colors";
import AddIcon from "../../../resources/images/add-icon.png";

import { GenericPopupProps } from "../../../components/GenericPopup";
import { Confirmation } from "../../../components/ConfirmationPopup";
import ProjectListItem from "./ProjectListItem";
import AddProjectView from "./AddProjectView";
import NoDataComponent from "../../../components/NoDataComponent";


const Title = styled("h1")<{size: number, weight?: string}>`
    font-size: ${props => props.size}px;
    color: white;
    font-weight: ${props => props.weight ?? "bold"};
    text-align: left;
    margin: 0px;
    white-space: nowrap;
    width: auto;
    margin-bottom: 20px;
`

const ProjectListContainer = styled(VerticalFlex)`
    position: relative;
    width: auto;
    height: 
    margin-top: 10px; 
    overflow-x: visible;
    overflow-y: auto;
`

const AddProjectButton = styled.div`
    position: absolute;
    right: 20px;
    bottom: 20px;

    height: 50px;
    width: 50px;
    
    border-radius: 5px;
    background-color: ${Color.green};

    background-image: url(${AddIcon});
    background-repeat: no-repeat;
    background-size: 25px 25px; 
    background-position: 50% 50%;

    cursor: pointer;
    transition: 0.3s ease;

    &:hover {
        background-color: ${Color.lightGreen};        
    }
`




interface ProjectsSectionProps {
    projects: IProject[]
    openProject: (project: IProject) => void
    setConfirmation: (value: Confirmation | null) => void
    setPopup: (value: GenericPopupProps | null) => void
}

const ProjectsSection = (props: ProjectsSectionProps): React.ReactElement => {

    const dispatch = useDispatch();

    // State
    const [projects, setProjects] = useState<IProject[]>(props.projects);
    const [showAddProject, setShowAddProject] = useState<boolean>(false);

    // Effects
    useEffect(() => setProjects(props.projects), [props.projects]);

    // Actions
    const toggleAddProject = () => { setShowAddProject(!showAddProject) }
    
    /**
     * Request project deletion 
     * with confirmation. 
     */
    const deleteProject = (project: IProject) => { 

        // Request project deletion and 
        // update Redux store. 
        const performDeletion = async () => {
            try {
                const _ = await Sleipnir.request(APIRequest.deleteProject, {id: project.id})
                dispatch(ClientAction.deleteProject(project.id));
            
                props.setPopup({
                    title: "Arkiverat!",
                    message: `Projekt ${project.name} har arkiverats.`,
                    color: Color.green,
                    setPopup: props.setPopup
                }) 
            } catch (e) {
                props.setPopup({
                    title: "Ett fel uppstod.",
                    message: `${e.message}`,
                    color: Color.red,
                    setPopup: props.setPopup
                }) 
            }
        }
        
        // Require project deletion confirmation
        props.setConfirmation({
            title: "Varning!",
            message: `Vill du arkivera projekt ${project.name}?`,
            callback: performDeletion,
            reset: props.setConfirmation
        })
    } 
    
    return (
        <div style={{height: '100%'}}>
            {showAddProject && 
                <AddProjectView 
                    toggle= {toggleAddProject}
                />
            }
            {!showAddProject && (
                    <ProjectListContainer>
                        <Title size={30}>Projekt</Title>
                        {projects.length !== 0 ? 
                            projects.map((p: IProject) => 
                                <ProjectListItem 
                                    key=                {p.id} 
                                    project=            {p}
                                    deleteProject=      {deleteProject}
                                    openProject=        {props.openProject}
                                />    
                            )
                            :
                            <NoDataComponent 
                                message=    {"Inga projekt"}
                                css=        {"padding: 20px;"}
                            />
                        }
                        
                    </ProjectListContainer>
                )
            }
            {!showAddProject && <AddProjectButton onClick={toggleAddProject}/>}
        </div>
    )
}

export default ProjectsSection;




