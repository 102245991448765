import styled from "styled-components";

import Color, { alpha } from "../resources/colors";
import { FadeAnimation } from "../pandora/animations";

const Overlay = styled("div")<{css? : string}>`
    background: ${ alpha(Color.darkGrey, 0.7) };
    height: 100%;
    width: 100%;
    position: fixed;

    animation: ${FadeAnimation} 0.2s linear 1;

    ${props => props.css ?? ""}
`
export default Overlay;

