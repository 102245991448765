import React from "react";
import styled, { keyframes } from "styled-components";

import Color, { alpha } from "../../resources/colors";
import LogoIcon from "../../resources/images/logo.png";
import backgroundImage from "../../resources/images/measurement-background.jpg";



const EntranceAnimation = keyframes`
    from {
        transform: scale(0.7);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
`

const LoadingTopLayer = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    
    background-image: url(${backgroundImage});
    background-size: cover;
`

const LoadingBackground = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    background: ${alpha(Color.darkGrey, 0.7)};
`

const CenterDiv = styled.div`
    top: 0;
    bottom: 0;
    margin: auto;
    width: 250px;
    height: 250px;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    background: rgba(44, 50, 58, 0.6);

    animation: ${EntranceAnimation} 0.3s 1;
`

const LoadingImage = styled.img`
    width: 100px;
    height: auto;
`

const LoadingTitle = styled("h1")<{size: number, lower?: boolean}>`
    margin: auto;
    text-align: center;
    font-size: ${props => props.size}pt;
    font-family: 'Montserrat';
    color: white;
    margin-top: 20px;
    margin-bottom: ${props => props.lower ? "20px" : "null"}
`

const LoadingScreen = (): React.ReactElement => {
    return (
        <LoadingTopLayer>
            <LoadingBackground>
                <CenterDiv>
                    <LoadingImage src={LogoIcon}/>
                </CenterDiv>
                <LoadingTitle size={60}>GeoDoc</LoadingTitle>
                <LoadingTitle size={15}>Loading...</LoadingTitle>
                <div>
                    <LoadingTitle size={10} lower>Copyright © 2020 Atritec AB. All rights reserved.</LoadingTitle>
                </div>
            </LoadingBackground>
        </LoadingTopLayer>
    );
}

export default LoadingScreen;