import { ORM, OrmState } from "redux-orm";

import { Schema } from "./orm";
import fetchState from "./stateLoader";
import { IAuthenticatedUser } from "../api/GeoDocModels";



export interface RootState { 
    orm: OrmState<Schema>
    user: IAuthenticatedUser | null
}
export const BOOTSTRAP_ACTION_KEY = "BOOTSTRAP";


/**
 * Setup initial state for ORM here.
 * This initial state will be applied in case of 
 * network connection errors as bootstrap state hydration 
 * is ideally applied soon after Redux store and ORM instantiation.
 * 
 * @param orm: ORM instance.
 * @returns: initialState as object with `orm` key.
 */

export function initialState(orm: ORM<Schema>): RootState {
    const state = orm.getEmptyState();
    const user = null;
    return { 
        orm: state,
        user: user
    }
}

/**
 * Bootstrap action. 
 * Dispatched BOOSTRAP_ACTION_KEY to identify bootstrap 
 * hydration action event. Retrieves loaded session state from fetchData.
 */

export const bootstrap = () => async (dispatch): Promise<void> => {
    try {
        const state = await fetchState();
        dispatch({
            type: BOOTSTRAP_ACTION_KEY,
            payload: state
        })
    } catch (error) {
        dispatch({
            type: BOOTSTRAP_ACTION_KEY + "_ERROR",
            payload: error
        })
    }
}

/**
 * BootstrapHydrator simply returns the action payload
 * which is this case comes from the state dispatched above in bootstrap()
 * Essentially a hydrating reducer. 
 * 
 * @param state: Current state
 * @param action: Dispatch action type.
 * @returns: Untouched payload for hydration. 
 */
export const bootstrapHydrator = (state, action): RootState => {
    return action.payload
}