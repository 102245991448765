import React from "react";
import styled from "styled-components";

import { CompletionState } from "../../pandora/pandora";
import { FilterBar } from "./FilterBar";
import { SearchBar } from "./SearchBar";



const Container = styled.div`
    position: fixed;
    height: fit-content;
    width: 20%; 
    left: auto;
    right: 15px;
    top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
`

interface MapToolsProps {
    selectedFilter: CompletionState | null;
    setFilter: (filter: CompletionState) => void;
}

export const MapTools = (props: MapToolsProps): React.ReactElement => {
    return (
        <Container>
            <SearchBar />
            <FilterBar 
                selectedFilter= {props.selectedFilter}
                setFilter=      {props.setFilter}
            />
        </Container>
    );
}