import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Auth from "../../../datasource/auth";

import CloseIcon from "../../../resources/images/close-icon.png";
import PendingIcon from "../../../resources/images/hourglass.png";
import CompletedIcon from "../../../resources/images/check-mark-icon.png";

import { IProject, IGeoData } from "../../../api/GeoDocModels"; 
import Color from "../../../resources/colors";

import CompletionFilter from "../../../datasource/completion";
import { CompletionState, CompletionStateColorMap, getCompletionNameState } from "../../../pandora/pandora";

import { HorizontalFlex, VerticalFlex } from "../../../pandora/styled";
import GeoDataContainer from "../../../components/GeoDataContainer";
import NoDataComponent from "../../../components/NoDataComponent";


const CompletionButton = styled("div")<{state: CompletionState, selectedState: CompletionState}>`
    width: 50%;
    border-radius: 5px;
    color: white;
    font-size: 11px;
    font-weight: bold; 
    text-align: center;
    line-height: 35px;
    margin-left: 5px;
    margin-right: 5px;

    background-color: ${props => props.state === props.selectedState ? CompletionStateColorMap[props.state][0] : Color.darkGrey};
    background-image: url(${props => FilterIconMap[props.state]});
    background-repeat: no-repeat;
    background-position: 12px 50%;
    background-size: 12px 12px;

    user-select: none;
    cursor: ${props => props.state === props.selectedState ? "auto" : "pointer"};
    transition: 0.2s ease;

    &: hover {
        background-color: ${props => CompletionStateColorMap[props.state][1]};
    }
`

const DataList = styled(VerticalFlex)`
    margin-top: 20px;
    height: calc(100% - 75px);
    overflow: auto;
`



const FilterIconMap = { 
    [CompletionState.Uncompleted] : CloseIcon,
    [CompletionState.Pending] : PendingIcon,
    [CompletionState.Completed] : CompletedIcon,
}

interface ProjectGeoDataProps {
    project: IProject
    openGeoDataView: (geoData: IGeoData) => void
}

const ProjectGeoData = (props: ProjectGeoDataProps): React.ReactElement => {

    const { project } = props;

    // State
    const [completionState, setCompletionState] = useState<CompletionState>(CompletionState.Uncompleted);
    const [currentGeoData, setCurrentGeoData] = useState<IGeoData[]>(CompletionFilter.get(completionState, props.project.geoData));

    // Effects
    useEffect(() => { setCurrentGeoData(CompletionFilter.get(completionState, project.geoData)) }, [completionState]);
    useEffect(() => { setCurrentGeoData(CompletionFilter.get(completionState, project.geoData)) }, [project]);

    return (
        <VerticalFlex>
            <HorizontalFlex css={"justify-content: space-evenly; height: 35px; margin-top: 20px;"}>
                {Object.keys(CompletionState).map((f) => 
                    <CompletionButton 
                        key=            {f}
                        state=          {CompletionState[f]} 
                        selectedState=  {completionState}
                        onClick=        {() => setCompletionState(CompletionState[f])}
                    >{getCompletionNameState(CompletionState[f], Auth.isEmployee())}</CompletionButton>
                    )
                }                
            </HorizontalFlex>
            <DataList>
                {currentGeoData.length != 0 && 
                    currentGeoData.map(d => 
                        <GeoDataContainer 
                            key=        {d.id}
                            geoData=    {d}
                            openData=   {props.openGeoDataView}
                        />
                    )
                }
                {currentGeoData.length === 0 && 
                    <NoDataComponent 
                        message=    {"Inga objekt"}
                        color=      {Color.darkGrey}
                        textColor=  {Color.darkGrey}
                        css=        {"padding: 20px;"}
                    />
                }
            </DataList>
        </VerticalFlex>
    )
}

export default ProjectGeoData;