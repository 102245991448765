import React from "react";
import styled from "styled-components";
import moment from "moment";

import { IComment, ICompletion, ITimeReport } from "../api/GeoDocModels"; 
import { DATE_FORMAT } from "../pandora/pandora";



const ActivityItemUserDiv = styled.div`
    height: 40px;
    min-width: 55px;
    width: fit-content;
    color: white;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    margin-left: 10px;
`

const ActivityItemUserLabel = styled("h5")<{size: number, weight: string, b?: string}>`
    position: relative;
    height: ${props => props.size + 5}px;
    line-height: ${props => props.size}px;
    font-size: ${props => props.size}px;
    font-weight: ${props => props.weight};
    text-align: center;
    margin: 0;
    padding: 0;
    margin-top: 1px;
`




interface ListItemUserMarkerProps {
    item: IComment | ICompletion | ITimeReport
}

const ListItemUserMarker = (props: ListItemUserMarkerProps): React.ReactElement => {

    const { user, time } = props.item;

    const initials: string = user.name.split(" ").map((n)=>n[0]).join("");
    const timestamp: string = moment(time, DATE_FORMAT).format("DD/MM HH:mm")

    return (
        <ActivityItemUserDiv>
            <ActivityItemUserLabel size={18} weight={"bold"}>{initials}</ActivityItemUserLabel>
            <ActivityItemUserLabel size={10} weight={"normal"} b={"blue"}>{timestamp}</ActivityItemUserLabel>
        </ActivityItemUserDiv>
    )
}


export default ListItemUserMarker;

