import styled from "styled-components";

import CloseIcon from "../resources/images/close-icon.png";
import ArrowIcon from "../resources/images/right-arrow.png";
import LinkIcon from "../resources/images/link-icon.png";
import NotificationIcon from "../resources/images/notification-icon.svg";

import Color from "../resources/colors";
import { ScaleAnimation } from "./animations";


export const Flex = styled("div")<{direction: string, css?: string}>`
    display: flex;
    flex-direction:  ${props => props.direction};
    height: 100%;
    width: 100%;
    ${props => props.css ?? ""}
`

export const HorizontalFlex = styled("div")<{css?: string}>`
    display: flex;
    width: auto;
    height: auto;
    ${props => props.css ?? ""}
`

export const VerticalFlex = styled("div")<{css?: string}>`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    ${props => props.css ?? ""}
`

export const CloseButton = styled("div")<{css?: string}>`
    position: absolute;
    width: 30px;
    height: 30px;
    right: 30px;
    top: 30px;    
    border-radius: 5px;

    background-image: url(${CloseIcon});
    background-repeat: no-repeat;
    background-size: 70% 70%;
    background-position: center;

    cursor: pointer;
    z-index: 5;
    transition: 0.2s ease;

    ${props => props.css ?? ""}

    &:hover {
        background-color: ${Color.lightGrey};
    }
` 

export const ViewContainer = styled(HorizontalFlex)`
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;    
    position: relative;
`

export const SaveButton = styled("div")<{css?: string}>`
    position: relative;
    width: fit-content;
    height: 30px;
    border-radius: 5px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;

    background: ${Color.green};

    font-size: 10px;
    font-weight: bold;
    color: white;
    line-height: 30px;

    user-select: none;
    cursor: pointer;
    z-index: 5;
    transition: 0.3s ease;

    animation: ${ScaleAnimation} 0.2s linear 1;

    ${props => props.css ?? ""}

    &:hover {
        background: ${Color.lightGreen};
        padding-left: 20px; 
        padding-right: 20px;
    }
`

export const NameInput = styled("input")<{enabled: boolean}>`
    position: relative;
    margin: 0;
    width: 40ch;
    max-width: 50ch;
    outline: none;
    border: 0px;
    padding: 5px;
    border-radius: 5px;

    background: transparent;

    text-align: left;
    color: white;
    font-size: 25px;
    font-family: Montserrat, sans-serif;
    font-weight: bold;
    box-sizing: border-box;

    transition: 0.3s ease;

    ::placeholder {
        color: white;
        opacity: 50%;
        font-size: 20px;
    }

    ${props => props.enabled ? `
        &: focus {
            background: ${Color.lightGrey};
        }

        &: hover {
            background: ${Color.lightGrey};
        }
    ` : ""}
`

export const DescriptionInput = styled("textarea")<{enabled: boolean}>`
    margin-top: 10px;
    color: white;
    height: fit-content;

    font-family: Montserrat, sans-serif;
    font-size: 10pt;
    text-align: left;
    font-weight: normal;
    
    background: transparent;
    resize: none;
    outline: none;
    border-width: 0;

    ${props => props.enabled ? `
    &: focus {
        background: ${Color.lightGrey};
    }

    &: hover {
        background: ${Color.lightGrey};
    }
` : ""}
`

export const Arrow = styled("div")<{rotate: boolean}>`
    position: absolute;
    width: 15px;
    height: 15px;

    right: 15px;

    transition: 0.3s ease;
    transform: rotateX(${props => props.rotate ? 180 : 0}deg);

    background-image: url(${ArrowIcon});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`


export const ActionButton = styled("div")<{background: [string, string], icon: string, hover?: boolean}>`
    margin-top: 10px;
    margin-bottom: 10px;

    background-color: ${props => props.background[0]};
    border-radius: 5px;

    color: white;
    font-size: 9pt;
    text-align: center;
    font-weight: bold;
    line-height: 40px;

    height: 40px;
    width: auto;

    background-image: url(${props => props.icon});
    background-repeat: no-repeat;
    background-size: 15px 15px;
    background-position: 15px 50%;
    user-select: none;

    cursor: pointer;

    transition: 0.1s linear;

    &:hover {
        padding: 10px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    &: ${props => props.hover !== null ? (props.hover ? "hover" : "active") : "active"} {
        background-color: ${props => props.background[1]};
    }
`

export const ContentStateButton = styled("div")<{clicked: boolean}>`
    min-width: 10%;
    width: fit-content;
    height: 50px;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 10px;

    background: ${props => props.clicked ? Color.lightGrey : "black"};

    cursor: pointer;

    color: white;
    font-size: 15px;
    line-height: 50px;
    text-align: center;
    font-weight: bold;
    user-select: none;

    transition: 0.3s ease;

    ${props => !props.clicked ? `
        &:hover {
            background: ${Color.lightGrey};
            transform: scale(0.95);
        }
        &:active {
            background: ${Color.extraLightGrey};
        }
    ` : ""}
`

export const LinkField = styled.input`
    height: 30px;
    width: 100%;
    padding: 5px;
    border: 0;

    color: white;
    font-family: Montserrat, sans-serif;
    font-size: 11px;
    outline: none;
    text-align: center;

    border-radius: 5px;
    background-color: ${Color.darkGrey};
`

export const LinkButton = styled.div`
    background-color: ${Color.blue};
    border-radius: 5px;
    margin-right: 10px;        

    height: 40px;
    min-width: 40px;

    background-image: url(${LinkIcon});
    background-repeat: no-repeat;
    background-size: 40% 40%;
    background-position: center;
    user-select: none;

    cursor: pointer;

    transition: 0.1s linear;

    &:hover {
        background-color: ${Color.lightBlue};
    }
`

export const ProjectMarker = styled("div")<{color: string}>`
    position: relative;
    width: fit-content;
    height: 20px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;

    color: white;
    font-size: 11px;
    font-weight: normal;
    line-height: 20px;
    margin-right: 10px;

    background-color: ${props => props.color};

    cursor: pointer;
    z-index: 5;
    transition: 0.3s ease;

    &: hover {
        padding-left: 30px;
        padding-right: 30px;
    }
`

export const ProjectSubscriptionButton = styled(ProjectMarker)<{hoverColor: string}>`
    padding-left: 40px;

    background-image: url(${NotificationIcon});
    background-size: 15px 15px;
    background-position: 10px 50%;
    background-repeat: no-repeat;

    user-select: none;

    &: hover {
        background-color: ${props => props.hoverColor};
        padding-left: 40px;
    }
` 
