import React, { useState } from "react";
import styled from "styled-components";

import AddIcon from "../resources/images/add-icon.png";
import Color from "../resources/colors";
import { Flex } from "../pandora/styled";


const TimeReportDiv = styled.input`
    background: ${Color.darkGrey};
    border-radius: 5px;
    box-sizing: border-box;

    padding-left: 10px;
    padding-right: 10px;
    max-width: 100%;
    width: 20%;
    min-height: 50px;
    height: 50px;
    margin-right: 10px;

    border: 0;
    color: white;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    outline: none;

    overflow: auto;
    overflow-x: hidden;
    resize: none;

    transition: 0.3s ease;

    ::placeholder {
        color: white;
        font-family: Montserrat, sans-serif;
        font-weight: bold;
        opacity: 50%;
    }
`

const CommentDiv = styled("textarea")`
    background: ${Color.darkGrey};
    border-radius: 5px;
    box-sizing: border-box;

    padding-left: 10px;
    padding-right: 10px;
    max-width: 100%;
    min-height: 50px;
    height: 50px;
    width: 80%;

    border: 0;
    color: white;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    outline: none;
    line-height: 50px;

    overflow: auto;
    overflow-x: hidden;
    resize: none;

    transition: 0.3s ease;

    ::placeholder {
        color: white;
        font-family: Montserrat, sans-serif;
        font-weight: bold;
        opacity: 50%;
    }
`

const AddTimeReportButton = styled.div`
    position: relative;

    background-color: ${Color.green};
    border-radius: 5px;

    height: 50px;
    width: 50px;
    min-width: 50px;

    margin-left: 10px;

    background-image: url(${AddIcon});
    background-repeat: no-repeat;
    background-size: 40% 40%;
    background-position: center;

    cursor: pointer;

    transition: 0.1s linear;

    &:hover {
        background-color: ${Color.lightGreen};
    }
`

const Title = styled("h1")<{size: number, weight?: string}>`
    font-size: ${props => props.size}px;
    color: white;
    font-weight: ${props => props.weight ?? "bold"};
    text-align: left;
    margin: 0px;
    white-space: nowrap;
    width: auto;
    margin-bottom: 5px;
`





interface TimeReportFieldProps {
    postTimeReport: (hours: number, content: string) => void;    
}

const TimeReportField = (props: TimeReportFieldProps): React.ReactElement => {

    // State
    const [hours, setHours] = useState<string>("");
    const [comment, setComment] = useState<string>(""); 

    // Actions
    const commentInputChange = e => {
        setComment(e.target.value);
    }

    const hoursInputChange = e => {
        // Regex to restrict input to numbers. 
        const re = /^[0-9\b]+$/;
        const value = e.target.value;
        if (value === '' || re.test(value)) {
            setHours(value);
        }
    }

    // Post time report to API. 
    const submit = () => {
        if (hours !== "") {
            props.postTimeReport(parseInt(hours, 10), comment);
            setHours("");
            setComment("");
            document.getElementById("time-report-field").focus()
        }
    }

    const onKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation()
            submit()
        }
    }

    return (
        <Flex direction={"column"} css={`position: relative; padding-top: 20px; height: fit-content; border-top: solid 4px ${Color.darkGrey};`}>
            <Title size={13}>Lägg till ÄTA</Title>
            <Flex direction={"row"} css={`position: relative; padding-top: 10px;`}>
                <TimeReportDiv 
                    id=             {"time-report-field"}
                    value=          {hours}
                    placeholder=    "Timmar"
                    onChange=       {hoursInputChange}
                    onKeyPress=     {onKeyPress}
                />
                <CommentDiv 
                    id=             {"comment-field"}
                    value=          {comment}
                    placeholder=    "Kommentar"
                    onChange=       {commentInputChange}
                    onKeyPress=     {onKeyPress}
                />
                <AddTimeReportButton 
                    onClick={submit}
                />
            </Flex>
        </Flex>
    )
}


export default TimeReportField;