

// Enumerations
export enum Role {
    Admin = "Admin",
    Basic = "Basic"
}

export enum TimeReportType {
    Initial = "Initial",
    Additional = "Additional"
}


// GeoDoc Models
export interface HeimdallModel {
    id: string;
}

export interface GeoDocModel {
    id: number;
}

export interface IProject extends GeoDocModel {
    name: string
    color: string
    description: string
    area: IMultiPolygon | null 
    geoData: IGeoData[]
    organisation: IOrganisation
}

export interface IGeoData extends GeoDocModel {
    name: string
    description: string
    type: string
    geometry: IPoint[]
    time: Date
    addedBy: IUser
    internalCompletion: ICompletion | null
    externalCompletion: ICompletion | null
    comments: IComment[]
    timeReports: ITimeReport[]
    images: string[]
}

export interface IComment extends GeoDocModel {
    time: Date
    user: IUser
    content: string
}

export interface ICompletion extends GeoDocModel {
    time: Date
    user: IUser
}

export interface IUser extends HeimdallModel { 
    name: string
    isEmployee: boolean
    organisation: IOrganisation | null
    phone: string
}

export interface IAuthenticatedUser extends IUser {
    role: Role | null
}

export interface ITimeReport extends GeoDocModel {
    hours: number
    type: TimeReportType
    time: Date
    user: IUser
    comment: IComment | null
}

export interface IImage extends GeoDocModel {
    bytes: string
}

export interface IOrganisation extends HeimdallModel {
    name: string
}



// Misc models
export interface IPoint {
    point: {
        type: string
        coordinates: [number, number]
    }
}

export interface IMultiPolygon {
    type: string
    coordinates: [number, number][][]
}

export interface APIError {
    code: number
    message: string
}

