import { 
    Model,
    fk, 
    attr, 
    QuerySet, 
    ModelType,
} from "redux-orm";

import { IDispatch, ClientActionType } from "./actions";
import { IPoint, IMultiPolygon } from "../api/GeoDocModels";


/**
 * These are the Redux ORM models.
 * The reducer function on every model defines 
 * dispatch actions for every model. The reducer
 * performs the relevant updates to the Redux store. 
 */

interface IProject {
    id: number
    name: string
    color: string
    description: string
    area: IMultiPolygon
    geoData: QuerySet<GeoData>
    organisation: Organisation
}

export class Project extends Model<typeof Project, IProject> {
    static modelName = "Project" as const;
    static fields = {
        name: attr(),
        color: attr({ getDefault: () => "rgb(255, 0, 0)"}),
        description: attr(),
        area: attr(),
        geoData: fk("GeoData", "projects"),
        organisation: fk("Organisation", "projects")
    }
    
    static reducer(action: IDispatch, model: ModelType<Project>, session): void {  
        const { type, payload } = action;
        switch (type) {

            /**
             * Create project. 
             */
            case ClientActionType.createProject:{
                // Extract organisation ID from nested organisation 
                // object passed at project creation time. 
                const _p = {
                    ...payload,
                    organisation: payload.organisationID,
                }
                model.create(_p);
                break;
            }

            /**
             * Delete project
             */
            case ClientActionType.deleteProject:
                model.withId(payload).delete();
                break;
            
            /**
             * Update project with updates in payload. 
             */
            case ClientActionType.updateProject:
                model.withId(payload.id).update(payload.props)
                break;     

            /**
             * Add geodata instance to project. 
             */
            case ClientActionType.addGeoDataToProject: {

                // Create GeoData instances in Redux store
                payload.geoData.forEach(g => {
                    const _geoData = {
                        ...g,
                        addedBy: g.userID
                    }
                    session.GeoData.create(_geoData)
                })

                // Extract new GeoData IDs for association with 
                // Project instance in store. 
                const project = model.withId(payload.id)
                const newGeoDataIDs = payload.geoData.map(g => g.id);
                //@ts-ignore
                project.update({"geoData": [...(project.ref?.geoData ?? []), ...newGeoDataIDs]})
            }
        }
    }
}




interface IGeoData {
    id: number
    name: string
    description: string
    type: string
    geometry: IPoint[]
    time: string
    addedBy: User
    internalCompletion: Completion | null
    externalCompletion: Completion | null
    comments: QuerySet<Comment>
    timeReports: QuerySet<TimeReport>
    images: string[]
}
export class GeoData extends Model<typeof GeoData, IGeoData> {
    static modelName = "GeoData" as const;
    static fields = {
        id: attr(),
        name: attr(),
        description: attr(),
        type: attr(),
        coordinate: attr(),
        time: attr(),
        addedBy: fk("User"),
        internalCompletion: fk("Completion", "internalCompletions"),
        externalCompletion: fk("Completion", "externalCompletions"),
        comments: fk("Comment", "geoData"),
        timeReports: fk("TimeReport", "geoData"),
        images: attr()
    }
    
    static reducer(action: IDispatch, model: ModelType<GeoData>, session): void {  
        const { type, payload } = action;

        /**
         * Perform GeoData update with properties from payload. 
         */
        switch (type) {
            case ClientActionType.updateGeoData: {
                model.withId(payload.id).update(payload.props);
                break;
            }
        }
    }
}



interface IComment {
    id: number
    time: string
    content: string
    user: User
}
export class Comment extends Model<typeof Comment, IComment> {
    static modelName = "Comment" as const;
    static fields = {
        id: attr(),
        time: attr(),
        content: attr(),
        user: fk("User", "comments")
    }

    static reducer(action: IDispatch, model: ModelType<Comment>, session): void {
        const { type, payload } = action;
        switch (type) {

            /**
             * Create comment. 
             */
            case ClientActionType.postComment: {     

                // Extract user ID from nested User object in payload. 
                const _comment = {
                    ...payload.props,
                    user: payload.props.userID
                }  
                const c = model.create(_comment)
                const g = session.GeoData.withId(payload.geoDataID)
                
                // Retrieve comment IDs for association to GeoData instance. 
                const _comments = g.ref.comments !== undefined ? [...g.ref.comments, c.id] : [c.id]
                g.update({"comments": _comments})
                break;
            }

            /**
             * Delete comment. 
             */
            case ClientActionType.deleteComment: { 
                const g = session.GeoData.withId(payload.geoDataID)

                // Remove comment from GeoData instance 
                // and update GeoData instance. 
                //@ts-ignore
                const comments = g.ref.comments.length > 0 ? [...g.ref.comments] : []
                const cID = comments.indexOf(payload.id)
                comments.splice(cID, 1)
                //@ts-ignore
                g.update({"comments": comments})

                // Delete comment. 
                model.withId(payload.id).delete();
                break;
            }
        }
    }
}


interface ICompletion {
    id: number
    time: string
    user: User
}
export class Completion extends Model<typeof Completion, ICompletion> {
    static modelName = "Completion" as const;
    static fields = {
        id: attr(),
        time: attr(),
        user: fk("User", "completions"),
    }
    
    static reducer(action: IDispatch, model: ModelType<Completion>, session): void {  
        const { type, payload } = action;
        switch (type) {

            /**
             * Create completion. 
             */
            case ClientActionType.postCompletion: {  

                // Extract user ID from nested User object in payload. 
                const _completion = {
                    ...payload.props,
                    user: payload.props.userID
                }  

                // Create completion 
                const c = model.create(_completion)

                // Update GeoData instance with reference to 
                // the new completion object. 
                // Depending on the user being an internal or 
                // external user, different GeoData completion properties 
                // are updated. 
                const g = session.GeoData.withId(payload.geoDataID)
                const completionKey = payload.isInternal ? "internalCompletion" : "externalCompletion"
                g.update({[completionKey] : c.id})
                break;
            }

            /**
             * Delete completion. 
             */
            case ClientActionType.deleteCompletion: { 

                // Remove completion from the GeoData association. 
                const g = session.GeoData.withId(payload.geoDataID)
                const completionKey = payload.isInternal ? "internalCompletion" : "externalCompletion";
                g.update({[completionKey] : null})
                model.withId(payload.id).delete();
                break;
            }
        }
    }
}


interface ITimeReport {
    id: number
    hours: number
    type: string
    time: string
    user: User
    comment: Comment | null
}
export class TimeReport extends Model<typeof TimeReport, ITimeReport> {
    static modelName = "TimeReport" as const;
    static fields = {
        id: attr(),
        hours: attr(),
        type: attr(),
        time: attr(),
        user: fk("User", "timeReports"),
        comment: fk("Comment", "timeReport")
    }

    static reducer(action: IDispatch, model: ModelType<TimeReport>, session): void {  
        const { type, payload } = action;
        switch (type) {

            /**
             * Create TimeReport 
             */
            case ClientActionType.postTimeReport: {  
                
                // If a comment was tied to the time report 
                // insert the comment instance into the 
                // redux store. 
                let c;
                const _comment = payload.props.comment   
                if (_comment !== null) {
                    c = session.Comment.create({
                        ..._comment,
                        user: _comment.user.id
                    })
                }

                // Construct and create TimeReport object in store. 
                const _timeReport = {
                    ...payload.props,
                    user: payload.props.userID, 
                    comment: c?.id ?? null
                }  
                const t = model.create(_timeReport)

                // Update GeoData with references 
                // to created comment and TimeReport
                const g = session.GeoData.withId(payload.geoDataID)
                const _comments = g.ref.comments !== undefined ? g.ref.comments : []
                if (c !== undefined) { _comments.push(c.id) }

                const _timeReports = g.ref.timeReports !== undefined ? [...g.ref.timeReports, t.id] : [t.id]
                //@ts-ignore
                g.update({
                    "comments": _comments,
                    "timeReports": _timeReports
                })
                break;
            }

            /**
             * Delete TimeReport 
             */
            case ClientActionType.deleteTimeReport: { 

                // Remove TimeReport refence on GeoData
                const g = session.GeoData.withId(payload.geoDataID)
                //@ts-ignore
                const timeReports = g.ref.timeReports.length > 0 ? [...g.ref.timeReports] : []
                const tID = timeReports.indexOf(payload.id)
                timeReports.splice(tID, 1)
                //@ts-ignore
                g.update({"timeReports": timeReports})
                model.withId(payload.id).delete();
                break;
            }
        }
    }
}


interface IUser {
    id: string
    name: string
    isEmployee: boolean,
    organisation: Organisation
    phone: string
}
export class User extends Model<typeof User, IUser> {
    static modelName = "User" as const;
    static fields = {
        id: attr(),
        name: attr(),
        isEmployee: attr(),
        phone: attr(),
        organisation: fk("Organisation", "users")
    }
}


interface IOrganisation {
    id: string
    name: string
}
export class Organisation extends Model<typeof Organisation, IOrganisation> {
    static modelName = "Organisation" as const;
    static fields = {
        id: attr(),
        name: attr()
    }
}
