import React, { useEffect, useState }from "react";
import styled from "styled-components";

import ActivityList, { MappedActivityItems, ActivityItemPair } from "./ActivityList";
import { IProject, IGeoData, IComment } from "../../api/GeoDocModels";
import { VerticalFlex } from "../../pandora/styled";



const Container = styled(VerticalFlex)<{isProjectView: boolean}>`
    position: relative;
    overflow: auto; 
    height: ${props => props.isProjectView ? "100%": "calc(100% - 110px)"};
`


interface ProjectActivityProps {
    project?: IProject | null
    geoData?: IGeoData | null
    css?: string
    deleteComment?: (comment: IComment) => void;
    openGeoDataView?: (geoData: IGeoData) => void
}

const Activity = (props: ProjectActivityProps): React.ReactElement => {

    const { 
        project,
        geoData,
        deleteComment,
        openGeoDataView
    } = props;

    // State
    const [activityItems, setActivityItems] = useState<MappedActivityItems>({} as MappedActivityItems);

    // Effects
    useEffect(() => {
        if (geoData !== undefined) {
            setData([geoData]);
            return;
        } 
        if (project !== undefined) {
            setData(project.geoData);
        }
    }, [project, geoData]);

    // Actions

    /**
     * Map a list of geodata to their responding activity 
     * data. All activities for a given geodata are mapped to the 
     * GeoData instance names. 
     * @param data 
     */
    const setData = (data: IGeoData[]) => {
        const _activityItems: MappedActivityItems = {} as MappedActivityItems;

        // Iterate data to extract activity data to map. 
        data.forEach((geoData: IGeoData) => {
            const _items: ActivityItemPair[] = [];

            // Get comments and completion data. 
            if (geoData.internalCompletion !== null) _items.push([geoData, geoData.internalCompletion]);
            if (geoData.externalCompletion !== null) _items.push([geoData, geoData.externalCompletion]);
            geoData.comments.forEach(c => _items.push([geoData, c]));

            // Map for each geodata. 
            _activityItems[geoData.name] = _items;
        })

        setActivityItems(_activityItems);
    }

    return (
        <Container isProjectView={project !== undefined}>
            <ActivityList 
                items=          {activityItems}
                isProjectView=  {project !== undefined}
                deleteComment=  {deleteComment}
                openGeoData=    {openGeoDataView}
            />
        </Container>
    )
}


export default Activity;