import React, { useEffect, useState }from "react";
import styled from "styled-components";

import Auth from "../../datasource/auth";
import CompletionFilter from "../../datasource/completion";
import Color from "../../resources/colors";
import { IGeoData, IComment, ICompletion} from "../../api/GeoDocModels";

import CommentIcon from "../../resources/images/comment-icon-full.png";
import DoneIcon from "../../resources/images/check-mark-icon.png";
import DeleteIcon from "../../resources/images/delete-icon.png";

import { FadeAnimation, ItemContainerAnimation } from "../../pandora/animations";
import { CompletionState } from "../../pandora/pandora";
import { HorizontalFlex, VerticalFlex } from "../../pandora/styled";
import ListItemUserMarker from "../ListItemUserMarker";
import NoDataComponent from "../NoDataComponent";


const ListContainer = styled(VerticalFlex)`
    align-items: center;

    margin-top: 20px; 
    padding-bottom: 20px; 

    overflow: auto; 
    overflow-x: hidden; 

    &: first-child {
        margin-top: 0px;
    }
`

const DataListContainer = styled(VerticalFlex)`
    align-items: center;
    height: auto;
    margin-top: 20px;
`

const DeleteButton = styled("div")<{contentExceeds: boolean}>`
    display: none;
    position: absolute;

    top: ${props => props.contentExceeds ? 25 : 10}px; 
    right: 20px;

    height: 40px;
    width: 40px;

    border-radius: 5px;
    background-color: ${Color.red};

    background-image: url(${DeleteIcon});
    background-repeat: no-repeat;
    background-size: 20px 20px; 
    background-position: 50% 50%;

    cursor: pointer;
    transition: 0.3s ease;

    &:hover {
        background-color: ${Color.lightRed};  
    }

    animation: ${FadeAnimation} 0.3s 1;
`

const ActivityItemDiv = styled(HorizontalFlex)<{
        isStatic: boolean, 
        internalUser: boolean, 
        icon: string, 
        isComment: boolean, 
        isDeletable: boolean,
        contentExceeds: boolean
    }>`

    position: relative;
    min-height: ${props => props.contentExceeds ? 70 : 40}px;
    width: 90%;
    max-width: 90%;
    margin-bottom: 10px;
    padding: 10px;

    justify-content: space-between;
    align-items: center;

    line-height: 40px;
    word-break: break-all;

    border-radius: 5px;
    background: ${Color.darkGrey};
    overflow: hidden;
    cursor: ${props => !props.isStatic ? "pointer" : "auto"};

    background-image: url(${props => props.icon});
    background-position: 95% 50%;
    background-size: 15px 15px;
    background-repeat: no-repeat;
    background-color: ${props => props.isComment ? Color.darkGrey : "black" };

    ${props => props.internalUser ? `
        border-left: solid 4px ${Color.green};
    ` : ""}

    transition: 0.2s ease;
    animation: ${ItemContainerAnimation} 0.3s 1;

    ${props => !props.isStatic ? `
            &:hover {
                background-color: ${Color.extraLightGrey};
            }
        `
        : 
        `
            ${props.isDeletable ? `
                &:hover {
                    ${DeleteButton} {
                        display: block;
                    }
                }
            ` : ""}
        `
    }

    ${props => props.css ?? ""}
`  

const Title = styled("h1")`
    color: white;
    font-size: 17px;
    line-height: 20px;
    text-align: left;
    width: 90%;

    margin-bottom: 10px;
`

const ItemTitle = styled(Title)<{isComment: boolean}>`
    font-size: 10px;
    line-height: 12pt;
    text-align: left;

    margin-left: 20px;
    margin-bottom: 0px;
    margin-top: 0px;

    max-width: 70%;

    font-style: ${props => props.isComment ? "italic" : "normal"};
    font-weight: ${props => props.isComment ? "normal" : "bold"};
`


// Item pair tuple consisting of the GeoData instance and the corresponding.
// Comment and Completion instance for display. 
export type ActivityItemPair = [IGeoData, IComment | ICompletion];

// Activity item pair defined above mapped to a string 
// identifier, supposed to be the GeoData name. 
export type MappedActivityItems = {string: ActivityItemPair};


interface ActivityItemProps {
    item: ActivityItemPair
    deleteComment?: (comment: IComment) => void;
    openGeoData?: (geoData: IGeoData) => void
}

const ActivityItem = (props: ActivityItemProps): React.ReactElement => {

    const {
        item,
        deleteComment,
        openGeoData
    } = props;


    // State
    const geoData: IGeoData = item[0];
    const activity: IComment | ICompletion = item[1];

    const isComment: boolean = "content" in activity;
    const isStatic: boolean = (openGeoData === undefined);
    const fromUser: boolean = Auth.currentUser.id === activity.user.id;
    const isCompleted = CompletionFilter.determine(geoData) === CompletionState.Completed;
    const isDeletable: boolean = fromUser && isStatic && isComment && !isCompleted;

    const content: string = isComment ? 
                            `"${(activity as IComment).content}"` 
                            : 
                            `Avvikelsen åtgärdad.`;
    const contentExceeds = content.length > 200


    // Actions

    // Handle click event on component. 
    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (openGeoData !== undefined) 
            openGeoData(geoData);
    }

    return (
        <ActivityItemDiv 
            internalUser=   {activity.user.isEmployee} 
            icon=           {isComment ? CommentIcon : DoneIcon} 
            isStatic=       {isStatic} 
            isComment=      {isComment}
            isDeletable=    {isDeletable}
            contentExceeds= {contentExceeds}
            onClick=        {handleClick}
            css=            {"justify-content: flex-start;"} 
        >
            {isDeletable && 
                <DeleteButton 
                    contentExceeds= {contentExceeds}
                    onClick=        {() => deleteComment(activity as IComment)}
                />
            }
            <ListItemUserMarker item={activity}/>
            <ItemTitle isComment={isComment}>{content}</ItemTitle>
        </ActivityItemDiv>
    )
}






interface ActivityListProps {
    items: MappedActivityItems
    isProjectView: boolean;
    deleteComment?: (comment: IComment) => void;
    openGeoData?: (geoData: IGeoData) => void
}

const ActivityList = (props: ActivityListProps): React.ReactElement => {
    
    const {
        items,
        isProjectView,
        deleteComment,
        openGeoData
    } = props;

    // State
    const [content, setContent] = useState<MappedActivityItems>({} as MappedActivityItems);
    
    // Effects
    useEffect(() => sortData(items), [items]);

    // Actions

    /**
     * Sort passed activity item pairs in reverse
     * chronological order to present latest updates first. 
     * @param items 
     */
    const _sort = (items: ActivityItemPair[]): ActivityItemPair[] => {
        const ordered: ActivityItemPair[] = items.sort((a: ActivityItemPair, b: ActivityItemPair) => {
            return b[1].time.getTime() - a[1].time.getTime();
        })
        return ordered;
    }

    /**
     * Perform sort on the passed GeoData. 
     * @param data 
     */
    const sortData = (data: MappedActivityItems) => {
        const _content: MappedActivityItems = {...data};
        Object.keys(_content).forEach(a => {
            _content[a] = _sort(_content[a])
        })
        setContent(_content);
    }

    return (
        <ListContainer>
            {Object.keys(content).length !== 0 &&
                Object.keys(content).filter(k => content[k].length !== 0).map(k => 
                    <DataListContainer key={k}>
                        {isProjectView && 
                            <Title key={k}>{k}</Title>
                        }
                        {content[k].map((i: ActivityItemPair, id) => 
                            <ActivityItem 
                                key=            {`${k}-${id}`}
                                item=           {i}
                                deleteComment=  {deleteComment} 
                                openGeoData=    {openGeoData}
                            />
                        )}
                    </DataListContainer>        
                )
            }
            {Object.keys(content).filter(k => content[k].length !== 0).length === 0 &&
                <NoDataComponent 
                    message=    {"Ingen aktivitet"}
                    color=      {Color.darkGrey}
                    textColor=  {Color.darkGrey}
                    css=        {"padding: 20px;"}
                />
            }
        </ListContainer>
    )
}

export default ActivityList;