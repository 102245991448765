import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux"; 

import { ClientAction } from "../../datasource/actions";
import RequestFactory from "../../api/RequestFactory";
import { IGeoData, IProject } from "../../api/GeoDocModels";

import { ViewEntranceAnimation } from "../../pandora/animations";
import Color from "../../resources/colors";
import UploadIcon from "../../resources/images/upload-icon.png";

import Overlay from "../../components/Overlay";
import GenericPopup, { GenericPopupProps } from "../../components/GenericPopup";
import ConfirmationPopup, { Confirmation } from "../../components/ConfirmationPopup";
import { VerticalFlex, ViewContainer, CloseButton } from "../../pandora/styled";
import ProjectSelector from "./ProjectSelect";
import UploadZone from "./UploadZone";
import GeoDataContainer from "../../components/GeoDataContainer";



const UploadContainer = styled(VerticalFlex)`
    position: relative;
    background: ${Color.darkGrey};
    border-radius: 10px;
    height: auto;
    min-height: 500px;
    width: 30%;
    overflow: hidden;
    display: flex;
    padding: 30px;

    animation: ${ViewEntranceAnimation} 0.2s linear 1;
`

const Title = styled("h1")<{size: number, weight?: string, css?: string}>`
    font-size: ${props => props.size}px;
    line-height: 35px;
    color: white;
    font-weight: ${props => props.weight ?? "bold"};
    text-align: left;
    
    height: 35px;
    margin: 0px;
    
    white-space: nowrap;
    width: auto;

    ${props => props.css ?? ""}
`

const ContainerIcon = styled("div")<{icon: string}>`
    position: absolute;
    left: 30px;
    top: 30px;

    width: 35px;
    height: 35px;
    background-image: url(${props => props.icon});
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 100% 100%;
`   

const UploadButton = styled.div`
    min-height: 50px;
    border-radius: 5px;
    margin-top: 20px;

    background-color: ${Color.green};
    font-size: 12px;
    color: white;
    line-height: 50px;
    font-weight: bold;

    cursor: pointer;
    user-select: none;

    transition: 0.3s ease;

    &: hover {
        background-color: ${Color.lightGreen};
    }
`

const UploadedDataContainer = styled(VerticalFlex)`
    margin-top: 20px;
    padding: 15px; 
    height: fit-content; 
    max-height: 250px;

    box-sizing: border-box;    
    padding-bottom: 5px;

    background-color: ${Color.lightGrey}; 
    border-radius: 5px; 

    overflow-y: auto;
`





interface UploadViewProps {
    projects: IProject[]
    toggleUploadView: () => void
}

const UploadView = (props: UploadViewProps): React.ReactElement => {

    const dispatch = useDispatch()

    // State
    const [selectedProject, setSelectedProject] = useState<IProject | null>(null);
    const [file, setFile] = useState<File | null>(null);
    const [uploadEnabled, setUploadEnabled] = useState<boolean>(false);

    const [uploadedGeoData, setUploadedGeoData] = useState<IGeoData[]>([]);

    const [popup, setPopup] = useState<GenericPopupProps | null>(null);
    const [confirmation, setConfirmation] = useState<Confirmation | null>(null);


    // Effects
    useEffect(() => {
        setUploadEnabled(file !== null && selectedProject !== null);
        if (file !== null) setUploadedGeoData([]);
    }, [file, selectedProject])

    useEffect(() => setUploadedGeoData([]), [selectedProject])


    // Actions
    const upload = async () => {      
        try {

            // Request upload
            const res: IGeoData[] = await RequestFactory.uploadGeoPackage(selectedProject.id, file) 
            dispatch(ClientAction.addGeoDataToProject(selectedProject.id, res))
            
            // Update state
            setFile(null);
            setUploadedGeoData(res);
            console.log(res)
            setPopup({
                title: "Klart!",
                message: "GeoPackage filen har laddats upp.",
                color: Color.green,
                setPopup: setPopup
            }) 
        } catch (e) {
            setPopup({
                title: "Ett fel uppstod.",
                message: `${e.message}`,
                color: Color.red,
                setPopup: setPopup
            }) 
        }
    }

    /**
     * Close view. 
     * Request confirmation of exit.
     */
    const close = () => {
        if (file !== null) {
            setConfirmation({
                title: "Varning!",
                message: "Vill du avbryta uppladdningen?",
                callback: props.toggleUploadView,
                reset: setConfirmation
            })
        } else {
            props.toggleUploadView()
        }
    }



    return (
        <Overlay onClick={close}>
            <ViewContainer>
                {confirmation !== null && <ConfirmationPopup confirmation={confirmation}/> }
                {popup && <GenericPopup 
                                title=          {popup.title}
                                message=        {popup.message}
                                color=          {popup.color}
                                setPopup=       {setPopup}
                            />
                }
                <UploadContainer 
                    onClick=    {(e) => e.stopPropagation()}
                >
                    <ContainerIcon icon={UploadIcon} />
                    <Title size={25} css={"margin-left: 50px;"}>Ladda upp</Title>
                    <ProjectSelector
                        selectedProject=    {selectedProject}
                        projects=           {props.projects}
                        setProject=         {setSelectedProject}
                        css=                {"margin-top: 30px;"}
                    />
                    <UploadZone 
                        file=       {file}
                        passFile=   {setFile}
                        setPopup=   {setPopup}
                    />
                    {uploadedGeoData.length !== 0 &&
                        <UploadedDataContainer>
                            <Title size={15} css={"margin-bottom: 5px;"}>Uppladdad data</Title>
                            {uploadedGeoData.map(g => 
                                    <GeoDataContainer
                                        key=        {g.id}
                                        geoData =   {g}
                                    />
                                )
                            }
                        </UploadedDataContainer>
                    }
                    {uploadEnabled && 
                        <UploadButton
                            onClick={upload}
                        >Ladda upp!</UploadButton>
                    }
                    <CloseButton 
                        onClick={close}
                    />
                </UploadContainer>
            </ViewContainer>
        </Overlay>

    )
}

export default UploadView;




