import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";

import { DATE_FORMAT } from "../../pandora/pandora";
import Color from "../../resources/colors";
import { ICompletion } from "../../api/GeoDocModels";

import CloseIcon from "../../resources/images/close-icon.png";
import SolveIcon from "../../resources/images/check-mark-icon.png";
import { VerticalFlex } from "../../pandora/styled";



const CompletionDiv = styled(VerticalFlex)<{background: string, icon: string, css?: string}>`
    width: 50%;
    min-height: 30px;
    height: fit-content;
    background: ${props => props.background};
    border-radius: 5px;
    
    padding-top: 10px;
    padding-bottom: 10px;

    color: white;
    line-height: 40px;
    font-size: 15px;
    font-weight: bold;

    background-image: url(${props => props.icon});
    background-repeat: no-repeat;
    background-size: 10px 10px;
    background-position: 90% 50%;

    ${props => props.css ?? ""}
`

const Text = styled("h2")<{css?: string}>`
    color: white;
    font-size: 10px;
    font-weight: normal;
    margin: 0;
    margin-top: 1px;
    line-height: 12px;
    ${props => props.css ?? ""}
`


interface CompletionProps {
    party: string
    completion: ICompletion | null
}

const CompletionSign = (props: CompletionProps): React.ReactElement => {

    const { party } = props;

    // State
    const [completion, setCompletion] = useState<ICompletion | null>(props.completion);
    const [isCompleted, setIsCompleted] = useState<boolean>(completion !== null);

    // Effects
    useEffect(() => {
        setCompletion(props.completion);
        setIsCompleted(props.completion !== null);
    }, [props.completion]);
    
    return (
        <CompletionDiv 
            background={isCompleted ? Color.green : Color.red}
            icon={isCompleted ? SolveIcon : CloseIcon}
            css={"margin-right: 10px;"}
        >  
            <VerticalFlex css={"justify-content: center; align-items: flex-start; padding-left: 15px;"}>
                <Text css={"font-weight: bold; font-size: 12px; line-height: 15px; margin-bottom: 5px;"}>{party}</Text>
                {completion !== null && <Text>{completion.user.name}</Text> }
                {completion !== null && <Text>{moment(completion.time).format(DATE_FORMAT)}</Text> }
            </VerticalFlex>
        </CompletionDiv>
    )
} 

export default CompletionSign;