import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Color from "../../resources/colors";
import { IProject } from "../../api/GeoDocModels";

import { Arrow } from "../../pandora/styled";
import NoDataComponent from "../../components/NoDataComponent";


const DropDownContainer = styled("div")<{css?: string}>` 
    width: 100%;;
    margin: 0 auto;
    text-align: left;

    text-indent: 10px;
    font-size: 8pt;
    font-weight: normal;
    color: white;
    border-width: 0px;
    cursor: pointer;

    user-select: none;
    ${props => props.css ?? ""}
`;

const DropDownHeader = styled.div`
    position: relative;
    background-color: ${Color.lightGrey};
    border-radius: 5px;
    outline: none;
    width: auto;
    height: 40px;

    line-height: 50px;
    font-weight: bold;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;

    z-index: 4;

    transition: 0.3s ease;

    &: hover {
        background-color: ${Color.extraLightGrey};
    }
`;


const DropDownList = styled.ul`
    padding: 0;
    margin: 0;
    background: ${Color.lightGrey};
    border-radius: 0 0 5px 5px;

    max-height: 150px;

    overflow: auto;

    margin-top: -10px;
    padding: 5px;
    padding-top: 15px;

    font-weight: normal;
`

const ListItem = styled.li`
    list-style: none;
    margin-bottom: 5px;
    height: 40px;
    line-height: 40px;
    border-radius: 5px;

    display: flex;
    flex-direction: row;
    align-items: center;

    transition: 0.3s ease;

    &: hover {
        background: ${Color.darkGrey};
    }
`

const ProjectMarker = styled("div")<{background: string}>`
    position: relative;
    width: 20px;
    height: 20px;
    margin-right: 15px;
    margin-left: 10px;
    border-radius: 10px;
    background-color: ${props => props.background};
`





interface ProjectSelectorProps {
    selectedProject: IProject
    projects: IProject[]
    setProject: (project: IProject) => void
    css?: string
}

const ProjectSelector = (props: ProjectSelectorProps): React.ReactElement => {

    // State
    const projects: IProject[] = props.projects;
    const [isOpen, setIsOpen] = useState(false);
    const [selectedProject, setSelectedProject] = useState<IProject>(props.selectedProject);

    // Effects
    useEffect(() => setSelectedProject(props.selectedProject), [props.selectedProject])

    // actions
    const toggling = () => setIsOpen(!isOpen);

    const onOptionClicked = (project: IProject) => () => {
        setSelectedProject(project);
        props.setProject(project)
        setIsOpen(false);
    };

    return (
        <DropDownContainer css={props.css}>
            <DropDownHeader onClick={toggling}>
                <Arrow rotate={isOpen} />
                {selectedProject !== null && <ProjectMarker background={selectedProject.color} /> }
                {selectedProject !== null ? selectedProject.name : "Välj projekt"}
            </DropDownHeader>
            {isOpen && (
                <DropDownList>
                    {projects.length !== 0 ? 
                        projects.map((project: IProject) => (
                            <ListItem 
                                onClick={onOptionClicked(project)} 
                                key={Math.random()}
                            >
                                <ProjectMarker background={project.color} />
                                {project.name}
                            </ListItem>
                        ))
                        :
                        <NoDataComponent 
                            color=  {Color.darkGrey}
                            css=    {"padding: 10px;"}
                        />     
                    }
                </DropDownList>
            )}
        </DropDownContainer>
    );
}


export default ProjectSelector;

