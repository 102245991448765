import { AxiosResponse } from "axios";

import Sleipnir from "./Sleipnir";
import APIRequest from "./APIRequest";
import { IProject, ITimeReport, IComment, IGeoData } from "./GeoDocModels";


/**
 * Data factory for creating and posting 
 * paylods to Sleipnir. Used for creating 
 * model instances asynchronously.
 * 
 * This class functions as a middle layer between the 
 * client and the API request handler, Sleipnir. 
 * It's user in order to simply API calls in the components,
 * utilising DRY code reusability in the components. 
 */
export default class RequestFactory {

    /**
     * Create new project. 
     * @param name Project name
     * @param description Project description.
     * @param organisationID Customer organisation ID for project.
     */
    public static async createProject(name: string, description: string, organisationID: string): Promise<IProject> {
        const project = {
            name: name,
            description: description,
            organisationID: organisationID
        }
        return await Sleipnir.request<IProject>(APIRequest.createProject, project) as IProject;
    }

    /**
     * Create comment. 
     * @param geoDataID ID for geodata comment. 
     * @param content Comment content.
     */
    public static async postComment(geoDataID: number, content: string): Promise<IComment> {
        const comment = {
            id: geoDataID,
            content: content,
        }
        return await Sleipnir.request(APIRequest.postComment, comment) as IComment;
    }

    /**
     * Register time report for GeoData instance.
     * @param geoDataID ID for geoData instance. 
     * @param hours Time report hour deviation. 
     * @param content Associated comment to Time Report. 
     */
    public static async postTimeReport(geoDataID: number, hours: number, content?: string): Promise<ITimeReport> {
       
        const comment = content !== "" ? {content: content} : null
        const timeReport = {
            id: geoDataID,
            hours: hours,
            comment: comment
        }
        return await Sleipnir.request(APIRequest.postTimeReport, timeReport) as ITimeReport;
    }

    /**
     * Update project in DB for passed properties.
     * @param projectID Project to update.
     * @param props Generic keys with values to update.
     */
    public static async updateProject(projectID: number, props: Record<string, any>): Promise<IProject> {
        const payload = {
            id: projectID,
            ...props
        }
        return await Sleipnir.request<IProject>(APIRequest.updateProject, payload) as IProject;
    }

    /**
     * Update GeoData in DB for passed properties.
     * @param geoDataID GeoData to update.
     * @param props Generic keys with values to update.
     */
    public static async updateGeoData(geoDataID: number, props: Record<string, any>): Promise<IGeoData> {
        const payload = {
            id: geoDataID,
            ...props
        }
        return await Sleipnir.request<IGeoData>(APIRequest.updateGeoData, payload) as IGeoData;
    }

    /**
     * Upload raw MapIt GeoPackage (.gpkg) file to server.
     * @param geoPackage GPKG file.
     * @param projectID Project associated to the GeoPackage file. 
     */
    public static async uploadGeoPackage(projectID: number, geoPackage: File): Promise<IGeoData[]> {
        const payload = new FormData();
        payload.append("id", projectID.toString());
        payload.append("geoPackage", geoPackage);
        return await Sleipnir.request<IGeoData>(APIRequest.uploadGeoPackage, payload) as IGeoData[];
    }

    /**
     * Upload image for GeoData instance. 
     * @param geoDataID ID for GeoData instance. 
     * @param image Image file. 
     */
    public static async uploadImage(geoDataID: number, image: File): Promise<AxiosResponse> {
        const payload = new FormData();
        payload.append("id", geoDataID.toString());
        payload.append("uploadImage ", image);
        return await Sleipnir.request(APIRequest.uploadImage, payload);
    }

}