import React from "react";
import styled from "styled-components";

import {Logo} from "./Logo";
import {NavButtons} from "./NavButtons";
import ProjectsList from "./project-list/ProjectsList";
import { IGeoData, IProject } from "../../api/GeoDocModels";

import Color from "../../resources/colors";
import { ProjectAction } from "../../pandora/pandora";



const SideBarContainer = styled("div")<{selected: boolean}>`
    position: fixed;
    height: 100vh;
    box-sizing: border-box;

    z-index: 10;
    margin: 0;
    padding: ${props => props.selected ? "10px" : "20px"};
    padding-top: 20px;
    width: ${props => props.selected ? "100px" : "190px"};
    background-color: ${Color.darkGrey};

    overflow: auto;

    flex-direction: column;
    justify-content: flex-start;

    transition: 0.3s ease;

    ::-webkit-scrollbar {
        width: 0px;
    }
`


interface SideBarProps {
    projects: IProject[]
    selectedProject: IProject | null
    hiddenProjects: IProject[]
    hideProject: (project: IProject) => void
    requestFlyToPoint: (geoData: IGeoData) => void
    selectProject: (project?: IProject) => void
    toggleMapSelection: () => void
    toggleUserView: () => void
    toggleProjectView: (project: IProject) => void
    toggleProjectDataList: () => void
    toggleUploadView: () => void
}

export const SideBar = (props: SideBarProps): React.ReactElement => {

    const {
        projects,
        selectedProject,
        hiddenProjects,
        hideProject,
        requestFlyToPoint,
        selectProject,
        toggleMapSelection,
        toggleUserView,
        toggleProjectView,
        toggleProjectDataList,
        toggleUploadView
    } = props;

    const projectAction = (project: IProject, action: ProjectAction) => {
        if (action === ProjectAction.Hide) hideProject(project);
        if (action === ProjectAction.ListData) toggleProjectDataList();
        if (action === ProjectAction.Settings) toggleProjectView(project);
        if (action === ProjectAction.FlyTo) {
            if (selectedProject.geoData.length !== 0) 
                requestFlyToPoint(selectedProject.geoData[0]);
        }   
    }

    return (
        <SideBarContainer 
            selected={selectedProject !== null}
            onClick={() => selectProject(null)}
        >
            <Logo />
            <ProjectsList 
                projects=                   {projects}
                selectedProject=            {selectedProject}
                hiddenProjects=             {hiddenProjects}
                selectProjectCallback=      {selectProject}
                projectActionCallback=      {projectAction}
            />
            <NavButtons 
                projectWasSelected= {selectedProject != null}
                toggleUserView=     {toggleUserView}
                toggleMapSelection= {toggleMapSelection}
                toggleUploadView=   {toggleUploadView}
            />
        </SideBarContainer>
    );
}