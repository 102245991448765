import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
 
import { DropContainerAnimation } from "../../pandora/animations";
import Color from "../../resources/colors";
import UploadIcon from "../../resources/images/upload-icon.png";

import { GenericPopupProps } from "../../components/GenericPopup";
import { VerticalFlex } from "../../pandora/styled";


const FadeIn = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
`

const Container = styled.div`
    position: relative;
    display: flex;
    min-height: 400px;
    height: 100%;
    align-items: center;
    justify-content: center;    

    background-color: transparent;
    border: solid 7px ${Color.lightGrey};
    border-radius: 5px;
    margin-top: 20px;

    transition: 0.3s ease;
`

const SelectFileButton = styled.div`
    width: 40%;
    height: 50px;

    background-color: ${Color.lightGrey};
    border-radius: 5px;
    color: white;
    font-size: 12px;
    line-height: 50px;
    font-weight: bold;
    cursor: pointer;

    z-index: 10;

    transition: 0.3s ease;

    &: hover {
        background-color: ${Color.extraLightGrey};
    }
`

const DropContainer = styled.div`
    position: absolute;
    background-color: ${Color.green};
    width: 200px;
    height: 200px;
    border-radius: 50%;

    background-image: url(${UploadIcon});
    background-size: auto 25%;
    background-position: 50% 50%;
    background-repeat: no-repeat;

    display: flex;
    align-items: center;
    justify-content: center;

    animation: ${DropContainerAnimation} 1.5s ease-out infinite;
`

const Title = styled("h1")<{size: number, weight?: string, css?: string}>`
    position: relative;
    
    font-size: ${props => props.size}px;
    color: white;
    font-weight: ${props => props.weight ?? "bold"};
    align-text: center;
    
    margin: 0px;
    width: auto;

    animation: ${FadeIn} 0.5s linear 1;

    ${props => props.css ?? ""}
`








interface UploadZoneProps {
    file: File
    passFile: (file) => void;
    setPopup: (value: GenericPopupProps | null) => void;
}

const UploadZone = (props: UploadZoneProps): React.ReactElement => {

    // State
    const dropRef = React.createRef<HTMLDivElement>();
    const fileInput = React.createRef<HTMLInputElement>();
    
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [file, setFile] = useState(props.file);

    // Effects
    useEffect(() => {setFile(props.file)}, [props.file])

    useEffect(() => {
        const div = dropRef.current

        div.addEventListener('dragenter', handleDragIn)
        div.addEventListener('dragleave', handleDragOut)
        div.addEventListener('dragover', handleDrag)
        div.addEventListener('drop', handleDrop)

        // returned function will be called on component unmount 
        return () => {
            div.removeEventListener('dragenter', handleDragIn)
            div.removeEventListener('dragleave', handleDragOut)
            div.removeEventListener('dragover', handleDrag)
            div.removeEventListener('drop', handleDrop)
        }
    }, [])

    // Actions

    /**
     * Perform validation check on 
     * files before upload. 
     */
    const uploadValid = (files): boolean => {

        // Check file count
        if (files.length > 1) {
            props.setPopup(null)
            props.setPopup({
                title: "Varning!",
                message: "Endast en fil tillåten.",
                color: Color.red,
                setPopup: props.setPopup
            })
            return false;
        }

        // Check file type
        const f = files[0];
        if (!f.name.includes(".gpkg")) {
            props.setPopup(null)
            props.setPopup({
                title: "Varning!",
                message: "Endast .gpkg filer tillåtna.",
                color: Color.red,
                setPopup: props.setPopup
            })
            return false;
        }
        return true;
    }

    // Register drag and drop 
    // events and listeners
    const handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }
    const handleDragIn = (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setIsDragging(true);
        }
    }
    const handleDragOut = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setIsDragging(false);
    }

    const handleDrop = (e) => {    
        e.preventDefault()
        e.stopPropagation()
        setIsDragging(false);
        
        // Handle drop by validating files 
        // and passing them along to parent container 
        // handling upload. 
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {   
            if (uploadValid(e.dataTransfer.files)) {
                const res = e.dataTransfer.files[0]
                setFile(res)
                props.passFile(res);
                e.dataTransfer.clearData()
            }
        }
    }


    /**
     * Handle file selection with file 
     * system selection prompt.
     * Perform validation checks.
     */
    const handleFileSelection = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            if (uploadValid(e.target.files)) {
                const res = e.target.files[0] 
                setFile(res);
                props.passFile(res);
            }
        }
    }

    return (
        <Container>
            <div style={{height: "100%", width: "100%", zIndex: 10, position: "absolute"}} ref={dropRef}></div>
            {!isDragging && 
                <VerticalFlex css={"justify-content: center; align-items: center; position: absolute;"}>
                    {file !== null && 
                        <VerticalFlex css={"height: auto; justify-content: center; align-items: center;"}>
                            <Title 
                                size=   {22}
                                css=    {"max-width: 70%;"}
                            >{file.name}</Title>
                            <Title 
                                size={15} 
                                weight={"normal"} 
                                css={"margin-bottom: 30px; margin-top: 10px;"}
                            >{Math.round(file.size / 1000) + " kB"}</Title>
                        </VerticalFlex>
                    }
                    <SelectFileButton 
                        onClick={() => fileInput.current.click()}
                    >Välj fil</SelectFileButton>
                </VerticalFlex>
            }
            {isDragging && 
                <DropContainer /> 
            }
            <input
                ref=        {fileInput}
                style=      {{display:'none'}}
                type=       {"file"}
                onChange=   {handleFileSelection}
            />
        </Container>
    )
}

export default UploadZone;




