import React from "react";
import styled from "styled-components";

import Redirect from "../../datasource/redirect";
import Color from "../../resources/colors";
import icon from "../../resources/images/logo.png";


const LogoContainer = styled.div`
    background-color: ${Color.lightGrey};
    border-radius: 5px;
    width: 55px;
    height: 55px;
    text-align: center;
    position: relative;
    bottom: auto;
    margin: auto;

    transition: 0.2s ease;

    &: hover {
        transform: scale(1.1);
    }
`

const Icon = styled.img`
    width: 60%;
    height: 60%;
    position: absolute;
    top: 0;
    bottom: 2px;
    left: 0;
    right: 0;
    margin: auto;
`

export const Logo = (): React.ReactElement => {
    return (
        <LogoContainer>
            <a href={Redirect.ASuite} target="_blank" rel="noreferrer">
                <Icon src={icon} alt="Logo"/>
            </a>
        </LogoContainer>
    );
}