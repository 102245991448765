import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { OrmState } from "redux-orm";
import { useIdleTimer } from "react-idle-timer";


import './App.css';

import { IGeoData, IProject } from './api/GeoDocModels';
import { CookieHandler, GeoDocCookies } from "./datasource/cookies";
import Selector from "./datasource/selector";
import { Schema } from "./datasource/orm";
import Redirect from "./datasource/redirect";
import ShareHandler from "./datasource/share";
import Auth from "./datasource/auth";
import CompletionFilter from "./datasource/completion";

import { CompletionState } from "./pandora/pandora";
import MapType from "./pandora/MapType";

import LoadingScreen from "./components/loading-screen/LoadingScreen"
import Map from "./components/map/Map";
import MapSelection from "./views/MapSelection";
import UserView from "./views/user/UserView";
import ProjectView from "./views/project/ProjectView";
import GeoDataView from "./views/geoData/GeoDataView";
import UploadView from "./views/upload/UploadView";
import { SideBar } from "./components/sidebar/SideBar";
import ProjectDataList from "./components/sidebar/data-list/ProjectDataList";
import { MapTools } from "./components/map-tools/MapTools";




const App = (): React.ReactElement => {

    // Retrieve data from Redux store 
    // this selector is updated whenever an 
    // action changing the data in the Redux Store
    // is dispatched. Triggering a reselection of the 
    // project data, which triggers a propagation of 
    // the new project throughout the UI. 
    const _projects: IProject[] = useSelector((state: OrmState<Schema>) => Selector.getProjects(state))

    // Retrieve cookies
    const cookieMapType: MapType = MapType.getTypeForName(CookieHandler.get(GeoDocCookies.MapType), MapType.Streets);


    // State
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [projects, setProjects] = useState<IProject[]>(_projects);
    const [selectedProject, setSelectedProject] = useState<IProject | null>(null);
    const [hiddenProjects, setHiddenProjects] = useState<IProject[]>([]);
    const [selectedGeoData, setSelectedGeoData] = useState<IGeoData | null>(null);

    // State setters for view show flags
    const [showProjectView, setShowProjectView] = useState<boolean>(false);
    const [showProjectDataList, setShowProjectDataList] = useState<boolean>(false);
    const [showGeoDataView, setShowGeoDataView] = useState<boolean>(false);
    const [showMapSelection, setShowMapSelection] = useState<boolean>(false);
    const [showUserView, setShowUserView] = useState<boolean>(false);
    const [showUploadView, setShowUploadView] = useState<boolean>(false);

    // Various states 
    const [completionFilter, setCompletionFilter] = useState<CompletionState | null>(null);
    const [selectedMapType, setSelectedMapType] = useState<MapType>(cookieMapType);
    const [flyToPoint, setFlyToPoint] = useState<[number, number] | null>(null);


    // Effects

    /**
     * Initial effect requesting 
     * verification of the user authenitcation 
     * token. If token is not valid, redirect
     * to ASuite (with a redirect request) 
     * for authentication. 
     */
    useEffect(() => {

        // Verify Sleipnir connection by
        // authenticating user token. If Sleipnir not reachable or 
        // token has expired (i.e. response.status !== 200), redirect to 
        // ASuite login route. 
        const verifyAuth = async () => {
            const isVerified = await Auth.verify()
            if (isVerified) {
                setIsLoading(false)
            } else {
                Redirect.asuite(true)
            }
        }
        
        verifyAuth()
     }, []);

    /**
     * Hook project data updates in 
     * the Redux store. Update the selected 
     * project instance. 
     */
    useEffect(() => { 
        // Update projects
        setProjects(_projects)

        // Update selected projects instance        
        if (selectedProject !== null) {
            const _selectedProject = _projects.find(p => p.id === selectedProject.id)
            if (_selectedProject !== undefined) setSelectedProject(_selectedProject);
        }
    }, [_projects])

    /**
     * Check for query string 
     * requesting focus of certain 
     * GeoData or Project instances. 
     */
    useEffect(() => {
        if (ShareHandler.INITIAL_LOAD && projects.length !== 0) {
            
            // Attempt to retrieve the queried data from current projects
            const queriedGeoData: [IGeoData, IProject] | null = ShareHandler.getQueriedGeoData(projects);
            const queriedProject: IProject | null = ShareHandler.getQueriedProject(projects);

            // Open view
            if (queriedGeoData !== null) selectGeoData(queriedGeoData[0], queriedGeoData[1]) 
            if (queriedProject !== null) toggleProjectView(queriedProject)
            
            // Remove ugly ass query strings from URL
            window.history.replaceState({}, document.title, "/");
        }
    }, [projects])

    /**
     * Update selected GeoData instance 
     * with new data. Hook triggers when update
     * happens in Redux Store. 
     */
    useEffect(() => {
        // Update selected GeoData
        if (selectedGeoData !== null && selectedProject !== null) {
            const _selectedGeoData = selectedProject.geoData.find(g => g.id === selectedGeoData.id)
            if (_selectedGeoData !== undefined) setSelectedGeoData(_selectedGeoData);
        }
    }, [selectedProject])
    

    /**
     * Idle timer forces GeoDoc page refresh every 
     * 5 minutes to prevent asynchrous data states. 
     */
    const _= useIdleTimer({
        timeout: 1000 * 60 * 5,
        onIdle: () => window.location.reload(),
      })

      
    // UI Actions

    /**
     * Hide all views by setting the show state
     * to false. Pass which setters should not be 
     * closed by passing exception. 
     */
    const closeAll = (except: ((value: boolean) => void)[]) => {
        const viewsSetters = [
            setShowMapSelection, 
            setShowProjectView, 
            setShowGeoDataView, 
            setShowProjectDataList, 
            setShowUserView,
            setShowUploadView
        ]
        viewsSetters.forEach(v => {
            if (except.includes(v)) return;
            v(false);
        });
    }

    const toggleProjectDataList = () => {
        closeAll([setShowProjectDataList])
        setShowProjectDataList(!showProjectDataList);
    }

    const toggleGeoDataView = () => {
        closeAll([setShowMapSelection, setShowProjectDataList])
        setShowGeoDataView(!showGeoDataView);
    }

    const toggleMapSelection = () => {
        closeAll([setShowMapSelection])
        setShowMapSelection(!showMapSelection);
    }

    const toggleUserView = () => {
        closeAll([setShowUserView])
        setShowUserView(!showUserView);
    }

    const toggleUploadView = () => {
        closeAll([setShowUploadView]);
        setShowUploadView(!showUploadView);
    }

    /**
     * Open project view with supplied project. 
     * Select project if new is passed. 
     */
    const toggleProjectView = (project: IProject) => {
        closeAll([setShowProjectView])
        if (project !== selectedProject) {
            setSelectedProject(project);
            if (!showProjectView) setShowProjectView(true); 
            return 
        }
        setShowProjectView(!showProjectView);
    } 

    /**
     * Add project to hidden projects list. 
     */
    const hideProject = (project: IProject) => {
        const projects: IProject[] = [...hiddenProjects];
        if (projects.includes(project)) {
            projects.splice(projects.indexOf(project), 1);
        } else {
            projects.push(project);
        }
        setHiddenProjects(projects);
    }

    /**
     * Select project. 
     */
    const selectProject = (project?: IProject) => {
        if (project === null) {
            setShowProjectView(false); 
            setShowProjectDataList(false);
        }
        setShowGeoDataView(false);
        setSelectedProject(project);
    }

    /**
     * Select GeoData. 
     */
    const selectGeoData = (geoData?: IGeoData, project?: IProject) => {
        setSelectedGeoData(geoData);
        if (geoData === null) {
            setShowGeoDataView(false); 
            return
        }
        if (project !== undefined) setSelectedProject(project);
        toggleGeoDataView()
    }

    /**
     * Pass GeoData to request fly to point 
     * with GeoData coordinate. 
     */
    const requestFlyToPoint = (geoData: IGeoData) => {
        setFlyToPoint(geoData.geometry[0].point.coordinates);
        if (completionFilter !== null) setCompletionFilter(CompletionFilter.determine(geoData));
    }

    const selectMap = (type: MapType) => setSelectedMapType(type);
    const setDataFilter = (filter: CompletionState) => setCompletionFilter(filter);


    const ui = () => {        
        return (
            isLoading ? 
            <LoadingScreen /> 
            : 
            <div className="App">
                <Map 
                    filter=             {completionFilter}
                    projects=           {projects}
                    flyToPoint=         {flyToPoint}
                    hiddenProjects=     {hiddenProjects}
                    mapBoxKey=          {selectedMapType.mapBoxKey} 
                    openGeoDataView=    {selectGeoData}
                    setFlyToPoint=      {setFlyToPoint}
                />
                <SideBar 
                    projects=               {projects}
                    selectedProject=        {selectedProject}
                    hiddenProjects=         {hiddenProjects}
                    hideProject=            {hideProject}
                    requestFlyToPoint=      {requestFlyToPoint}
                    selectProject=          {selectProject}
                    toggleProjectView=      {toggleProjectView}
                    toggleUserView=         {toggleUserView}
                    toggleMapSelection=     {toggleMapSelection}
                    toggleProjectDataList=  {toggleProjectDataList}
                    toggleUploadView=       {toggleUploadView}
                />
                <MapTools 
                    selectedFilter= {completionFilter}
                    setFilter=      {setDataFilter}
                />
                {showMapSelection && 
                    <MapSelection 
                        selected=               {selectedMapType}
                        selectMap=              {selectMap}
                        toggleMapSelection=     {toggleMapSelection}
                    />
                }
                {showUserView && 
                    <UserView 
                        projects=               {projects}
                        toggleUserView=         {toggleUserView}
                        openProject=            {toggleProjectView}
                    />
                }
                {showUploadView && 
                    <UploadView 
                        projects=               {projects}
                        toggleUploadView=       {toggleUploadView}
                    />
                }
                {showProjectDataList && 
                    <ProjectDataList
                        project=                {selectedProject}
                        openGeoData=            {selectGeoData}
                    />
                }
                {showProjectView && 
                    <ProjectView 
                        project=                {selectedProject}
                        openGeoData=            {selectGeoData}
                        requestFlyToPoint=      {requestFlyToPoint}
                        toggleViewVisibility=   {toggleProjectView}
                    />
                }
                {showGeoDataView && selectedGeoData !== null && 
                    <GeoDataView 
                        geoData=                {selectedGeoData}
                        project=                {selectedProject}
                        openProjectView=        {toggleProjectView}
                        requestFlyToPoint=      {requestFlyToPoint}
                        toggleViewVisibility=   {toggleGeoDataView}
                    />
                }
            </div>
        )
    }

    return ui();
}


export default App;
