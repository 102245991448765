import Color from "../resources/colors";


/**
 * Project actions types used in SideBar
 */
export enum ProjectAction {
    ListData,
    Hide,
    FlyTo,
    Settings
}

/**
 * Common date format for component
 * rendering and parsing of Dates. 
 */
export const DATE_FORMAT: string = "YYYY-MM-DD HH:mm";


/**
 * GeoData filtering options
 */
export enum CompletionState {
    Uncompleted = "Uncompleted",
    Pending = "Pending",
    Completed = "Completed"
}

export const CompletionStateColorMap = { 
    [CompletionState.Uncompleted] : [Color.red, Color.lightRed],
    [CompletionState.Pending] : [Color.blue, Color.lightBlue],
    [CompletionState.Completed] : [Color.green, Color.lightGreen],
    "all": [Color.purple, Color.lightPurple]
}

export const getCompletionNameState = (state: CompletionState | null, isInternal: boolean): string => {
    switch (state) {
        case CompletionState.Uncompleted: 
            return  isInternal ? "Ej inmätta" : "Ej åtgärdade";
        case CompletionState.Pending: 
            return  isInternal ? "Inväntar åtgärd" : "Inväntar inmätning";
        case CompletionState.Completed: 
            return  "Klara"
        case null:
            return "Alla"
    }
}

export const getCompletionInfo = (state: CompletionState | null, isInternal: boolean): string => {
    switch (state) {
        case CompletionState.Uncompleted: 
            return  isInternal ? "Avvikelse åtgärdad av kund. Redo för inmätning." : "Avvikelse registrerad av Atritec. Kräver åtgärd.";
        case CompletionState.Pending: 
            return  isInternal ? "Inväntar åtgärd från kund." : "Inväntar inmätning av Atritec.";
        case CompletionState.Completed: 
            return  "Avvikelsen är åtgärdad och inmätt."
    }
}



/**
 * Convert numeric coordinates to
 * degree, minute seconds format.
 * @param coordinate Coordinate number to convert.
 */
function toDegreesMinutesAndSeconds(coordinate) {
    const absolute = Math.abs(coordinate);
    const degrees = Math.floor(absolute);
    const minutesNotTruncated = (absolute - degrees) * 60;
    const minutes = Math.floor(minutesNotTruncated);
    const seconds = Math.floor((minutesNotTruncated - minutes) * 60);

    return degrees + "° " + minutes + "' " + seconds + "\" ";
}

/**
 * Format LONG LAT coordinate format. 
 * @param lat 
 * @param lng 
 */
export function convertDMS(lat, lng): [string, string] {
    const latitude = toDegreesMinutesAndSeconds(lat);
    const latitudeCardinal = lat >= 0 ? "N" : "S";

    const longitude = toDegreesMinutesAndSeconds(lng);
    const longitudeCardinal = lng >= 0 ? "E" : "W";

    return [latitude + " " + latitudeCardinal, longitude + " " + longitudeCardinal];
}

/**
 * Project color presets.
 */
export enum ColorPreset {
    Red = "rgb(255, 77, 77)",
    Blue = "rgb(77, 121, 255)",
    Green = "rgb(0, 204, 102)",
    Pink = "rgb(204, 0, 153)",
    Yellow = "rgb(255, 204, 0)",
    DarkPurple = "rgb(153, 51, 102)",
    DeepRed = "rgb(204, 0, 0)",
    LightGreen = "rgb(102, 255, 153)",
    Orange = "rgb(255, 153, 102)",
    Cyan = "rgb(0, 153, 153)"
}
