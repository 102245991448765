import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import moment from "moment";

import { IGeoData, IProject, IComment, ITimeReport, ICompletion, IImage } from "../../api/GeoDocModels";
import RequestFactory from "../../api/RequestFactory";
import ShareHandler from "../../datasource/share";
import Sleipnir from "../../api/Sleipnir";
import APIRequest from "../../api/APIRequest";
import { ClientAction } from "../../datasource/actions";
import Auth from "../../datasource/auth";
import CompletionFilter from "../../datasource/completion";

import { convertDMS, DATE_FORMAT, CompletionState, CompletionStateColorMap } from "../../pandora/pandora";
import { ViewEntranceAnimation } from "../../pandora/animations";
import { HorizontalFlex, VerticalFlex, CloseButton, ViewContainer, SaveButton, NameInput, DescriptionInput, ContentStateButton, ActionButton, LinkField, LinkButton, ProjectMarker } from "../../pandora/styled";
import Color from "../../resources/colors";

import CloseIcon from "../../resources/images/close-icon.png";
import SolveIcon from "../../resources/images/check-mark-icon.png";
import FlyToIcon from "../../resources/images/go-to-icon.svg";
import UncompletedIcon from "../../resources/images/close-icon.png";
import PendingIcon from "../../resources/images/hourglass.png";
import CompletedIcon from "../../resources/images/check-mark-icon.png";

import ImageList from "./ImageList";
import Activity from "../../components/activity/Activity";
import TimeReport from "../../components/time-report/TimeReport";
import Overlay from "../../components/Overlay";
import ConfirmationPopup, { Confirmation } from "../../components/ConfirmationPopup";
import CommentField from "../../components/CommentField";
import TimeReportField from "../../components/TimeReportField";
import CompletionSign from "./CompletionSign";
import GenericPopup, { GenericPopupProps } from "../../components/GenericPopup";



const GeoDataContainer = styled(VerticalFlex)`
    position: relative;
    background: ${Color.darkGrey};
    border-radius: 10px;
    height: 750px;
    min-height: 70%;
    max-height: 90%;
    width: 70%;
    padding: 40px;
    overflow: hidden;

    animation: ${ViewEntranceAnimation} 0.2s linear 1;
`

const Header = styled(HorizontalFlex)`
    align-items: left;
    height: 70px;
    justify-content: flex-start;
    position: relative;
    width: 100%;
`

const Marker = styled("div")<{color: string, icon: string}>`
    width: 70px;
    height: 70px;
    border-radius: 5px;
    margin-right: 20px;

    background-color: ${props => props.color};
    background-image: url(${props => props.icon});
    background-size: 30% 30%;
    background-position: center;
    background-repeat: no-repeat;
    
    transition: 0.3s ease;
`

const TitleDiv = styled(VerticalFlex)`
    align-items: left;
    height: 100%;
    justify-content: center;
    width: fit-content;
    margin-right: 20px;
`

const TopTitle = styled.h3`
    color: white;
    font-size: 12pt;
    font-weight: normal;
    text-align: left;
    line-height: 12pt;

    margin: 0;
    width: fit-content;
`

const Title = styled("h1")<{size: number, css?: string}>`
    text-align: left;
    color: white;
    font-size: ${props => props.size}px;
    margin: 0;
    width: fit-content;

    ${props => props.css ?? ""}
`

const ContentDiv = styled(VerticalFlex)<{css?: string}>`
    width: auto;
    height: auto;
    background: ${Color.lightGrey};
    border-radius: 5px;
    padding: 20px;

    ${props => props.css ?? ""}
`

const ContentGrid = styled(ContentDiv)`
    display:grid;
    grid-template-columns: 50% 50%;
    grid-row: auto auto;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
`

const SideBarFlex = styled(VerticalFlex)`
    padding-right: 20px; 
    width: 30%; 
    
    overflow-x: hidden; 
    overflow-y: auto;

    ::-webkit-scrollbar-thumb {
        background-color: ${Color.lightGrey}; 
    }
`

const TextLabel = styled("h3")<{css?: string}>`
    margin-top: 20px;
    color: white;
    font-size: 8pt;
    text-align: left;
    font-weight: normal;

    ${props => props.css ?? ""}
`

const GeoDataTypeLabel = styled(TextLabel)<{css?: string}>`
    width: fit-content;
    padding: 5px;
    margin: 0;
    margin-top: 10px;

    border-radius: 5px;
    background-color: ${Color.darkGrey};

    font-size: 11px;
    font-weight: bold;
`

const TopActionsDiv = styled(HorizontalFlex)`
    position: absolute;
    top: 30px;
    right: 30px;
    height: 50px;
`





enum ContentState {
    Activity = "Activity",
    Images = "Images",
    Time = "Time"
}

const ContentStateTitleMap = {
    [ContentState.Activity]: "Aktivitet",
    [ContentState.Images]: "Bilder",
    [ContentState.Time]: "ÄTA"
}

const FilterIconMap = { 
    [CompletionState.Uncompleted] : UncompletedIcon,
    [CompletionState.Pending] : PendingIcon,
    [CompletionState.Completed] : CompletedIcon,
}



interface GeoDataViewProps {
    project: IProject
    geoData: IGeoData
    openProjectView: (project: IProject) => void
    requestFlyToPoint: (geoData: IGeoData) => void
    toggleViewVisibility: (geoData: IGeoData) => void
}

const GeoDataView = (props: GeoDataViewProps): React.ReactElement => {
    
    const dispatch = useDispatch()

    const { 
        project,
        geoData: argGeoData,
        openProjectView,
        requestFlyToPoint,
        toggleViewVisibility
    } = props;

    // State
    const [contentState, setContentState] = useState<ContentState>(ContentState.Activity);

    const [geoData, setGeoData] = useState<IGeoData>(argGeoData);
    const [name, setName] = useState<string>(argGeoData.name);
    const [description, setDescription] = useState<string>(argGeoData.description);

    const [cachedImages, setCachedImages] = useState<IImage[] | null>(null);
    const [hasImages, setHasImages] = useState<boolean>(false);

    const [coordinateDMS, setCoordinateDMS] = useState<[string, string]>(convertDMS(geoData.geometry[0].point.coordinates[1], geoData.geometry[0].point.coordinates[0]));
    const [completionState, setCompletionState] = useState<CompletionState>(CompletionFilter.determine(geoData))

    const [confirmation, setConfirmation] = useState<Confirmation | null>(null);
    const [popup, setPopup] = useState<GenericPopupProps | null>(null);
    
    const [hasChanges, setHasChanges] = useState<boolean>(false);

    // Effects
    
    useEffect(() => {

        // Request flag indicating if the current 
        // GeoData has images. 
        const checkHasImages = async () => {
            try {
                const res = await Sleipnir.request(APIRequest.geoDataHasImages, {id: geoData.id})
                setHasImages(res["hasImages"])
            } catch (e) {
                console.log(e)
            }
        }  
        checkHasImages()
    }, []) 
        
    // Update the GeoData state from GeoData 
    // passed as argument. 
    useEffect(() => {
        setGeoData(argGeoData);
        setCompletionState(CompletionFilter.determine(argGeoData));
        setName(argGeoData.name);
        setDescription(argGeoData.description);
    }, [argGeoData]);

    // Update the Coordinate DMS
    // when the GeoDataView is loaded, 
    // i.e. the effect trigger for the GeoData state 
    // reference changes. 
    useEffect(() => {
        setCoordinateDMS(
            convertDMS(
                geoData.geometry[0].point.coordinates[1],
                geoData.geometry[0].point.coordinates[0]
            )
        )
    }, [geoData])


    // Actions

    /**
     * Save the changes to the database. 
     * Update the Redux store if request
     * doesn't fail. 
     */
    const saveChanges = async (showPopup: boolean = true) => { 
        try {
            if (name === "" || description === "") throw new Error("Tomma värden ej tillåtna.")
            
            // Request GeoData update
            const payload = {name: name, description: description}
            const _geoData: IGeoData = await RequestFactory.updateGeoData(geoData.id, payload)

            // Dispatch update to Redux store
            dispatch(ClientAction.updateGeoData(_geoData.id, payload))
            setHasChanges(false);

            if (showPopup) {
                setPopup({
                    title: "Sparat!",
                    message: "Ändringarna har sparats.",
                    color: Color.green,
                    setPopup: setPopup
                }) 
            }
        } catch (e) {
            setPopup({
                title: "Ett fel uppstod.",
                message: `${e.message}`,
                color: Color.red,
                setPopup: setPopup
            }) 
        }
    }


    /**
     * Handle name input editing. 
     */
    const editName = (e) => { 
        const { value } = e.target;
        if (value.length <= 50) {
            setName(value);
            if (!hasChanges) setHasChanges(true);
        }
    }

    /**
     * Handle description input editing. 
     */
    const editDescription = (e) => { 
        const { value } = e.target;
        if (value.length <= 500) {
            setDescription(value);
            if (!hasChanges) setHasChanges(true);
        }
    }

    /**
     * Open the GeoData's project's view. 
     */
    const openProject = () => { openProjectView(project) }

    /**
     * Request comment posting. 
     * Update the Redux store if request
     * doesn't fail. 
     */
    const postComment = async (content: string) => { 
        try {
            const comment: IComment = await RequestFactory.postComment(geoData.id, content)
            dispatch(ClientAction.postComment(geoData.id, comment)) 
        } catch (e) {
            setPopup({
                title: "Ett fel uppstod.",
                message: `${e.message}`,
                color: Color.red,
                setPopup: setPopup
            }) 
        }
    }

    /**
     * Request comment deletion. 
     * Update the Redux store if request
     * doesn't fail. 
     */
    const deleteComment = (comment: IComment) => {
        const callback = async () => {
            try { 
                const _ = await Sleipnir.request(APIRequest.deleteComment, {id: comment.id})
                dispatch(ClientAction.deleteComment(geoData.id, comment.id)) 
            } catch (e) {
                setPopup({
                    title: "Ett fel uppstod.",
                    message: `${e.message}`,
                    color: Color.red,
                    setPopup: setPopup
                }) 
            }
        }

        setConfirmation({
            title: "Varning!",
            message: "Är du säker på att du vill radera denna kommentar?",
            callback: callback,
            reset: setConfirmation
        })
    }

    /**
     * Request TimeReport posting. 
     * Update the Redux store if request
     * doesn't fail. 
     */
    const postTimeReport = async (hours: number, content: string) => {  
        try {
            // Request TimeReport creation. 
            const timeReport: ITimeReport = await RequestFactory.postTimeReport(geoData.id, hours, content);
            console.log(timeReport)
            // Dispatch creation to the Redux store. 
            dispatch(ClientAction.postTimeReport(geoData.id, timeReport)) 
        } catch (e) {
            setPopup({
                title: "Ett fel uppstod.",
                message: `${e.message}`,
                color: Color.red,
                setPopup: setPopup
            }) 
        }
    }

    /**
     * Request TimeReport deletion. 
     * Update the Redux store if request
     * doesn't fail. 
     */
    const deleteTimeReport = (timeReport: ITimeReport) => {
        const callback = async () => {
            try { 
                // Request TimeReport deletion 
                const _ = await Sleipnir.request(APIRequest.deleteTimeReport, {id: timeReport.id})
                // Dispatch deletion to the Redux store. 
                dispatch(ClientAction.deleteTimeReport(geoData.id, timeReport.id)) 
            } catch (e) {
                setPopup({
                    title: "Ett fel uppstod.",
                    message: `${e.message}`,
                    color: Color.red,
                    setPopup: setPopup
                }) 
            }
        }

        setConfirmation({
            title: "Varning!",
            message: "Är du säker på att du vill radera denna ÄTA?",
            callback: callback,
            reset: setConfirmation
        })
    }

    /**
     * Request Completion posting. 
     * Update the Redux store if request
     * doesn't fail. 
     */
    const postCompletion = () => {  
        const complete = async () => {
            try {
                const completion: ICompletion = await Sleipnir.request<ICompletion>(APIRequest.postCompletion, {id: geoData.id}) as ICompletion   
                dispatch(ClientAction.postCompletion(geoData.id, Auth.isEmployee(), completion))
                                
                setPopup({
                    title: "Avvikelse åtgärdad",
                    message: `Avvikelsen ${geoData.name} åtärdad.`,
                    color: Color.green,
                    setPopup: setPopup
                }) 
            } catch (e) {
                setPopup({
                    title: "Ett fel uppstod.",
                    message: `Avvikelsen ${geoData.name} kunde inte markeras som åtgärdad.`,
                    color: Color.red,
                    setPopup: setPopup
                }) 
            }
        }
        
        setConfirmation({
            title: "Bekräfta åtgärd",
            message: "Vill du markera denna avvikelse som åtgärdad?",
            callback: complete,
            reset: setConfirmation
        })
    }

    /**
     * Request Completion deletion. 
     * Update the Redux store if request
     * doesn't fail. 
     */
    const deleteCompletion = () => { 
        const callback = async () => {
            try { 
                const _ = await Sleipnir.request(APIRequest.deleteCompletion, {id: geoData.externalCompletion.id})
                dispatch(ClientAction.deleteCompletion(geoData.id, Auth.isEmployee(), geoData.externalCompletion.id)) 
            } catch (e) {
                setPopup({
                    title: "Ett fel uppstod.",
                    message: `${e.message}`,
                    color: Color.red,
                    setPopup: setPopup
                }) 
            }
        }

        setConfirmation({
            title: "Varning!",
            message: "Är du säker på att du vill markera avvikelsen som ej åtgärdad?",
            callback: callback,
            reset: setConfirmation
        })
     }

     
    /**
     * Request FlyToPoint action in the Map. 
     */
    const flyTo = () => { 
        toggleViewVisibility(geoData);
        requestFlyToPoint(geoData) 
    }

    /**
     * Copy the share link for the GeoData to the clipboard. 
     */
    const share = () => {
        const el = document.getElementById("link-ref") as HTMLInputElement;
        el.select()
        document.execCommand("copy")
        setPopup({
            title: "Länk kopierad!",
            message: "Länken har kopierats.",
            color: Color.green,
            setPopup: setPopup
        }) 
    }

    /**
     * Close view. 
     * Confirm change saving before exit. 
     */
    const close = (e: React.MouseEvent) => {
        e.stopPropagation();

        if (hasChanges) {
            const callback = (save: boolean) => { 
                if (save) saveChanges(false)
                toggleViewVisibility(geoData)
            }

            setConfirmation({
                title: "Osparade ändringar!",
                message: "Vill du spara ändringarna?",
                callback: () => callback(true),
                reset: () => callback(false)
            })
        } else {
            toggleViewVisibility(geoData)
        }
    }


    return (
        <Overlay onClick={close}>
            <ViewContainer>
                {confirmation !== null && <ConfirmationPopup confirmation={confirmation}/> }
                {popup && <GenericPopup 
                                title=          {popup.title}
                                message=        {popup.message}
                                color=          {popup.color}
                                setPopup=       {setPopup}
                            />
                }
                <GeoDataContainer onClick={(e) => e.stopPropagation()}>
                    <TopActionsDiv>
                        <ProjectMarker 
                            color=      {project.color}
                            onClick=    {openProject}
                        ><b>{project.organisation.name}</b>{" - " + project.name}</ProjectMarker>
                        {hasChanges && 
                            <SaveButton 
                                onClick={() => saveChanges(true)}
                            >Spara</SaveButton>
                        } 
                        {!hasChanges && 
                            <CloseButton 
                                onClick={close} 
                                css={"position: relative; right: 0; top: 0;"}
                            />
                        }     
                    </TopActionsDiv>
                    <Header>
                        <Marker 
                            color=      {CompletionStateColorMap[completionState][0]}
                            icon=       {FilterIconMap[completionState]}
                        />
                        <TitleDiv>
                            <TopTitle>{"# " + geoData.id}</TopTitle>
                            <NameInput 
                                enabled=        {Auth.isEmployee()}
                                placeholder=    {"Namn"}
                                value=          {name}
                                onChange=       {editName}
                                readOnly=       {!Auth.isEmployee()}
                            />
                        </TitleDiv>
                    </Header>
                    <HorizontalFlex css={"margin-top: 40px; justify-content: space-between; height: 100%; overflow: hidden;"}>
                        <SideBarFlex>
                            <HorizontalFlex>
                                <CompletionSign 
                                    completion={geoData.internalCompletion} 
                                    party={"Atritec"}
                                />
                                <CompletionSign 
                                    completion={geoData.externalCompletion} 
                                    party={project.organisation.name}
                                />
                            </HorizontalFlex>
                            <ContentGrid css={"margin-bottom: 15px; margin-top: 15px;"}>
                                <div>
                                    <Title size={10}>Tid</Title>
                                    <TextLabel css={"margin-top: 5px; margin-bottom: 10px;"}>{moment(geoData.time).format(DATE_FORMAT)}</TextLabel>
                                </div>
                                <div>
                                    <Title size={10}>Operatör</Title>
                                    <TextLabel css={"margin-top: 5px; margin-bottom: 5px;"}>{geoData.addedBy.name}</TextLabel>
                                </div>
                                <div>
                                    <Title size={10}>Koordinat</Title>
                                    <TextLabel css={"margin-top: 5px; margin-bottom: 2px;"}>{coordinateDMS[0]}</TextLabel>
                                    <TextLabel css={"margin-top: 0px; margin-bottom: 5px;"}>{coordinateDMS[1]}</TextLabel>
                                </div>
                                <div>
                                    <Title size={10}>Telefon</Title>
                                    <TextLabel css={"margin-top: 5px; margin-bottom: 5px;"}>{geoData.addedBy.phone}</TextLabel>
                                </div>
                            </ContentGrid>
                            <ContentDiv css={"margin-bottom: 15px;"}>
                                <Title size={15}>Beskrivning</Title>
                                <GeoDataTypeLabel>{geoData.type}</GeoDataTypeLabel>
                                <DescriptionInput
                                    enabled=        {Auth.isEmployee()}
                                    placeholder=    {"Beskrivning"}
                                    value=          {description}
                                    onChange=       {editDescription}
                                    readOnly=       {!Auth.isEmployee()}
                                />
                            </ContentDiv>
                            <ContentDiv css={"margin-bottom: 10px; padding: 10px; flex-direction: row;"}>
                                <LinkButton onClick={share}/>
                                <LinkField
                                    id=         {"link-ref"} 
                                    readOnly=   {true}
                                    value=      {ShareHandler.shareGeoData(geoData)}
                                />
                            </ContentDiv>
                            {CompletionFilter.completionEnabled(geoData) && 
                                <ActionButton 
                                    background= {[Color.green, Color.lightGreen]}
                                    icon=       {SolveIcon}
                                    onClick=    {postCompletion}
                                >Markera åtgärdad</ActionButton>
                            }
                            {CompletionFilter.uncompletionEnabled(geoData) && 
                                <ActionButton 
                                    background= {[Color.red, Color.lightRed]}
                                    icon=       {CloseIcon}
                                    onClick=    {deleteCompletion}
                                >Markera ej åtgärdad</ActionButton>
                            }
                            <ActionButton 
                                background= {[Color.purple, Color.lightPurple]}
                                icon=       {FlyToIcon}
                                onClick=    {flyTo}
                            >Zooma data</ActionButton>
                        </SideBarFlex>
                        <VerticalFlex css={"padding-left: 20px; width: 70%;"}>
                            <HorizontalFlex css={"margin-bottom: 20px; justify-content: flex-start;"}>
                                {Object.keys(ContentState).filter(c => ContentState[c] === ContentState.Images ? hasImages : true).map((c: string) => (
                                        <ContentStateButton
                                            key=        {c}
                                            clicked=    {contentState === ContentState[c]}
                                            onClick=    {() => setContentState(ContentState[c])}
                                        >{ContentStateTitleMap[ContentState[c]]}</ContentStateButton>
                                    ))
                                }
                            </HorizontalFlex>
                            <ContentDiv css={"height: 100%; position: relative; overflow: auto;"}>
                                {contentState === ContentState.Activity && 
                                    <div style={{height: "100%"}}>
                                        <Activity 
                                            geoData=        {geoData} 
                                            deleteComment=  {deleteComment}
                                        />
                                        {completionState !== CompletionState.Completed && 
                                            <CommentField postComment= {postComment}/>
                                        }
                                    </div>
                                } 
                                {contentState == ContentState.Images && 
                                    <ImageList 
                                        geoDataID=       {geoData.id}
                                        cachedImages=    {cachedImages}
                                        setCachedImages= {setCachedImages}
                                        setPopup=        {setPopup}
                                    />
                                }
                                {contentState == ContentState.Time && 
                                    <div style={{height: "100%"}}>
                                        <TimeReport 
                                            geoData=            {geoData} 
                                            deleteTimeReport=   {deleteTimeReport}
                                        />
                                        {Auth.isEmployee() && completionState !== CompletionState.Completed && 
                                            <TimeReportField postTimeReport= {postTimeReport}/>
                                        }
                                    </div>
                                }
                            </ContentDiv>
                        </VerticalFlex>
                    </HorizontalFlex>
                </GeoDataContainer>
            </ViewContainer>
        </Overlay>
    )
}


export default GeoDataView;




