import qs from "query-string";

import { IGeoData, IProject } from "../api/GeoDocModels";


export default class ShareHandler {

    public static INITIAL_LOAD: boolean = true;
    private static PROJECT_KEY: string = "project";
    private static GEODATA_KEY: string = "geodata";

    /**
     * Get the current GeoDoc URL from 
     * environment variables. 
     */
    private static getURL = (): string => {
        return process.env.REACT_APP_GEODOC_URL;
    }

    /**
     * Construct URL link to GeoDoc with 
     * query params for project reference. 
     * @param project 
     */
    public static shareProject = (project: IProject): string => {
        return `${ShareHandler.getURL()}/?project=${project.id}`;
    }

    /**
     * Construct URL link to GeoDoc with 
     * query params for GeoData reference. 
     * @param geoData 
     */
    public static shareGeoData = (geoData: IGeoData): string => {
        return `${ShareHandler.getURL()}/?geodata=${geoData.id}`;
    }

    /**
     * Get the current query params from the
     * current URL.
     */
    public static getQuery = (): Record<string, unknown> => {
        return qs.parse(window.location.search)
    }

    /**
     * Parse the query string arguments. 
     * @param q 
     */
    public static getQueryArg = (q: Record<string, unknown>, key: string): number| null => {
        if (!Object.keys(q).includes(key)) return null;
        try {
            return parseInt(q[key] as string)
        } catch {
            return null
        }
    }

    /**
     * Attempt to retrieve the queried project from 
     * passed list of projects. Project IDs are matched with query 
     * string IDs. 
     */
    public static getQueriedProject = (projects: IProject[]): IProject | null => {
        const query: Record<string, unknown> = ShareHandler.getQuery()
        const argID: number | null = ShareHandler.getQueryArg(query, ShareHandler.PROJECT_KEY)

        if (argID === null) return null;
        const queriedProject = projects.find(p => p.id === argID)
        if (queriedProject !== undefined) {
            ShareHandler.INITIAL_LOAD = false;
            return queriedProject   
        }
        return null;
    }

    /**
     * Attempt to retrieve the queried GeoData from 
     * passed list of projects. GeoData IDs are matched
     * with the corresponding query string IDs. 
     */
    public static getQueriedGeoData = (projects: IProject[]): [IGeoData, IProject] | null => {
        const query: Record<string, unknown> = ShareHandler.getQuery()
        const argID: number | null = ShareHandler.getQueryArg(query, ShareHandler.GEODATA_KEY)

        if (argID === null) return null;
        for (let i = 0; i < projects.length; i++) {
            const p = projects[i]
            const queriedGeoData = p.geoData.find(g => g.id === argID)
            if (queriedGeoData !== undefined) {
                ShareHandler.INITIAL_LOAD = false;
                return [queriedGeoData, p]
            }
        }           
        return null
    }
}


