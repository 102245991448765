import React from "react";
import styled from "styled-components";
 
import { IAuthenticatedUser } from "../../api/GeoDocModels";

import Color from "../../resources/colors";
import { Flex } from "../../pandora/styled";


const Title = styled("h1")<{size: number, weight?: string}>`
    font-size: ${props => props.size}px;
    color: white;
    font-weight: ${props => props.weight ?? "bold"};
    text-align: left;
    
    margin: 0px;
    margin-top: 5px;

    white-space: nowrap;
    width: auto;

`

const IDTitle = styled("h2")<{size: number, weight?: string}>`
    font-size: ${props => props.size}px;
    color: ${Color.extraLightGrey};
    font-weight: ${props => props.weight ?? "bold"};
    text-align: left;
    margin: 0px;
`

const ActionButton = styled("div")<{background: [string, string], isAdmin: boolean, css?: string}>`

    margin-top: 10px;

    background-color: ${props => props.background[0]};
    border-radius: 5px;

    color: white;
    font-size: 12px;
    text-align: center;
    font-weight: bold;
    line-height: 40px;
    
    height: 40px;
    width: ${props => props.isAdmin ? "48%" : "auto"};

    user-select: none;
    cursor: pointer;

    ${props => props.css ?? ""}

    transition: 0.1s linear;

    &:hover {
        background-color: ${props => props.background[1]};
    }
`

const ActionButtonContainer = styled(Flex)<{isAdmin: boolean}>`
    position: relative;
    justify-content: space-between;  
    height: fit-content;

    margin-top: ${props => props.isAdmin ? 0 : 40}px;
`




interface ProfileSectionProps {
    user: IAuthenticatedUser
    isAdmin: boolean
    logout: () => void
    redirect: () => void
}

const ProfileSection = (props: ProfileSectionProps): React.ReactElement => {

    const { 
        user, 
        isAdmin,
        logout,
        redirect
    } = props;

    const name: string = user.name
    const userID: string = user.id
    const orgName: string = user.organisation !== null ? user.organisation.name : "Atritec"

    return (
        <Flex direction={"column"} css={"justify-content: space-between; position: relative;"}>
            <div>
                <IDTitle size={14}>{"# " + userID}</IDTitle>
                <Title size={30}>{name}</Title>
                <Title size={18} weight={"normal"}>{orgName}</Title>
            </div>
            <ActionButtonContainer 
                direction=  {isAdmin ? "row": "column"}
                isAdmin=    {isAdmin}
            >
                <ActionButton 
                    background= {[Color.green, Color.lightGreen]} 
                    isAdmin=    {isAdmin}
                    onClick=    {() => redirect()}
                >Till ASuite</ActionButton>
                <ActionButton 
                    background= {[Color.red, Color.lightRed]} 
                    isAdmin=    {isAdmin}
                    onClick=    {() => logout()}
                >Logga ut</ActionButton>
            </ActionButtonContainer>
        </Flex>
    )
}

export default ProfileSection;




