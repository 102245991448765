import React from "react";
import styled from "styled-components";

import Color from "../resources/colors";

import AcceptIcon from "../resources/images/close-icon.png";
import RejectIcon from "../resources/images/check-mark-icon.png";
import PopupIcon from "../resources/images/warning-icon.svg";

import { ConfirmationPopupAnimation } from "../pandora/animations";
import { Flex } from "../pandora/styled";
import Overlay from "./Overlay";


const Container = styled(Flex)`
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;    
    position: relative;
`

const PopupContainer = styled(Flex)`
    position: relative;
    
    width: 20%;
    height: fit-content;
    padding: 25px;

    border-radius: 5px;
    background-color: black;
    color: white;
    
    overflow: hidden; 

    animation: ${ConfirmationPopupAnimation} 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 1;
    transition: 0.3s ease;
`

const Icon = styled("div")<{color?: string}>`
    height: 40px;
    width: 40px;
    margin: 10px;

    mask: url(${PopupIcon}) no-repeat 50% 50%;
    mask-size: cover;
    background-color: ${props => props.color ?? Color.red};
`

const Title = styled("h1")<{size: number, weight?: string, css?: string}>`
    position: relative;
    font-size: ${props => props.size}px;
    color: white;
    font-weight: ${props => props.weight ?? "bold"};
    text-align: center;
    margin: 0px;
    width: auto;
    white-space: normal;
    word-break: break-word;
    height: fit-content;

    ${props => props.css ?? ""}
`


const ActionButton = styled("div")<{background: [string, string], icon: string, css?: string}>`

    margin-top: 10px;

    background-color: ${props => props.background[0]};
    border-radius: 5px;

    color: white;
    font-size: 9pt;
    text-align: center;
    font-weight: bold;
    line-height: 40px;
    
    height: 40px;
    width: auto;
    flex: 1;

    background-image: url(${props => props.icon});
    background-repeat: no-repeat;
    background-size: 15px 15px;
    background-position: 20px 50%;
    user-select: none;

    cursor: pointer;

    ${props => props.css ?? ""}

    transition: 0.1s linear;

    &:hover {
        background-color: ${props => props.background[1]};
    }
`






export interface Confirmation {
    title: string;
    message: string;
    color?: string;
    callback: (...args: any[]) => any;
    reset: (value: Confirmation | null) => void;
}

interface ConfirmPopupProps {
    confirmation: Confirmation
}


/**
 * Generic confirmation popup Component. 
 * Pass confirmation props and set accept / reject 
 * callback functions. 
 * @param props 
 */
const ConfirmationPopup = (props: ConfirmPopupProps): React.ReactElement => {

    const { title, message, callback, reset } = props.confirmation;

    // Confirmation was accepted, call callback function. 
    const accept = () => {
        reset(null);
        callback();
    }

    // Confirmation was rejected, call callback function. 
    const reject = () => {
        reset(null);
    }

    return (
        <Overlay 
            css=        {"z-index: 15;"} 
            onClick=    {e => e.stopPropagation()}
        >
            <Container direction="row">
                <PopupContainer direction="column" css={"align-items: center;"}>
                    <Icon color={props.confirmation.color}/>
                    <Title size={17} css={"margin-top: 10px; margin-bottom: 10px;"}>{title}</Title>
                    <Title size={12} weight={"normal"} css={"margin-top: 5px; margin-bottom: 30px;"}>{message}</Title>
                    <Flex direction="row">
                        <ActionButton
                            background= {[Color.green, Color.lightGreen]} 
                            icon=       {RejectIcon} 
                            onClick=    {accept}
                            css=        {"margin-right: 20px;"}
                        >Ja</ActionButton>
                        <ActionButton
                            background= {[Color.red, Color.lightRed]} 
                            icon=       {AcceptIcon} 
                            onClick=    {reject}
                        >Nej</ActionButton>
                    </Flex>
                </PopupContainer>
            </Container>
        </Overlay>
    )
}

export default ConfirmationPopup;