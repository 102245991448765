import React, { useEffect, useState }from "react";
import styled from "styled-components";

import Auth from "../../datasource/auth";

import { IProject, IGeoData, ITimeReport } from "../../api/GeoDocModels";
import Color from "../../resources/colors";

import { HorizontalFlex, VerticalFlex } from "../../pandora/styled";
import TimeReportList, { TimeReportItemPair } from "./TimeReportList";


const Container = styled(VerticalFlex)<{isProjectView: boolean}>`
    position: relative;

    overflow: auto; 
    align-items: center; 

    height: ${props => !props.isProjectView && Auth.isEmployee() ? "calc(100% - 110px)" : "100%"};
`

const SummaryBar = styled(HorizontalFlex)<{isProject: boolean}>`
    position: relative;
    justify-content: space-between;
    width: 100%;
    height: 40px;

    padding-left: 20px;
    padding-right: 20px;

    box-sizing: border-box;

    border-radius: 5px;
    background: ${Color.darkGrey};
`

const Title = styled("h1")<{css?: string}>`

    position: relative;
    height: 40px;

    color: white;
    font-size: 8pt;
    line-height: 40px;
    font-size: 13px;
    text-align: center;
    margin: 0;
    border-radius: 5px;
    user-select: none;

    font-style: normal;
    font-weight: bold;

    ${props => props.css ?? ""}
`


interface TimeReportProps {
    project?: IProject | null
    geoData?: IGeoData | null
    deleteTimeReport?: (timeReport: ITimeReport) => void
    openGeoDataView?: (geoData: IGeoData) => void;
}

const TimeReport = (props: TimeReportProps): React.ReactElement => {

    const { 
        project,
        geoData,
        deleteTimeReport,
        openGeoDataView
    } = props;
    
    // State
    const [items, setItems] = useState<TimeReportItemPair[]>([]);
    const [totalTime, setTotalTime] = useState<number>(0);

    // Effects

    /**
     * Update data on input 
     * props change. 
     */
    useEffect(() => {
        if (geoData !== undefined) {
            setData([geoData]);
            return;
        } 
        if (project !== undefined) {
            setData(project.geoData);
        }
    }, [project, geoData]);

    // Summarise time for all TimeReport on GeoData
    // and collect time reports in tuple with geodata. 
    const setData = (data: IGeoData[]) => {
        const _items: TimeReportItemPair[] = [];
        let _time = 0;

        data.forEach((geoData: IGeoData) => {
            geoData.timeReports.forEach((report: ITimeReport) => {
                _items.push([geoData, report])
                _time += report.hours;
            });
        })

        setItems(_items);
        setTotalTime(_time);
    }

    return (
        <Container isProjectView={project !== undefined}>
            <SummaryBar isProject={project !== undefined}>
                <Title>Total tid</Title>
                <Title css={"font-size: 17px;"}>{totalTime + "h"}</Title>
            </SummaryBar>
            <TimeReportList 
                items=              {items}
                isProjectView=      {project !== undefined}
                openGeoData=        {openGeoDataView}
                deleteTimeReport=   {deleteTimeReport}
            />
        </Container>
    )
}


export default TimeReport;