import React from "react";
import styled from "styled-components";

import { IProject } from "../../../api/GeoDocModels";
import { ProjectAction } from "../../../pandora/pandora";

import Color from "../../../resources/colors";
import ProjectListItem from "./ProjectsListItem";
import NoDataComponent from "../../NoDataComponent";


const ListDiv = styled("div")<{itemWasSelected: boolean}>`
    display: ${props => props.itemWasSelected ? "flex" : "block"};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    transition: 0.3s ease;
`



interface ProjectsListProps {
    projects: IProject[]
    selectedProject: IProject | null
    hiddenProjects: IProject[]
    selectProjectCallback: (project?: IProject) => void
    projectActionCallback: (project: IProject, action: ProjectAction) => void
}

const ProjectsList = (props: ProjectsListProps): React.ReactElement => {

    const {
        projects,
        selectedProject,
        hiddenProjects,
        selectProjectCallback,
        projectActionCallback
    } = props;
    
    return (
        projects.length !== 0 ? 
            <ListDiv itemWasSelected={selectedProject != null}>
                { projects.map(p => 
                        <ProjectListItem 
                            key=                {p.id}
                            project=            {p} 
                            selectedProject=    {selectedProject}
                            isHidden=           {hiddenProjects.includes(p)}   
                            color=              {p.color} 
                            onClick=            {selectProjectCallback}
                            actionCallback=     {projectActionCallback}
                        />
                    )
                }
            </ListDiv>
            :
            <NoDataComponent 
                message=    {"Inga projekt"}
                color=      {Color.red}
                css=        {"padding: 20px;"}
            />
    )
}

export default ProjectsList;