import React, { useState, useRef } from 'react';
import styled from 'styled-components';

import InfoIcon from "../resources/images/info-icon.png";
import { InfoPopupAnimation } from "../pandora/animations";
import Color from "../resources/colors";


const Container = styled("div")<{css?: string}>`
    position: absolute;

    width: 15px;
    height: 15px;
    padding: 2px;

    border-radius: 5px;
    transition: 0.3s ease;

    background-image: url(${InfoIcon});
    background-repeat: no-repeat;
    background-size: 70% 70%;
    background-position: center;

    &: hover {
        background-color: ${Color.extraLightGrey};
    }

    ${props => props.css ?? ""}
`

const MessageContainer = styled("div")<{top: number}>`
    position: fixed;
    width: auto !important;
    height: auto !important;
    padding: 10px;

    left: 350px;
    top: ${props => props.top};

    text-indent: 0 !important;
    font-weight: normal;
    font-size: 11px;
    text-align: left;
    line-height: 15px;

    overflow-wrap: break-word;
    white-space: pre-wrap;

    border-radius: 5px;
    background-color: ${Color.darkGrey};

    animation: ${InfoPopupAnimation} 0.3s 1;
`



interface InfoComponentProps {
    message: string;
    css?: string;
}

const InfoComponent = (props: InfoComponentProps): React.ReactElement => {

    const containerRef = useRef<HTMLDivElement | null >(null);
    const [isOpen, setOpen] = useState<boolean>(false);

    return (
        <Container
            ref=            {el => containerRef.current = el}          
            onMouseOver=    {() => setOpen(true)}
            onMouseLeave=   {() => setOpen(false)}
            css=            {props.css}
        >
            {isOpen && 
                <MessageContainer top={containerRef.current.offsetTop}>
                    {props.message}
                </MessageContainer>
            }
        </Container>
    )

}

export default InfoComponent;