import React from "react";
import styled from "styled-components";
 
import { ViewEntranceAnimation } from "../pandora/animations";
import { ViewContainer, CloseButton } from "../pandora/styled";

import { GeoDocCookies, CookieHandler } from "../datasource/cookies";
import MapType from "../pandora/MapType";
import Color from "../resources/colors";
import Overlay from "../components/Overlay";

import MapIcon from "../resources/images/layers.png";


const SelectionContainer = styled.div`
    position: relative;
    background: ${Color.darkGrey};
    border-radius: 10px;
    height: auto%;
    width: auto;
    padding: 30px;

    display: flex;
    flex-direction: column;

    animation: ${ViewEntranceAnimation} 0.2s linear 1;
`

const SelectionTitle = styled.h1`
    text-align: left;
    color: white;
    font-size: 25px;
    line-height: 35px;

    height: 35px;
    margin: 0px;
    margin-bottom: 20px;
    padding-left: 50px;
`

const SelectionOptionContainer = styled.div`
    height: auto;
    width: auto;
    padding-bottom: 20px;
    margin-top: 20px;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
`


const MapOptionDiv = styled("div")<{isSelected: boolean}>`
    height: auto;
    width: 140px;
    border-radius: 5px;

    padding: 10px;
    border-width: 0;
    cursor: pointer;
    background: ${props => props.isSelected ? Color.lightGrey : "none"};

    transition: 0.3s ease;

    &: hover {
        background-color: ${Color.lightGrey};
        transform: scale(1.05) translate(0px, -5px);
    }
    &: active {
        background: rgba(255, 255, 255, 0.5);
    }
`

const MapOptionImage = styled("div")<{image: string}>`
    height: 150px;
    width: 100%;
    border-radius: 5px;
    background-image: url(${props => props.image}); 
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`

const MapOptionTitle = styled.h1`
    text-align: center;
    color: white;
    font-size: 15px;
    margin-top: 15px;
`

const ContainerIcon = styled("div")<{icon: string}>`
    position: absolute;
    left: 30px;
    top: 30px;

    width: 35px;
    height: 35px;
    background-image: url(${props => props.icon});
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 100% 100%;
`   




interface MapOptionProps {
    type: MapType
    selected: MapType
    selectMap: (type: MapType) => void
}

const MapOption = (props: MapOptionProps): React.ReactElement => {

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        props.selectMap(props.type);

        // Set selected map type cookie
        CookieHandler.set(GeoDocCookies.MapType, props.type.name);
    }

    return (
        <MapOptionDiv 
        isSelected={props.selected === props.type}
        onClick={(e) => handleClick(e)}
        >
            <MapOptionImage image={props.type.image} />
            <MapOptionTitle>{props.type.name}</MapOptionTitle>
        </MapOptionDiv>        
    )
}



interface MapSelectionProps {
    selected: MapType
    selectMap: (type: MapType) => void
    toggleMapSelection: () => void
}

const MapSelection = (props: MapSelectionProps): React.ReactElement => {
    return (
        <Overlay onClick={props.toggleMapSelection}>
            <ViewContainer>
                <SelectionContainer onClick={(e) => e.stopPropagation()}>
                    <ContainerIcon icon={MapIcon} />
                    <SelectionTitle>Välj karttyp</SelectionTitle>
                    <SelectionOptionContainer>
                        { MapType.all().map((m: MapType) => 
                            <MapOption 
                                key=        {m.name}
                                type=       {m}
                                selected=   {props.selected}
                                selectMap=  {props.selectMap} 
                            /> 
                        )}
                    </SelectionOptionContainer>
                    <CloseButton onClick={props.toggleMapSelection} />
                </SelectionContainer>
            </ViewContainer>
        </Overlay>

    )
}

export default MapSelection;




