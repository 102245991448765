import React from "react";
import styled from "styled-components";
import moment from "moment";

import { IGeoData } from "../api/GeoDocModels";
import { DATE_FORMAT } from "../pandora/pandora";

import Color from "../resources/colors";
import { ItemContainerAnimation } from "../pandora/animations";
import { HorizontalFlex, VerticalFlex } from "../pandora/styled";




const Container = styled(HorizontalFlex)<{selectable: boolean, css?: string}>`
    position: relative;
    min-height: 40px;
    height: fit-content;
    max-width: 100%;
    border-radius: 5px;

    background: ${Color.darkGrey};
    margin-bottom: 10px;
    justify-content: space-between;
    line-height: 40px;
    align-items: center;
    padding: 10px;
    
    cursor: ${props => props.selectable ? "pointer" : "auto"};
    overflow: hidden;

    ${props => props.css ?? ""}

    transition: 0.2s ease;
    animation: ${ItemContainerAnimation} 0.3s 1;

    &:hover {
        background: ${props => props.selectable ? Color.extraLightGrey : Color.darkGrey};
    }
    &:active {
        background: ${props => props.selectable ? "rgba(255, 255, 255, 0.3)" : Color.darkGrey};
    }
`

const Title = styled("h1")<{css?: string}>`
    color: white;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    text-align: left;
    margin: 0;

    ${props => props.css ?? ""}
`

const IDLabel = styled.h3`
    color: white;
    font-weight: normal;
    font-size: 10px;
    line-height: 10px;
    text-align: right;
    margin: 0;
    white-space: nowrap;
`


interface GeoDataContainerProps {
    geoData: IGeoData
    openData?: (data?: IGeoData) => void
}   

const GeoDataContainer = (props: GeoDataContainerProps): React.ReactElement => {

    const { 
        geoData,
        openData
    } = props

    const open = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (openData !== undefined) openData(geoData);
    }

    return (
        <Container 
            onClick=        {open}
            selectable=     {openData !== undefined}
        >
            <VerticalFlex>
                <Title>{geoData.name}</Title>
                <Title 
                    css={"font-weight: normal; margin-top: 5px;"}
                >{moment(geoData.time).format(DATE_FORMAT)}</Title>
            </VerticalFlex>
            <IDLabel>{"# " + geoData.id}</IDLabel>
        </Container>
    )
}

export default GeoDataContainer;



