import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Sleipnir from "../../api/Sleipnir";
import APIRequest from "../../api/APIRequest";

import { IImage } from "../../api/GeoDocModels";
import { GenericPopupProps } from "../../components/GenericPopup";

import Color from "../../resources/colors";
import { ItemContainerAnimation } from "../../pandora/animations";

import { VerticalFlex } from "../../pandora/styled";
import NoDataComponent from "../../components/NoDataComponent";
import LoadingComponent from "../../components/LoadingComponent";


const Container = styled.div`
    height: 100% !important;
    width: auto;
    max-height: 100%;
    overflow: hidden;
`

const ImageListContainer = styled(VerticalFlex)`
    max-height: 100%; 
    height: 100%;
    padding-bottom: 20px; 
    overflow-y: auto; 
    overflow-x: hidden;
`

const Image = styled("img")`
    width: 100%;
    height: auto;
    object-fit: contain;

    margin-top: 10px;
    border-radius: 5px;
    background-color: ${Color.darkGrey};

    animation: ${ItemContainerAnimation} 0.3s 1;
`



interface ImageListProps {
    geoDataID: number;
    cachedImages: IImage[] | null;
    setCachedImages: (value: IImage[] | null) => void;
    setPopup: (value: GenericPopupProps | null) => void;
}

const ImageList = (props: ImageListProps): React.ReactElement => {

    // State
    const [images, setImages] = useState<IImage[] | null>(props.cachedImages);

    // Effects
    useEffect(() => {
        
        // Denotes component is mounted to prevent
        // state update on an unmounted component.
        // Is set to false in cleanup callbackn on 
        // on component unmount. 
        let isMounted: boolean = true;

        // Request image fetch from API.
        const getImages = async () => {
            try {
                const _images: IImage[] = await Sleipnir.request(APIRequest.getGeoDataImages, {id: props.geoDataID}) as IImage[];
                
                if (isMounted) setImages(_images);
                props.setCachedImages(_images);
            } catch (e) {
                props.setPopup({
                    title: "Ett fel uppstod.",
                    message: `${e.message}`,
                    color: Color.red,
                    setPopup: props.setPopup
                })
            }
        }

        if (images === null) getImages()

        // Set "isMounted" false on component dismount. 
        return () => { isMounted = false; }
    }, [])


    // Actions
    const decode = (image: string): string => `data:image/jpeg;base64,${image}`
    
    return (
        <Container>
            {images !== null && images.length !== 0 &&
                <ImageListContainer>
                    {images.map((i: IImage) => 
                            <Image 
                                key={i.id}
                                src={decode(i.bytes)} 
                            />
                        )
                    }
                </ImageListContainer>
            }
            {images !== null && images.length === 0 &&
                <NoDataComponent 
                    message=    {"Inga bilder"}
                    color=      {Color.darkGrey}
                    textColor=  {Color.darkGrey}
                    css=        {"padding: 20px;"}
                />
            }
            {images === null &&
                <LoadingComponent 
                    message={"Hämtar bilder..."}
                />
            }
        </Container>
    )
}

export default ImageList;