import React, { useState } from "react";
import styled from "styled-components";

import Color from "../../../resources/colors";
import { IOrganisation } from "../../../api/GeoDocModels";
import { Arrow } from "../../../pandora/styled";


const DropDownContainer = styled.div` 
    width: 100%;;
    margin: 0 auto;
    text-align: left;

    text-indent: 10px;
    font-size: 8pt;
    font-weight: normal;
    color: white;
    border-width: 0px;
    cursor: pointer;

    user-select: none;
`;

const DropDownHeader = styled.div`
    position: relative;
    background-color: ${Color.lightGrey};
    border-radius: 5px;
    outline: none;
    width: auto;
    height: 40px;
    
    line-height: 50px;
    font-weight: bold;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;

    z-index: 4;

    transition: 0.3s ease;

    &: hover {
        background-color: ${Color.extraLightGrey};
    }
`;

const DropDownList = styled.ul`
    padding: 0;
    margin: 0;
    background: ${Color.lightGrey};
    border-radius: 0 0 5px 5px;

    overflow: hidden;

    margin-top: -10px;
    padding: 5px;
    padding-top: 15px;

    font-weight: normal;
`

const ListItem = styled.li`
    list-style: none;
    margin-bottom: 5px;
    height: 35px;
    line-height: 35px;
    border-radius: 5px;

    display: flex;
    flex-direction: row;
    align-items: center;

    transition: 0.3s ease;

    &: hover {
        background: ${Color.darkGrey};
    }
`






interface OrganisationSelectorProps {
    orgs: IOrganisation[]
    setOrganisationID: (org: string) => void
}

const OrganisationSelector = (props: OrganisationSelectorProps): React.ReactElement => {

    // State
    const organisations: IOrganisation[] = props.orgs;
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOrg, setSelectedOrg] = useState<IOrganisation>(null);

    // Actions
    const toggling = () => setIsOpen(!isOpen);

    const onOptionClicked = (org: IOrganisation) => () => {
        setSelectedOrg(org);
        props.setOrganisationID(org.id)
        setIsOpen(false);
    };

    return (
        <DropDownContainer>
            <DropDownHeader onClick={toggling}>
                <Arrow rotate={isOpen} />                
                {selectedOrg !== null ? selectedOrg.name : "Välj organisation"}
            </DropDownHeader>
            {isOpen && (
                <DropDownList>
                    {organisations.map(org => (
                        <ListItem 
                            onClick={onOptionClicked(org)} 
                            key={Math.random()}
                        >
                            {org.name}
                        </ListItem>
                    ))}
                </DropDownList>
        )}
        </DropDownContainer>
    );
}


export default OrganisationSelector;

