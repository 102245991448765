import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import { AxiosResponse } from "axios";

import Sleipnir from "../../api/Sleipnir";
import APIRequest from "../../api/APIRequest";
import RequestFactory from "../../api/RequestFactory";
import { IGeoData, IProject } from "../../api/GeoDocModels";

import ShareHandler from "../../datasource/share";
import Auth from "../../datasource/auth";
import { ClientAction } from "../../datasource/actions";
import FileSaver from "../../datasource/fileSaver";

import EditIcon from "../../resources/images/edit-icon.png";
import GoToIcon from "../../resources/images/go-to-icon.svg";
import Color from "../../resources/colors";

import Overlay from "../../components/Overlay";
import ProjectGeoData from "./components/ProjectGeoData";
import Activity from "../../components/activity/Activity";
import TimeReport from "../../components/time-report/TimeReport";
import ExportTimeReport from "../../components/time-report/ExportTimeReport";
import GenericPopup, { GenericPopupProps } from "../../components/GenericPopup";
import ConfirmationPopup, { Confirmation } from "../../components/ConfirmationPopup";
import { ViewEntranceAnimation } from "../../pandora/animations";

import { ViewContainer, VerticalFlex, HorizontalFlex, CloseButton, NameInput, DescriptionInput, SaveButton, ContentStateButton, ActionButton, LinkField, LinkButton, ProjectSubscriptionButton } from "../../pandora/styled";



const ProjectContainer = styled(VerticalFlex)`
    position: relative;
    background: ${Color.darkGrey};
    border-radius: 5px;
    height: 80%;
    width: 70%;
    padding: 40px;
    overflow: hidden;

    animation: ${ViewEntranceAnimation} 0.2s linear 1;
`

const Header = styled(HorizontalFlex)`
    align-items: left;
    height: 70px;
    justify-content: flex-start;
    position: relative;
`

const Marker = styled("div")<{background: string}>`
    width: 70px;
    height: 70px;
    border-radius: 5px;
    background: ${props => props.background};
    margin-right: 20px;
`

const TitleDiv = styled(VerticalFlex)`
    align-items: left;
    height: 100%;
    justify-content: center;
    width: fit-content;
    margin-right: 20px;
`

const TopTitle = styled.h3`
    color: white;
    font-size: 12pt;
    font-weight: normal;
    text-align: left;
    line-height: 12pt;
    margin: 0;
    width: fit-content;
    text-transform: uppercase;
`

const Title = styled("h1")<{size: number}>`
    text-align: left;
    color: white;
    font-size: ${props => props.size}pt;
    margin: 0;
    width: fit-content;
`

const ContentDiv = styled(HorizontalFlex)<{css?: string}>`
    width: auto;
    height: auto;
    max-height: 85%;
    overflow: hidden;
    background: ${Color.lightGrey};
    border-radius: 5px;
    padding: 20px;

    display: flex;
    flex-direction: column;
    ${props => props.css ?? ""}
`

const TopActionsDiv = styled(HorizontalFlex)`
    position: absolute;
    top: 30px;
    right: 30px;
    height: 50px;
`






enum ContentState {
    Data = "Data",
    Activity = "Activity",
    Time = "Time"
}

const ContentStateTitleMap = {
    [ContentState.Activity]: "Aktivitet",
    [ContentState.Data]: "Data",
    [ContentState.Time]: "ÄTA"
}

interface ProjectViewProps {
    project: IProject
    openGeoData: (data?: IGeoData) => void
    requestFlyToPoint: (geoData: IGeoData) => void
    toggleViewVisibility: (project: IProject) => void
}

const ProjectView = (props: ProjectViewProps): React.ReactElement => {

    const dispatch = useDispatch()

    // State
    const [project, setProject] = useState<IProject>(props.project);
    const [name, setName] = useState<string>(props.project.name);
    const [description, setDescription] = useState<string>(props.project.description); 
    const [hasChanges, setHasChanges] = useState<boolean>(false);

    const [isSubscribed, setSubscriptionStatus] = useState<boolean | null>(null);

    const [contentState, setContentState] = useState<ContentState>(ContentState.Data);

    const [confirmation, setConfirmation] = useState<Confirmation | null>(null);
    const [popup, setPopup] = useState<GenericPopupProps | null>(null);


    // Effects

    /**
     * Update project property UI
     * on project change. 
     */
    useEffect(() => {

        // Request flag indicating if the  
        // user is subscribed to notifications
        // for the current project. 
        const getSubscriptionStatus = async () => {
            try {
                const res = await Sleipnir.request(APIRequest.getProjectSubscriptionStatus, {id: project.id})
                setSubscriptionStatus(res["subscribed"])
            } catch (e) {
                setPopup({
                    title: "Ett fel uppstod.",
                    message: `${e.message}`,
                    color: Color.red,
                    setPopup: setPopup
                }) 
            }
        }  

        setSubscriptionStatus(null);
        getSubscriptionStatus()
        setProject(props.project);
        setName(props.project.name);
        setDescription(props.project.description);
    }, [props.project]);


    // Actions

    /**
     * Request data save to API. 
     */
    const saveChanges = async (showPopup: boolean = true) => { 
        try {
            if (name === "" || description === "") throw new Error("Tomma värden ej tillåtna.")

            // Construct payload
            const payload = {name: name, description: description}

            // Request project data save. 
            const _project: IProject = await RequestFactory.updateProject(project.id, payload)

            // Update project in Redux store. 
            dispatch(ClientAction.updateProject(_project.id, payload))
            setHasChanges(false);
           
            if (showPopup) {
                setPopup({
                    title: "Sparat!",
                    message: "Ändringarna har sparats.",
                    color: Color.green,
                    setPopup: setPopup
                }) 
            }
        } catch (e) {
            setPopup({
                title: "Ett fel uppstod.",
                message: `${e.message}`,
                color: Color.red,
                setPopup: setPopup
            }) 
        }
    }

    /**
     * Handle name editing. 
     */
    const editName = (e) => { 
        const { value } = e.target;
        if (value.length <= 50) {
            setName(value);
            if (!hasChanges) setHasChanges(true);
        }
    }

    /**
     * Handle description editing. 
     */
    const editDescription = (e) => { 
        const { value } = e.target;
        if (value.length <= 500) {
            setDescription(value);
            if (!hasChanges) setHasChanges(true);
        }
    }

    /**
     * 
     */
    const editBoundaries = () => { console.log("NOT IMPLEMENTED") }

    /**
     * Open GeoDataView for selected GeoData. 
     * @param data 
     */
    const openGeoData = (data?: IGeoData) => { props.openGeoData(data) }

    /**
     * Request TimeReport export with interval. 
     * @param fromDate 
     * @param toDate 
     */
    const exportTimeReport = async (fromDate: Date | null, toDate: Date | null) => {

        // Construct payload
        const payload = {
            "id": project.id,
            "fromTime": fromDate !== null ? moment(fromDate).utc().format() : null,
            "toTime": toDate !== null ? moment(toDate).utc().format() : null,
        }

        try {
            // Request TimeReport export file
            const res: AxiosResponse = await Sleipnir.request(APIRequest.exportTimeReport, payload, true);

            // Save file to client
            const saveConfig = `${APIRequest.exportTimeReport.headers["Accept"]}`
            FileSaver.save(res.data, 
                            `Export ÄTA ${project.name}`,
                            saveConfig)

            setPopup({
                title: "Export sparad!",
                message: `Export av ÄTA rapport för projekt ${project.name} utförd.`,
                color: Color.green,
                setPopup: setPopup
            }) 
        } catch (e) {
            setPopup({
                title: "Ett fel uppstod.",
                message: `${e.message}`,
                color: Color.red,
                setPopup: setPopup
            }) 
        }
    }

    /**
     * Request subscription status change.
     */
    const toggleSubscriptionStatus = async () => {
        try {
            const request = isSubscribed ? APIRequest.unsubscribeFromProject : APIRequest.subscribeToProject
            const res: AxiosResponse = await Sleipnir.request(request, {id: project.id});

            setSubscriptionStatus(!isSubscribed);

            setPopup({
                title: "Notiser",
                message: `Notiser har slagits ${!isSubscribed ? "på" : "av"}.`,
                color: !isSubscribed ? Color.green : Color.red,
                setPopup: setPopup
            }) 
        } catch (e) {
            setPopup({
                title: "Ett fel uppstod.",
                message: `${e.message}`,
                color: Color.red,
                setPopup: setPopup
            }) 
        }
    }

    /**
     * Request fly to point.
     */
    const flyTo = () => { 
        if (project.geoData.length !== 0) {
            props.toggleViewVisibility(project);
            props.requestFlyToPoint(project.geoData[0]) 
        }
    }

    /**
     * Copy the share link for the Project to the clipboard. 
     */
    const share = () => {
        const el = document.getElementById("link-ref") as HTMLInputElement;
        el.select()
        document.execCommand("copy")
        setPopup({
            title: "Länk kopierad!",
            message: "Länken har kopierats.",
            color: Color.green,
            setPopup: setPopup
        }) 
    }

    /**
     * Close view. 
     * Confirm change saving before exit. 
     */
    const close = (e: React.MouseEvent) => {
        e.stopPropagation();

        if (hasChanges) {

            const callback = (save: boolean) => { 
                if (save) saveChanges(false)
                props.toggleViewVisibility(project)
            }

            setConfirmation({
                title: "Osparade ändringar!",
                message: "Vill du spara ändringarna?",
                callback: () => callback(true),
                reset: () => callback(false)
            })
        } else {
            props.toggleViewVisibility(project)
        }
    }




    return (
        <Overlay onClick={close}>
            <ViewContainer>
                {confirmation !== null && <ConfirmationPopup confirmation={confirmation}/> }
                {popup && <GenericPopup 
                                title=          {popup.title}
                                message=        {popup.message}
                                color=          {popup.color}
                                setPopup=       {setPopup}
                            />
                }
                <ProjectContainer onClick={(e) => e.stopPropagation()}>
                    <TopActionsDiv>
                        {isSubscribed !== null && 
                            <ProjectSubscriptionButton 
                                color=      {isSubscribed ? Color.red : Color.green}
                                hoverColor= {isSubscribed ? Color.lightRed : Color.lightGreen}
                                onClick=    {toggleSubscriptionStatus}
                            ><b>{isSubscribed ? "Stäng av notiser" : "Slå på notiser"}</b></ProjectSubscriptionButton>
                        }
                        {hasChanges && 
                            <SaveButton 
                                onClick=    {() => saveChanges(true)}
                            >Spara</SaveButton>
                        } 
                        {!hasChanges && 
                            <CloseButton 
                                onClick={close} 
                                css={"position: relative; right: 0; top: 0;"}
                            />
                        } 
                    </TopActionsDiv>
                    <Header>
                        <Marker background={props.project.color} />
                        <TitleDiv>
                            <TopTitle>{project.organisation.name}</TopTitle>
                            <NameInput 
                                enabled=        {Auth.isEmployee()}
                                placeholder=    {"Namn"}
                                value=          {name}
                                onChange=       {editName}
                                readOnly=       {!Auth.isEmployee()}
                            />
                        </TitleDiv>
                    </Header>
                    <HorizontalFlex css={"margin-top: 40px; justify-content: space-between; height: 100%; overflow: hidden;"}>
                        <VerticalFlex css={"padding-right: 20px; width: 30%"}>
                            <ContentDiv css={"margin-bottom: 20px;"}>
                                <Title size={13}>Beskrivning</Title>
                                <DescriptionInput
                                    enabled=        {Auth.isEmployee()}
                                    placeholder=    {"Beskrivning"}
                                    value=          {description}
                                    onChange=       {editDescription}
                                    readOnly=       {!Auth.isEmployee()}
                                />
                            </ContentDiv>
                            <ContentDiv css={"margin-bottom: 10px; padding: 10px; flex-direction: row;"}>
                                <LinkButton onClick={share}/>
                                <LinkField
                                    id=         {"link-ref"} 
                                    readOnly=   {true}
                                    value=      {ShareHandler.shareProject(project)}
                                />
                            </ContentDiv>
                            {Auth.isEmployee() && <ActionButton 
                                background={[Color.green, Color.lightGreen]}
                                icon={EditIcon}
                                onClick={editBoundaries}
                            >Ändra projektgränser</ActionButton>}
                            <ActionButton 
                                background={[Color.purple, Color.lightPurple]}
                                icon={GoToIcon}
                                onClick={flyTo}
                            >Zooma projekt</ActionButton>
                        </VerticalFlex>
                        <VerticalFlex css={"padding-left: 20px; width: 70%;"}>
                            <HorizontalFlex css={"margin-bottom: 20px; justify-content: flex-start;"}>
                                {Object.keys(ContentState).map((c: string) => (
                                        <ContentStateButton
                                            key=        {c}
                                            clicked=    {contentState === ContentState[c]}
                                            onClick=    {() => setContentState(ContentState[c])}
                                        >{ContentStateTitleMap[ContentState[c]]}</ContentStateButton>
                                    ))
                                }
                            </HorizontalFlex>
                            <ContentDiv css={"height: 100%; position: relative; overflow: auto;"}>
                                {contentState === ContentState.Data && 
                                    <ProjectGeoData 
                                        project=            {project}
                                        openGeoDataView=    {openGeoData}
                                    />
                                }
                                {contentState === ContentState.Activity && 
                                    <div style={{height: "100%"}}>
                                        <Activity 
                                            project=            {project}
                                            openGeoDataView=    {openGeoData}
                                        />
                                    </div>  
                                }
                                {contentState === ContentState.Time && 
                                    <div style={{height: "100%", overflow: "hidden"}}>
                                        <ExportTimeReport 
                                            project=            {project}
                                            exportTimeReport=   {exportTimeReport}
                                        />
                                        <TimeReport 
                                            project=            {project}
                                            openGeoDataView=    {openGeoData}
                                        />
                                    </div>  
                                }
                            </ContentDiv>
                        </VerticalFlex>
                    </HorizontalFlex>
                </ProjectContainer>
            </ViewContainer>
        </Overlay>
    )
}

export default ProjectView;




