import React, { useState } from "react";
import styled from "styled-components";

import { ViewEntranceAnimation } from "../../pandora/animations";
import { HorizontalFlex, VerticalFlex, CloseButton, ViewContainer } from "../../pandora/styled";

import { IAuthenticatedUser, IProject } from "../../api/GeoDocModels";
import Color from "../../resources/colors";
import Overlay from "../../components/Overlay";
import Auth from "../../datasource/auth";
import Redirect from "../../datasource/redirect";

import GenericPopup, { GenericPopupProps } from "../../components/GenericPopup";
import ConfirmationPopup, { Confirmation } from "../../components/ConfirmationPopup";
import SettingsBar, { ContentState } from "./SettingsBar";
import ProfileSection from "./ProfileSection";
import ProjectsSection from "./project/ProjectsSection";


const UserContainer = styled(VerticalFlex)<{isAdmin: boolean}>`
    position: relative;
    background: ${Color.darkGrey};
    border-radius: 10px;
    height: ${props => props.isAdmin ? "500px" : "fit-content"};
    width: ${props => props.isAdmin ? "50%" : "30%"};;
    overflow: hidden;
    display: flex;

    animation: ${ViewEntranceAnimation} 0.2s linear 1;
`

const ContentContainer = styled("div")<{isAdmin: boolean}>`
    position: relative;
    width: ${props => props.isAdmin ? "calc(100% - 200px)" : "100%"};
    height: auto;
    background: transparent;
    padding: 30px;
`




interface UserViewProps {
    projects: IProject[]
    toggleUserView: () => void
    openProject: (project: IProject) => void;
}

const UserView = (props: UserViewProps): React.ReactElement=> {

    const { 
        projects,
        toggleUserView,
        openProject
        } = props;

    // State
    const user: IAuthenticatedUser = Auth.currentUser;
    const isAdmin: boolean = Auth.isAdmin();

    const [contentState, setContentState] = useState<ContentState>(ContentState.Profile);

    const [confirmation, setConfirmation] = useState<Confirmation | null>(null) 
    const [popup, setPopup] = useState<GenericPopupProps | null>(null)
    console.log("admin", isAdmin)
    // Actions
    const logout = () => Auth.logout()
    const redirect = () => Redirect.asuite()

    return (
        <Overlay onClick={toggleUserView}>
            <ViewContainer>
                {confirmation !== null && <ConfirmationPopup confirmation={confirmation}/> }
                {popup && <GenericPopup 
                                title=          {popup.title}
                                message=        {popup.message}
                                color=          {popup.color}
                                setPopup=       {setPopup}
                            />
                }
                <UserContainer 
                    isAdmin=    {isAdmin}
                    onClick=    {(e) => e.stopPropagation()}
                >
                    <HorizontalFlex css={"justify-content: flex-end; padding: 0; height: 100%;"}>
                        {isAdmin &&
                            <SettingsBar 
                                isAdmin=        {isAdmin}
                                selectedState=  {contentState}
                                selectState=    {setContentState}     
                            />
                        }
                        <ContentContainer
                            isAdmin={isAdmin}
                        >
                            {contentState === ContentState.Profile && 
                                <ProfileSection 
                                    user=       {user} 
                                    isAdmin=    {isAdmin}
                                    logout=     {logout}
                                    redirect=   {redirect}
                                />
                            }
                            {contentState === ContentState.Projects && 
                                <ProjectsSection 
                                    projects=           {projects}
                                    openProject=        {openProject}
                                    setConfirmation=    {setConfirmation}
                                    setPopup=           {setPopup}
                                />
                            }
                        </ContentContainer>
                    </HorizontalFlex>
                    <CloseButton 
                        onClick={toggleUserView}
                    />
                </UserContainer>
            </ViewContainer>
        </Overlay>

    )
}

export default UserView;




