import React, {useState} from "react";
import styled from "styled-components";

import magGlass from "../../resources/images/magnifying-glass.png";
import Color from "../../resources/colors";



const SearchBarContainer = styled.div`
    position: relative;
    background-color: ${Color.darkGrey};
    height: 50px;
    width: 80%; 
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    transition: 0.3s ease;

    &: hover {
        width: 100%;
        background: ${Color.lightGrey};
    }
`

const SearchIcon = styled.img`
    position: absolute;
    left: 15px;
    height: 40%;
`

const SearchInput = styled.input`
    position: absolute;
    background-color: transparent;
    border: 0;
    color: white;
    font-family: Montserrat, sans-serif;
    font-weight: bold;
    font-size: 12px;
    outline: none;
    ::placeholder {
        color: white;
        font-family: Montserrat, sans-serif;
        font-weight: bold;
        opacity: 50%;
    }
    left: 48px;
    width: 80%;
    height: 50%;
`

export const SearchBar = (): React.ReactElement => {

    const [search, setSearch] = useState(""); 

    const handleChange = event => {
        setSearch(event.target.value);
    }

    return (
        <SearchBarContainer>
            <SearchIcon src={magGlass} alt="Search" />
            <SearchInput 
                placeholder="Sök GeoDoc..." 
                type="text"
                value={search}
                onChange={handleChange}
            />
        </SearchBarContainer>
    );
}