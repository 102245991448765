import _FS from "file-saver";


export default class FileSaver {

    /**
     * Save the passed data to the file system. 
     * @param data Data to save
     * @param filename Requested filename for saved data
     * @param config Save configuration params
     */
    public static save(data: string, filename: string, config?: string) {
        const blob: Blob = new Blob([data], {
            type: config ?? ""
        });
        _FS.saveAs(blob, filename);
    }
}