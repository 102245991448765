import React, { useEffect } from "react";
import styled from "styled-components";

import { IProject } from "../../../api/GeoDocModels";
import { ProjectAction } from "../../../pandora/pandora";
import Color from "../../../resources/colors";

import ListIcon from "../../../resources/images/list-icon.svg";
import EyeIcon from "../../../resources/images/eye-icon.svg";
import InfoIcon from "../../../resources/images/info-icon.svg";
import FlyToIcon from "../../../resources/images/go-to-icon.svg";



const ListItem = styled("div")<{isSelected: boolean, itemWasSelected: boolean}>`
    height: 35px;
    width: ${props => props.itemWasSelected ? "35px" : "auto"};
    padding: 10px;
    border-radius: 5px;

    display: flex;
    flex-direction: ${props => props.isSelected ? "column" : "row"};
    justify-content: ${props => props.itemWasSelected && !props.isSelected ? "center" : "flex-start"};
    align-items: center;

    margin-bottom: 10px;
    transition: 0.3s ease;
    transition: background 0.3s;
    transition: height 0.3s;

    background: ${props => props.isSelected ? Color.lightGrey : "transparent"};
    
    cursor: pointer;
    overflow: hidden;

    color: white;

    transition: 0.2s ease;

    &:hover {
        background: ${Color.lightGrey};
    }
 `

const ListItemTitle = styled.h6`
    font-size: 11px;
    margin-left: 10px;
    color: white;
    max-width: 60%;
    text-align: left;
`

const ListItemMarker = styled("div")<{background?: string, isHidden: boolean}>`
    height: 35px;
    width: 35px;
    background-color: ${props => props.background};
    border-radius: 5px;
    
    user-select: none;
    color: white;
    font-size: 12px;
    font-weight: bold;

    line-height: 35px;
    opacity: ${props => props.isHidden ? 0.3 : 1};
`

const ListItemActionDiv = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px;
`

const ListItemActionIcon = styled("div")<{icon: string, color: string, overrideColor?: string}>`
    height: 30px;
    width: 30px;
    
    margin-bottom: 10px;
    border-radius: 50%;

    mask: url(${props => props.icon}) no-repeat 50% 50%;
    mask-size: 60% 60%;
    background-color: ${props => props.overrideColor ?? "white"};

    transition: 0.2s ease;

    &:hover {
        background-color: ${props => props.color};
    }
`


interface ProjectListItemProps {
    project: IProject
    color: string
    isHidden: boolean
    selectedProject: IProject | null
    onClick: (project?: IProject) => void
    actionCallback: (project: IProject, action: ProjectAction) => void
}

const ProjectListItem = (props: ProjectListItemProps): React.ReactElement => {

    const { 
        project,
        color,
        isHidden,
        selectedProject,
        onClick,
        actionCallback
    } = props;
    
    // State
    const listItemID = `li-div${project.id}`
    const isSelected = selectedProject?.id === project.id
    const itemWasSelected = selectedProject !== null 

    // Effect

    // Update item element height on selection 
    useEffect(() => {
        const listItemDiv: HTMLElement = document.getElementById(listItemID);
        const selectedHeight = project.geoData.length !== 0 ? 205 : 165;
        listItemDiv.style.height = selectedProject === project ? `${selectedHeight}px` : "40px";
    }, [selectedProject]);

    // Actions
    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        onClick(project);
    }

    const handleActionClick = (e: React.MouseEvent, action: ProjectAction) => actionCallback(project, action);

    return (
        <ListItem 
            id=                 {listItemID}
            onClick=            {(e) => handleClick(e)}
            isSelected=         {isSelected}
            itemWasSelected=    {itemWasSelected}
        >
            {itemWasSelected && 
                <ListItemMarker 
                    isHidden=   {isHidden} 
                    background= {color}
                >{project.name.charAt(0)}</ListItemMarker>}
            {selectedProject === null && 
                <ListItemMarker 
                    isHidden=   {isHidden} 
                    background= {color} 
                />
            }
            {selectedProject === null && 
                <ListItemTitle>{project.name}</ListItemTitle>
            }
            {isSelected && (
                <ListItemActionDiv>
                    <ListItemActionIcon 
                        onClick=    {(e) => handleActionClick(e, ProjectAction.ListData)} 
                        icon=       {ListIcon}
                        color=      {color}
                    />
                    <ListItemActionIcon 
                        onClick=        {(e) => handleActionClick(e, ProjectAction.Hide)} 
                        icon=           {EyeIcon}
                        color=          {color}
                        overrideColor=  {isHidden ? Color.red : null} 
                    />
                    {project.geoData.length !== 0 && 
                        <ListItemActionIcon 
                            onClick=    {(e) => handleActionClick(e, ProjectAction.FlyTo)} 
                            icon=       {FlyToIcon}
                            color=      {color}
                        />
                    }
                    <ListItemActionIcon 
                        onClick=    {(e) => handleActionClick(e, ProjectAction.Settings)} 
                        icon=       {InfoIcon}
                        color=      {color}
                    />
                </ListItemActionDiv>
            )}
        </ListItem>
    )
}


export default ProjectListItem;