import { IProject, IGeoData, IComment, ICompletion, ITimeReport } from "../api/GeoDocModels";

// Dispatch protocol type for Redux store dispatches
export interface IDispatch { type: string, payload: any }

// Dispatch constructor function
const Dispatch = (t: string, p: any): IDispatch => ({type: t, payload: p});


/**
 * Available Client action types in ORM. 
 */
export enum ClientActionType {

    // PROJECT:
    createProject = "CREATE_PROJECT",   
    deleteProject = "DELETE_PROJECT",
    renameProject = "RENAME_PROJECT",
    updateProject = "UPDATE_PROJECT",
    addGeoDataToProject = "ADD_UPLOADED_GEODATA",

    // GEODATA:
    createGeoData = "CREATE_GEODATA",
    updateGeoData = "UPDATE_GEODATA",
    
    // COMMENTS: 
    postComment = "POST_COMMENT",
    deleteComment = "DELETE_COMMENT",
    
    // COMPLETION: 
    postCompletion = "POST_COMPLETION",
    deleteCompletion = "DELETE_COMPLETION",

    // TIME REPORT: 
    postTimeReport = "POST_TIMEREPORT",
    deleteTimeReport = "DELETE_TIMEREPORT"
}


/**
 * Static list of all dispatch objects with 
 * type identifier and payload sent to Redux reducers.
 * Reducers found on ORM models in Redux ORM. 
 */
export class ClientAction {

    public static createProject = (props: IProject): IDispatch =>                                               Dispatch(ClientActionType.createProject, props);
    public static deleteProject = (id: number): IDispatch =>                                                    Dispatch(ClientActionType.deleteProject, id);
    public static updateProject = (id: number, props: Record<string, any>): IDispatch =>                        Dispatch(ClientActionType.updateProject, {id, props});
    public static addGeoDataToProject = (projID: number, geoData: IGeoData[]): IDispatch =>                     Dispatch(ClientActionType.addGeoDataToProject, {id: projID, geoData})

    public static createGeoData = (props: IGeoData): IDispatch =>                                               Dispatch(ClientActionType.createGeoData, props);
    public static updateGeoData = (id: number, props: Record<string, any>): IDispatch =>                        Dispatch(ClientActionType.updateGeoData, {id, props});
    
    public static postComment = (geoDataID: number, props: IComment): IDispatch =>                              Dispatch(ClientActionType.postComment, {geoDataID, props});
    public static deleteComment = (geoDataID: number, id: number): IDispatch =>                                 Dispatch(ClientActionType.deleteComment, {geoDataID, id});

    public static postCompletion = (geoDataID: number, isInternal: boolean, props: ICompletion): IDispatch =>   Dispatch(ClientActionType.postCompletion, {geoDataID, isInternal, props});
    public static deleteCompletion = (geoDataID: number, isInternal: boolean, id: number): IDispatch =>         Dispatch(ClientActionType.deleteCompletion, {geoDataID, isInternal, id});

    public static postTimeReport = (geoDataID: number, props: ITimeReport): IDispatch =>                        Dispatch(ClientActionType.postTimeReport, {geoDataID, props});
    public static deleteTimeReport = (geoDataID: number, id: number): IDispatch =>                              Dispatch(ClientActionType.deleteTimeReport, {geoDataID, id})
}


