import React, { useState } from "react";
import styled from "styled-components";

import Color from "../resources/colors";
import CommentIcon from "../resources/images/comment-icon-full.png";
import { HorizontalFlex } from "../pandora/styled";


const CommentTextarea = styled("textarea")`
    background: ${Color.darkGrey};
    border-radius: 5px;
    box-sizing: border-box;

    padding: 15px;
    max-width: 100%;
    width: 100%;
    min-height: 60px;
    height: 80px;

    border: 0;
    color: white;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    outline: none;

    overflow: auto;
    overflow-x: hidden;
    resize: none;

    transition: 0.3s ease;

    ::placeholder {
        color: white;
        font-family: Montserrat, sans-serif;
        font-weight: bold;
        opacity: 50%;
    }
`

const CommentButton = styled.div`
    position: relative;

    background-color: ${Color.green};
    border-radius: 5px;

    height: 60px;
    width: 70px;

    margin-left: 10px;

    background-image: url(${CommentIcon});
    background-repeat: no-repeat;
    background-size: 40% 40%;
    background-position: center;

    cursor: pointer;

    transition: 0.1s linear;

    &:hover {
        background-color: ${Color.lightGreen};
    }        
`


interface CommentFieldProps {
    postComment: (content: string) => void
}

const CommentField = (props: CommentFieldProps): React.ReactElement => {

    // State
    const [comment, setComment] = useState<string>(""); 

    // Actions
    const handleChange = e => {
        setComment(e.target.value);
    }

    /**
     * Post inputted comment to API via parent component. 
     */
    const submit = () => {
        if (comment !== "") {
            props.postComment(comment)
            setComment("");
            document.getElementById("comment-field").focus()
        }
    }

    /**
     * Bind keypress to action. 
     */
    const onKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation()
            submit()
        }
    }

    return (
        <HorizontalFlex css={`position: relative; padding-top: 20px; border-top: solid 4px ${Color.darkGrey};`}>
            <CommentTextarea 
                id=             {"comment-field"}
                value=          {comment}
                placeholder=    "Kommentera"
                onChange=       {handleChange}
                onKeyPress=     {onKeyPress}
            />
            <CommentButton onClick={submit}/>
        </HorizontalFlex>
    )
}


export default CommentField;