import { Session, createSelector as createOrmSelector, Model } from "redux-orm";
import { ModelType } from "redux-orm/Model";
import { IndexedModelClasses } from "redux-orm/ORM";
import moment from "moment";

import orm, { Schema } from "./orm";
import { RootState } from "./bootstrap"
import { Project, GeoData, User, Comment, Completion, Organisation, TimeReport } from "./models";
import { IProject, IGeoData, ICompletion, IUser, IComment, IOrganisation, ITimeReport, IPoint, TimeReportType } from "../api/GeoDocModels";
import { ColorPreset } from "../pandora/pandora";
 


// Redux-ORM selectors work with reselect. To feed input
// selectors to a Redux-ORM selector, we use the 
// reselect `createSelector` function.
// The first input selector should always be the orm selector.
// Behind the scenes, `schema.createSelector` begins a Redux-ORM
// session with the value returned by `ormSelector` and passes
// that Session instance as an argument instead.
// So `orm` below is a Session instance.



/** 
 * Selector retrieving data from ReudxORM Store.
 * This class is supposed to be used by Components, not ModelSelector.
 * Selector exposes public selectors with structured data.
*/ 
export default class Selector {
    
    // Selects the state managed by Redux-ORM.
    static ormSelector = (state: RootState) => state.orm;

    /**
     * Retrieve all projects with fetched 
     * subproperties and children. 
     */
    public static getProjects = createOrmSelector<Schema, IProject[]>(
        orm, 
        (session: Session<Schema>) => { 
            return (session.Project.all().toModelArray().map(project => ModelSelector.getProjectForID(session, project.id))) as unknown as IProject[]
        }
    )
    
    /**
     * Retrieve all users.
     */
    public static getUsers = createOrmSelector<Schema, IUser[]>(
        orm,
        (session: Session<Schema>) => { 
            return (session.User.all().toModelArray().map(user => ModelSelector.getUserForID(session, user.id))) as unknown as IUser[]
        }
    )

    /**
     * Retrieve all organisations.
     */
    public static getOrganisations = createOrmSelector<Schema, IOrganisation[]>(
        orm,
        (session: Session<Schema>) => { 
            return (session.Organisation.all().toRefArray()) as unknown as IOrganisation[]
        }
    )

    /**
     * Retrieve current user.
     */
    public static getUser = (state: RootState): IUser => state.user
}




/**
 * Selectors fetching single model objects.
 * Should never be used directly from Components.
 */
export class ModelSelector {

    /**
     * Retrieve requested model instance with ID.
     * 
     * @param session Current session object derived from orm.Session in public ORMSelector.
     * @param model Requested Model type registered on ORM.
     * @param id Requested model ID.
     */
    static getModelForID<I extends IndexedModelClasses<any>, M extends Model>(
        session: Session<Schema>, 
        model: ModelType<InstanceType<I[keyof I]>>, 
        id: number | string): M| null {

        //@ts-ignore
        const found = session.sessionBoundModels.find(m => m === model).withId(id)
        if (found === undefined) {
            throw new Error(`Could not find entry in database for model ${model.modelName} id ${id}`)
        }
        //@ts-ignore
        return found;
    }

    /**
    * Retrieve Organisation properties as object for ID.
    * @param session Current session object derived from orm.Session in public ORMSelector.
    * @param id Requested id.
    * @returns Organisation object.
    */
    static getOrganisationForID(session: Session<Schema>, id: string): IOrganisation | null {
        const organisation: Organisation = ModelSelector.getModelForID(session, session.Organisation, id);
        if (organisation === null) return null;
        return organisation.ref;
    }

    /**
    * Retrieve User properties as object for ID.
    * @param session Current session object derived from orm.Session in public ORMSelector.
    * @param id Requested id.
    * @returns User object.
    */
    static getUserForID(session: Session<Schema>, id: string): IUser | null {
        const user: User = ModelSelector.getModelForID(session, session.User, id);
        if (user === null) return null;

        const organisation: IOrganisation = ModelSelector.getOrganisationForID(session, user.ref.organisation);
        return {
            ...user.ref,
            organisation: organisation
        }
    }

    /**
    * Retrieve TimeReport properties as object for ID.
    * @param session Current session object derived from orm.Session in public ORMSelector.
    * @param id Requested id.
    * @returns User object.
    */
   static getTimeReportForID(session: Session<Schema>, id: number): ITimeReport | null {
        const report: TimeReport = ModelSelector.getModelForID(session, session.TimeReport, id);
        if (report === null) return null;

        const user: IUser = ModelSelector.getUserForID(session, report.ref.user);
        const comment: IComment = ModelSelector.getCommentForID(session, report.ref.comment);
        return {
            ...report.ref,
            type: TimeReportType[report.ref.type],
            time: moment.utc(report.ref.time).local().toDate(),
            user: user,
            comment: comment
        }
    }

    /**
    * Retrieve Completion properties as object for ID.
    * @param session Current session object derived from orm.Session in public ORMSelector.
    * @param id Requested id.
    * @returns Completion object.
    */
    static getCompletionForID(session: Session<Schema>, id: number): ICompletion | null {
        const completion: Completion = ModelSelector.getModelForID(session, session.Completion, id);
        if (completion === null) return null;

        const user: IUser = ModelSelector.getUserForID(session, completion.ref.user);
        return {
            ...completion.ref,
            time: moment.utc(completion.ref.time).local().toDate(),
            user: user
        }
    }

    /**
    * Retrieve Comment properties as object for ID.
    * @param session Current session object derived from orm.Session in public ORMSelector.
    * @param id Requested id.
    * @returns Comment object.
    */
    static getCommentForID(session: Session<Schema>, id: number): IComment | null {
        const comment: Comment = ModelSelector.getModelForID(session, session.Comment, id);
        if (comment === null) return null;

        const user: IUser = ModelSelector.getUserForID(session, comment.ref.user);
        return {
            ...comment.ref,
            time: moment.utc(comment.ref.time).local().toDate(),
            user: user
        }
    }

    /**
    * Retrieve GeoData properties as object for ID.
    * @param session Current session object derived from orm.Session in public ORMSelector.
    * @param id Requested id.
    * @returns GeoData object.
    */
    static getGeoDataForID(session: Session<Schema>, id: number): IGeoData | null {
        const geoData: GeoData = ModelSelector.getModelForID(session, session.GeoData, id);
        if (geoData === null) return null;

        const { internalCompletion, externalCompletion } = geoData.ref;

        const internalCompletionRef: ICompletion = internalCompletion !== null ? ModelSelector.getCompletionForID(session, internalCompletion) : null;
        const externalCompletionRef: ICompletion = externalCompletion !== null ? ModelSelector.getCompletionForID(session, externalCompletion) : null;
        const addedBy: IUser = ModelSelector.getUserForID(session, geoData.ref.addedBy);
        const comments: IComment[] = (geoData.ref.comments as unknown as number[])?.map(id => ModelSelector.getCommentForID(session, id)) ?? []
        const timeReports: ITimeReport[] = (geoData.ref.timeReports as unknown as number[])?.map(id => ModelSelector.getTimeReportForID(session, id)) ?? []
        return {
            ...geoData.ref,
            internalCompletion: internalCompletionRef,
            externalCompletion: externalCompletionRef,
            addedBy: addedBy,
            time: moment.utc(geoData.ref.time).local().toDate(),
            comments: comments,
            timeReports: timeReports,
            images: geoData.ref.images ?? []
        } 
    }

    /**
    * Retrieve Project properties as object for ID.
    * @param session Current session object derived from orm.Session in public ORMSelector.
    * @param id Requested id.
    * @returns Project object.
    */
    static getProjectForID(session: Session<Schema>, id: number): IProject | null {
        const project: Project = ModelSelector.getModelForID(session, session.Project, id);
        if (project === null) return null;

        const organisation: IOrganisation = ModelSelector.getOrganisationForID(session, project.ref.organisation);
        const geoData: IGeoData[] = (project.ref.geoData as unknown as number[])?.map(id => ModelSelector.getGeoDataForID(session, id)) ?? []
        return {
            ...project.ref,
            color: ColorPreset[project.ref.color],
            geoData: geoData,
            organisation: organisation
        }
    }
}


