import DarkMapImage from "../resources/images/map-images/dark-map-image.PNG";
import LightMapImage from "../resources/images/map-images/light-map-image.PNG";
import StreetMapImage from "../resources/images/map-images/street-map-image.PNG";
import GeoMapImage from "../resources/images/map-images/geo-map-image.PNG";
import SatelliteMapImage from "../resources/images/map-images/satellite-map-image.PNG";
import AtritecMapImage from "../resources/images/map-images/atritec-map-image.PNG";


export default class MapType {

    static readonly Satellite = new MapType("Satellit", SatelliteMapImage, "mapbox/satellite-v9")
    static readonly Geo =       new MapType("Geo", GeoMapImage, "mapbox/outdoors-v11")
    static readonly Streets =   new MapType("Vägar", StreetMapImage, "mapbox/streets-v11")
    static readonly Light =     new MapType("Ljus", LightMapImage, "mapbox/light-v10")
    static readonly Dark =      new MapType("Mörk", DarkMapImage, "mapbox/dark-v10")
    static readonly Atritec =   new MapType("Atritec", AtritecMapImage, "atritecrasmus/ckifuc1mj4ywe19s4cqlsx3ju")
  
    // private to disallow creating other instances of this type
    private constructor(public readonly name: string, 
                        public readonly image: string,
                        public readonly mapBoxKey: any) {}
        
    static getTypeForName(name: string, defaultType: MapType): MapType {
        const allTypes: MapType[] = MapType.all();
        for (let i=0; i<allTypes.length;i++) {
            if (allTypes[i].name == name) return allTypes[i]
        }
        return defaultType
    }

    static all(): MapType[] {
        return [MapType.Satellite, MapType.Geo, MapType.Streets, MapType.Light, MapType.Dark, MapType.Atritec]
    }

    toString() {
      return this.name;
    }
  }
