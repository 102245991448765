import React from "react";
import styled from "styled-components";
 
import Color from "../../resources/colors";
import { VerticalFlex } from "../../pandora/styled";


const SettingsBarContainer = styled(VerticalFlex)`
    position: absolute;
    width: 110px;
    height: auto;
    
    padding: 15px;
    left: 0;
    top: 0;
    bottom: 0;
    margin: 0;
    background: ${Color.lightGrey}
`

const StateButton = styled("div")<{state: ContentState, selectedState: ContentState}>`
    background-color: ${props => props.selectedState == props.state ? Color.darkGrey : Color.lightGrey};
    height: 40px;
    margin-top: 5px;
    border-radius: 5px;

    text-indent: 10px;
    line-height: 40px;
    text-align: left;
    font-size: 12px;
    font-weight: bold;
    color: white;

    cursor: pointer;
    user-select: none;

    transition: 0.3s ease;

    &: hover {
        background-color: ${Color.darkGrey};
    }
`



export enum ContentState {
    Profile = "Profile",
    Projects = "Projects",
}

const ContentStateTitleMap = {
    [ContentState.Profile] : "Profil",
    [ContentState.Projects] : "Projekt",
}

interface SettingsBarProps {
    isAdmin: boolean;
    selectedState: ContentState
    selectState: (state: ContentState) => void;
}

const SettingsBar = (props: SettingsBarProps): React.ReactElement => {

    const states: string[] = props.isAdmin ? Object.keys(ContentState) : [ContentState.Profile]

    return (    
        <SettingsBarContainer>
            <VerticalFlex>
                {states.map(c => (
                    <StateButton
                        key=            {c}
                        state=          {ContentState[c]}
                        selectedState=  {props.selectedState}
                        onClick=        {() => props.selectState(ContentState[c])}
                    >{ContentStateTitleMap[c]}</StateButton>
                ))}
            </VerticalFlex>
        </SettingsBarContainer>
    )
}

export default SettingsBar;




