import axios, { AxiosResponse } from "axios";

import APIRequest, { HTTPRequestMethod, Request } from "./APIRequest";
import { GeoDocModel, HeimdallModel, IAuthenticatedUser } from "./GeoDocModels";


export default class Sleipnir {

    private static APIToken: string;
    private static baseURL: string = process.env.REACT_APP_API_URL;

    /**
     * Construct request header w dynamic parameters.
     * @param token API access token. 
     * @param contentType Request content type. Default: application/json
     */
    private static getHeaders = (token: string, contentType?: string, rHeaders?: Record<string, string | number>): Record<string, string | number> => {
        return {
            "Authorization": `Bearer ${token}`,
            "Content-Type": contentType ?? "application/json",
            ...rHeaders
        }
    }

    /**
     * Set static class instance property.
     * API authentication 'Bearer' token. 
     * @param token 
     */
    public static setToken = (token: string | undefined): void => {
        Sleipnir.APIToken = token
    };

    /**
     * Retrieve user for SSO 
     * cookie auth token. 
     */
    public static async authenticate(): Promise<IAuthenticatedUser> {
        return await Sleipnir.request<IAuthenticatedUser>(APIRequest.auth) as IAuthenticatedUser;
    }

    /**
     * Helper function to construct 
     * request API route with any ID args. 
     */
    private static getRoute(r: (...args: any[]) => string, data?: Record<string | number | symbol, any>): string {
        let id: number = data?.id
        // Check FormData
        const asFormData = data as FormData;
        if (asFormData !== null && id == undefined) {
            try { 
                id = parseInt(asFormData.get("id") as string) 
            } catch (e) { 
                id = undefined;
            }
        }
        return Sleipnir.baseURL + r(id ?? "")
    }   

    /**
     * Dispatch request to Sleipnir via axios. 
     * @param r Request with HTTP method, route and required payload keys for request. {@link Request}
     * @param data Payload. 
     * @param rawResponse Flag if raw response from API call should be returned or error checked result.
     */
    public static async request<I extends GeoDocModel | HeimdallModel | File>(r: Request, data?: Record<string | number | symbol, any>, rawResponse?: boolean): Promise<I | Array<I> | AxiosResponse<I>> {

        // Requests cannot be dispatched wihout 
        // instantiated caller set in user authentication. 
        if (Sleipnir.APIToken === undefined) 
            throw new Error("FATAL ERROR: Sleipnir APIToken not set.")

        const {method, route, keys, contentType, headers: rHeaders, opts} = r;

        keys.forEach((key: string) => {
            if (!(key in data)) {
                // Handle FormData key check 
                const asFormData = data as FormData;
                if (asFormData !== null && [...asFormData.keys()].includes(key)) return;
                throw new Error(`FATAL ERROR: Missing required key: ${key} in payload.`)
            }
        })

        // Setup payload - "GET" HTTP method does not support 
        // sending request bodies. Replace with "params" key. 
        const payloadKey: string = method === HTTPRequestMethod.GET ? "params" : "data"
        const payload: Record<string, Record<string, unknown>> = {[payloadKey]: data}

        // Setup request URL with provided 
        // route and any ID args
        const url: string = Sleipnir.getRoute(route, data)

        // Configure request headers for API request
        const headers = Sleipnir.getHeaders(Sleipnir.APIToken, contentType, rHeaders)

        // Compose request
        const req: Record<string, any> = {
            "headers": headers,
            "url": url,
            "method": method,
            ...opts,
            ...payload
        }

        // Dispatch request
        try {
            const res: AxiosResponse = await axios(req);
            if (rawResponse) return res;
            if (res.status !== 200) throw new Error(res.data);
            return res.data as I;
        } catch (e) {
            console.log("ERROR RESPONSE", e.response)
            throw new Error(e.message)
        }
    }
}
