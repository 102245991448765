
export enum HTTPRequestMethod {
    POST = "POST",
    PUT = "PUT",
    GET = "GET",
    DELETE = "DELETE"
}

enum APIRoute {
    User = "user",
    Organisation = "organisation",
    Project = "project",
    GeoData = "geodata",
    Comment = "comment",
    Complete = "complete",
    TimeReport = "time",
    Upload = "upload",
    Subscription = "subscription"
}


/**
 * Request protocol defining keys 
 * expected and required for performing an API 
 * request. Some optional keys, like content type and headers
 * defined optional key-value pairs inserted into the request
 * header. 
 */
export interface Request {
    method: HTTPRequestMethod
    keys: string[]
    contentType?: string 
    headers?: Record<string, any>
    opts?: Record<string, any>
    route: (...args: any[]) => string
}


/**
 * 
 */
export default class APIRequest {

    /**
     * Construct generic API route. 
     */
    private static makeURL = (...args: Array<string | number>): string => "/" + args.map(String).join("/");

    // AUTH:

    // Get User data including organisation for the current user.
    static auth: Request = {
        method: HTTPRequestMethod.GET,
        keys: [],
        route: () => APIRequest.makeURL(APIRoute.User, "current")
    }


    // ORGANISATION: 

    // Get all organisations with Sleipnir association. 
    // Only Employee may request this
    static getOrganisations: Request = {
        method: HTTPRequestMethod.GET,
        keys: [],
        route: () => APIRequest.makeURL(APIRoute.Organisation, "all")
    }

    // Get all projects for a specific organisation
    static getProjectsForOrganisation: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["id"],
        route: (orgID: number) => APIRequest.makeURL(APIRoute.Organisation, orgID, "projects")
    }
    

    // PROJECT:

    // Get several projects from a list of IDs. 
    // Empty JSON requests all projects Regular user can only request 
    // their projects. Employees can request any projects.
    static getProjects: Request = {
        method: HTTPRequestMethod.GET, 
        keys: [],
        route: () => APIRequest.makeURL(APIRoute.Project, "all")
    }

    // Get a specific Project. A regular user can only show projects which 
    // belong to their organisation. Employee can show any project.
    static getProject: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["id"],
        route: (projID: number) => APIRequest.makeURL(APIRoute.Project, projID)
    }

    // Edit a project (by id) with the new fields
    static updateProject: Request = {
        method: HTTPRequestMethod.PUT,
        keys: ["id"],
        route: (projID: number) => APIRequest.makeURL(APIRoute.Project, projID)
    }

    // Create a new Project. It will belong to the user's organisation.
    static createProject: Request = {
        method: HTTPRequestMethod.POST,
        keys: ["name", "description", "organisationID"],
        route: () => APIRequest.makeURL(APIRoute.Project, "create")
    }

    //Delete a project by ID
    static deleteProject: Request = {
        method: HTTPRequestMethod.DELETE,
        keys: ["id"],
        route: (projID: number) => APIRequest.makeURL(APIRoute.Project, projID)
    }

    // Get whether the current User receives notifications from a project or not
    static getProjectSubscriptionStatus: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["id"],
        route: (projID: number) => APIRequest.makeURL(APIRoute.Project, projID, APIRoute.Subscription)
    }

    // Enables notifications for project
    static subscribeToProject: Request = {
        method: HTTPRequestMethod.POST,
        keys: ["id"],
        route: (projID: number) => APIRequest.makeURL(APIRoute.Project, projID, APIRoute.Subscription)
    }

    // Disables notifications for project
    static unsubscribeFromProject: Request = {
        method: HTTPRequestMethod.DELETE,
        keys: ["id"],
        route: (projID: number) => APIRequest.makeURL(APIRoute.Project, projID, APIRoute.Subscription)
    }


    
    // GEODATA:

    // Get all Geodata entries for a project id
    static getGeoData: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["id"],
        route: (geoDataID: number) => APIRequest.makeURL(APIRoute.GeoData, geoDataID)
    }

    // Edit the given Geodata (by id) with the given fields
    static updateGeoData: Request = {
        method: HTTPRequestMethod.PUT,
        keys: ["id"],
        route: (geoDataID: number) => APIRequest.makeURL(APIRoute.GeoData, geoDataID)
    }

    // Get a list of images for GeoData ID. 
    static getGeoDataImages: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["id"],
        route: (geoDataID: number) => APIRequest.makeURL(APIRoute.GeoData, geoDataID, "images")
    }

    // Check whether a geoData has images or not 
    static geoDataHasImages: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["id"],
        route: (geoDataID: number) => APIRequest.makeURL(APIRoute.GeoData, geoDataID, "hasimages")
    }
    

    // COMMENTS:

    // Create a new comment
    static postComment: Request = {
        method: HTTPRequestMethod.POST,
        keys: ["id", "content"],
        route: (geoDataID: number) => APIRequest.makeURL(APIRoute.GeoData, geoDataID, APIRoute.Comment)
    }

    // Deactivate a comment, it will be hidden
    static deleteComment: Request = {
        method: HTTPRequestMethod.DELETE,
        keys: ["id"],
        route: (commentID: number) => APIRequest.makeURL(APIRoute.GeoData, APIRoute.Comment, commentID)
    }


    // COMPLETIONS:

    // User marks Geodata as completed. Id is geodata id
    static postCompletion: Request = {
        method: HTTPRequestMethod.POST,
        keys: ["id"],
        route: (geoDataID: number) => APIRequest.makeURL(APIRoute.GeoData, geoDataID, APIRoute.Complete)
    }  

    // Deleting completion by geodata id
    static deleteCompletion: Request = {
        method: HTTPRequestMethod.DELETE,
        keys: ["id"],
        route: (completionID: number) => APIRequest.makeURL(APIRoute.GeoData, APIRoute.Complete, completionID)
    }  


    // TIME REPORT:

    // Get all time reports for a geodata
    static getTimeReports: Request = {
        method: HTTPRequestMethod.GET,
        keys: [],
        route: (geoDataID: number) => APIRequest.makeURL(APIRoute.GeoData, geoDataID, APIRoute.TimeReport)
    }

    // Create a time report entry. Optionally create a comment
    static postTimeReport: Request = {
        method: HTTPRequestMethod.POST,
        keys: ["id", "hours", "comment"],
        route: (geoDataID: number) => APIRequest.makeURL(APIRoute.GeoData, geoDataID, APIRoute.TimeReport)
    }

    // Create a time report entry. Optionally create a comment
    static deleteTimeReport: Request = {
        method: HTTPRequestMethod.DELETE,
        keys: ["id"],
        route: (timeReportID: number) => APIRequest.makeURL(APIRoute.GeoData, APIRoute.TimeReport, timeReportID)
    }


    // UPLOAD:
    
    // Upload an image and associate it with the geodata.
    static uploadImage: Request = {
        method: HTTPRequestMethod.POST,
        keys: ["id", "uploadImage"],
        contentType: "multipart/form-data",
        route: (geoDataID: number) => APIRequest.makeURL(APIRoute.GeoData, geoDataID, APIRoute.Upload, "image")
    }

    // Upload a GeoPackage File to create a new 
    // Geodata entry for a specific project An ImageIdentifier 
    // is also created. This is used ot automatically retrieve images
    static uploadGeoPackage: Request = {
        method: HTTPRequestMethod.POST,
        keys: ["id", "geoPackage"],
        contentType: "multipart/form-data",
        route: (projID: number) => APIRequest.makeURL(APIRoute.Project, projID, APIRoute.Upload, "geopackage")
    }


    // EXPORT:

    // Export an Excel document of time reports
    static exportTimeReportAll: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["id", "interval"], 
        headers: {
            "Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },
        opts: {
            "responseType": "blob",
        },
        route: () => APIRequest.makeURL("export", APIRoute.TimeReport, "all")
    }
    
    // Export an Excel document of time reports
    static exportTimeReport: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["id", "fromTime", "toTime"], 
        headers: {
            "Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },
        opts: {
            "responseType": "blob",
        },
        route: (projectID: number) => APIRequest.makeURL("export", APIRoute.TimeReport, projectID)
    }

}

