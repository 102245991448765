import Redirect from "./redirect";
import { IAuthenticatedUser, Role } from "../api/GeoDocModels";
import Sleipnir from "../api/Sleipnir";
import { CookieHandler, GeoDocCookies } from "./cookies";


export default class Auth {

    /**
     * Current logged in user instance.
     */
    public static currentUser: IAuthenticatedUser;

    /**
     * Retrieve token and set static token
     * on API caller {@link Sleipnir}. Without 
     * calling the setToken(token:) on Sleipnir, 
     * API calls won't work.
     */
    public static init = (): boolean => {
        const token = Auth.getToken()
        if (token !== undefined) Sleipnir.setToken(token)
        return token !== undefined
    }

    /**
     * Verify session with token 
     * is authenticated.
     */
    public static verify = async (): Promise<boolean> => {
        try {
            const res: IAuthenticatedUser = await Sleipnir.authenticate()
            return res !== null;
        } catch (e) {
            Auth.setUser(undefined)
            console.log(e)
            return false
        }
    }

    /**
     * Remove login token cookie and 
     * redirect to ASuite. 
     */
    public static logout = (): void => {
        Auth.removeToken()
        Sleipnir.setToken(undefined);
        Redirect.asuite()
    }

    /**
     * Set currently logged in user.
     * @param user 
     */
    public static setUser = (user: IAuthenticatedUser) => Auth.currentUser = user;

    /**
     * Retrive authentication token 
     * from cookies. 
     */
    private static getToken = (): string | undefined => {
        return CookieHandler.get(GeoDocCookies.Auth)
    }

    /**
     * Remove authentication cookie.
     */
    private static removeToken = (): void => {
        CookieHandler.remove(GeoDocCookies.Auth)
    }

    /**
     * Reducer for { user: } state in 
     * Redux store. 
     * @param state Current Redux state for "user" key.
     * @param action Dispatch action { type:, payload: }
     */
    public static reducer = (state, action) => {
        const { type } = action;
        switch (type) {
            default:
                return (state === undefined) ? null : state;   
        }
    }   

    /**
     * Retrieve which type of completion 
     * is directly managed current User.
     */
    public static completionType = (): string | null => {
        if (Auth.currentUser === undefined) return null;
        return Auth.currentUser.isEmployee ? "internalCompletion" : "externalCompletion";
    }

    
    /**
     * Retrieve which type of completion 
     * is not directly managed current User.
     */
    public static nonCompletionType = (): string | null => {
        if (Auth.currentUser === undefined) return null;
        return !Auth.currentUser.isEmployee ? "internalCompletion" : "externalCompletion";
    }

    public static isAdmin = (): boolean => {
        if (Auth.currentUser === undefined) 
            throw new Error("Attmepted use of authenticated user, but user not logged in.")
        return Auth.currentUser.role === Role.Admin;
    }

    public static isEmployee = (): boolean => {
        if (Auth.currentUser === undefined) 
            throw new Error("Attmepted use of authenticated user, but user not logged in.")
        return Auth.currentUser.isEmployee;
    }
}
